import {
  FETCH_ALLPRODUCTS_REQUEST,
  FETCH_ALLPRODUCTS_SUCCESS,
  FETCH_ALLPRODUCTS_FALIURE,

  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FALIURE,
  STOCK_PRODUCT_REQUEST,
  STOCK_PRODUCT_SUCCESS,
  STOCK_PRODUCT_FALIURE,
  UPDATE_ALLPRODUCT_REQUEST,
  UPDATE_ALLPRODUCT_SUCCESS,
  UPDATE_ALLPRODUCT_FALIURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FALIURE,
  UPDATE_PRODUCT_PRICE_REQUEST,
  UPDATE_PRODUCT_PRICE_SUCCESS,
  UPDATE_PRODUCT_PRICE_FALIURE,
  FETCH_SINGLEPRODUCTS_REQUEST,
  FETCH_SINGLEPRODUCTS_SUCCESS,
  FETCH_SINGLEPRODUCTS_FALIURE,
  ADD_PRODUCT_GROUP_REQUEST,
  ADD_PRODUCT_GROUP_SUCCESS,
  ADD_PRODUCT_GROUP_FALIURE,
  DELETE_PRODUCT_GROUP_REQUEST,
  DELETE_PRODUCT_GROUP_SUCCESS,
  DELETE_PRODUCT_GROUP_FALIURE
} from './AllProductsConstants'
import {config} from '../config'
import toast from 'react-hot-toast'


export const fetchProductsRequest = (category_info) =>{
  return{
    type : FETCH_ALLPRODUCTS_REQUEST,
    payload:category_info
  }
}

export const fetchProductsSuccess = (all_categories_success) =>{
  return{
    type : FETCH_ALLPRODUCTS_SUCCESS,
    payload : all_categories_success
  }
}

export const fetchAllCategoriesSuccess = (all_categories_success) =>{
  return{
    type : FETCH_ALLPRODUCTS_SUCCESS,
    payload : all_categories_success
  }
}

export const fetchAllCategoriesFaliure = (error) =>{
  return{
    type : FETCH_ALLPRODUCTS_FALIURE,
    payload : error
  }
}

export const editCategoryRequest = (edit_category) =>{
  return{
    type : UPDATE_ALLPRODUCT_REQUEST,
    payload:edit_category
  }
}

export const editCategorySuccess = (edit_category_success) =>{
  return{
    type : UPDATE_ALLPRODUCT_SUCCESS,
    payload : edit_category_success
  }
}

export const editCategoryFaliure = (error) =>{
  return{
    type : UPDATE_ALLPRODUCT_FALIURE,
    payload : error
  }
}

export const editProductRequest = (edit_product) =>{
  return{
    type : UPDATE_PRODUCT_REQUEST,
    payload:edit_product
  }
}

export const editProductSuccess = (edit_product_success) =>{
  return{
    type : UPDATE_PRODUCT_SUCCESS,
    payload : edit_product_success
  }
}

export const editProductFaliure = (error) =>{
  return{
    type : UPDATE_PRODUCT_FALIURE,
    payload : error
  }
}

export const editProductPriceRequest = (edit_product_price) =>{
  return{
    type : UPDATE_PRODUCT_PRICE_REQUEST,
    payload:edit_product_price
  }
}

export const editProductPriceSuccess = (edit_product_price_success) =>{
  return{
    type : UPDATE_PRODUCT_PRICE_SUCCESS,
    payload : edit_product_price_success
  }
}

export const editProductPriceFaliure = (error) =>{
  return{
    type : UPDATE_PRODUCT_PRICE_FALIURE,
    payload : error
  }
}





export const fetchProductsFaliure = (error) =>{
  return{
    type : FETCH_PRODUCTS_FALIURE,
    payload : error
  }
}

export const stockProductRequest = (stock_product) =>{
  return{
    type : STOCK_PRODUCT_REQUEST,
    payload:stock_product
  }
}

export const stockProductSuccess = (stock_product_success) =>{
  return{
    type : STOCK_PRODUCT_SUCCESS,
    payload : stock_product_success
  }
}

export const stockProductFaliure = (error) =>{
  return{
    type : STOCK_PRODUCT_FALIURE,
    payload : error
  }
}

export const fetchsingleProductRequest = (category_info) =>{
  return{
    type : FETCH_SINGLEPRODUCTS_REQUEST,
    payload:category_info
  }
}

// export const addGroupRequest = (add_group) =>{
//   return{
//     type : ADD_GROUP_REQUEST,
//     payload: add_group
//   }
// }

export const fetchsingleProductSuccess = (all_categories_success) =>{
  return{
    type : FETCH_SINGLEPRODUCTS_SUCCESS,
    payload : all_categories_success
  }
}

export const fetchsingleProductFaliure = (error) =>{
  return{
    type : FETCH_SINGLEPRODUCTS_FALIURE,
    payload : error
  }
}

export const addProductGroupRequest = (add_product_group) =>{
  return{
    type : ADD_PRODUCT_GROUP_REQUEST,
    payload:add_product_group
  }
}

export const addProductGroupSuccess = (add_product_group_success) =>{
  return{
    type : ADD_PRODUCT_GROUP_SUCCESS,
    payload : add_product_group_success
  }
}

export const addProductGroupFaliure = (error) =>{
  return{
    type : ADD_PRODUCT_GROUP_FALIURE,
    payload : error
  }
}

export const deleteProductGroupRequest = (delete_product_status) =>{
  return{
    type : DELETE_PRODUCT_GROUP_REQUEST,
    payload : delete_product_status
  }
}

export const deleteProductGroupSuccess = (delete_product_status_success) =>{
  return{
    type : DELETE_PRODUCT_GROUP_SUCCESS,
    payload : delete_product_status_success
  }
}

export const deleteProductGroupFaliure = (error) =>{
  return{
    type : DELETE_PRODUCT_GROUP_FALIURE,
    payload : error
  }
}

export const fetchAllProducts = (category_info,currentPage) =>{
  // console.log('apireq', category_info );
    return(dispatch) => {
      dispatch(fetchProductsRequest(category_info))
      // console.log(dispatch(fetchProductsRequest(category_info)));
      const url =`${config.api_root}/api/v1/merchants/products?user_id=${config.user_id}&offset=${currentPage}&size=10`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      }
    fetch(url, request_option)
    .then(response => response.json())
    .then(book_res =>{
      const all_categories_success = book_res
      dispatch(fetchProductsSuccess(all_categories_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchProductsFaliure(errorMsg))
    })
    }
}



export const addProduct = (category_info) =>{

  return(dispatch) => {
    dispatch(fetchProductsRequest(category_info))
    const url =`${config.api_root}/api/v1/merchants/product`;
    const request_option = {
    method: "POST",
    headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
        },
    body: JSON.stringify({
      user_id : config.user_id,
      fields:{
        name: category_info.name,
        shortDescription:category_info.shortdescription,
        descriptions:category_info.description,
        price:category_info.price,
        is_alcohol: category_info.is_alcohol,
        is_sugary_item: category_info.is_sugary_item,
        enabledUserInstructions: true,
        virtual: false,
        inStock: true,
        downloadable: false,
        timeslots: [],
        productImageUrl: category_info.productImageUrl,
        priceslots: {},
        categories: category_info.categories,
        
        addonsGroups: category_info.addons || [],
     
      },
    })
  }
  fetch(url, request_option)
  .then(response => response.json())
  .then(add_timing_res =>{
    console.log("--jkf--->"+JSON.stringify(add_timing_res.object.error));
    const all_categories_success = add_timing_res.object.error
    if(add_timing_res.request_status === false){
      dispatch(fetchProductsFaliure(add_timing_res.object.error))
      console.log(fetchProductsFaliure(add_timing_res.object.error));
      toast.error(add_timing_res.object.error)
    }else{
      toast.success("Product Added Successfully")
      window.location.replace("/product")
    }
    
    dispatch(fetchProductsSuccess(all_categories_success))

  })
  .catch(error => {
    const errorMsg = error
    console.log("--jk--->"+errorMsg);
    dispatch(fetchProductsFaliure(errorMsg))
  })
}
}

// for image

// Get a reference to the file input element

  