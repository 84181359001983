import {
  FETCH_AllRESTTIMINGS_REQUEST,
  FETCH_AllRESTTIMINGS_SUCCESS,
  FETCH_AllRESTTIMINGS_FALIURE
} from './AllRestTimingsConstants'
import {config} from '../config'

export const fetchAllRestTimingsRequest = (exams_info) =>{
  return{
    type : FETCH_AllRESTTIMINGS_REQUEST,
    payload:exams_info
  }
}

export const fetchAllRestTimingsSuccess = (all_rest_timings_success) =>{
  return{
    type : FETCH_AllRESTTIMINGS_SUCCESS,
    payload : all_rest_timings_success
  }
}

export const fetchAllRestTimingsFaliure = (error) =>{
  return{
    type : FETCH_AllRESTTIMINGS_FALIURE,
    payload : error
  }
}

export const fetchAllRestTimings = (exams_info) =>{
    return(dispatch) => {
      dispatch(fetchAllRestTimingsRequest(exams_info))
      const examDate = exams_info !== undefined ? exams_info.examDate != "" ? exams_info.examDate :undefined : undefined
      const classid = exams_info !== undefined ? exams_info.classId !="" ? exams_info.classId : undefined: undefined
      const sectionid = exams_info !== undefined ? exams_info.sectionId !="" ? exams_info.sectionId : undefined : undefined
      const subjectid = exams_info !== undefined ? exams_info.subjectId !="" ? exams_info.subjectId : undefined : undefined
      const url =`${config.api_root}/api/v1/merchants/shop/timing?user_id=${config.user_id}`;
      const request_option = {
      method: "GET",
      headers: {
        'Authorization': 'Bearer '+ config.token,
        'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(exam_res =>{
      const all_rest_timings_success = exam_res
      dispatch(fetchAllRestTimingsSuccess(all_rest_timings_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchAllRestTimingsFaliure(errorMsg))
    })
    }
}

export const deleteRestTiming = (timing_id) =>{
    return(dispatch) => {
      dispatch(fetchAllRestTimingsRequest(timing_id))
      const url =`${config.api_root}/api/v1/merchants/shop/timing?user_id=${config.user_id}&time_id=${timing_id}`;
      const request_option = {
      method: "DELETE",
      headers: {
        'Authorization': 'Bearer '+ config.token,
        'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(exam_res =>{
      const all_rest_timings_success = exam_res
      //dispatch(fetchAllRestTimingsSuccess(all_rest_timings_success))
      dispatch(fetchAllRestTimings())
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchAllRestTimingsFaliure(errorMsg))
    })
    }
}
