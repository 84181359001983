import React from 'react';
import { Link } from 'react-router-dom';
import AddTiming from './AddTiming';
import AllRestTimings from './AllRestTimings';
function RestTiming() {
  
        return (
            <>
            <div className="breadcrumbs-area">
              <h3>Restaurant Timings</h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Restaurant Timings</li>
              </ul>
            </div>
            {/* Breadcubs Area End Here */}
            {/* Exam Schedule Area Start Here */}
            <div className="row">
            <AllRestTimings />
              <AddTiming />

            </div>
            </>
        );
}

export default RestTiming;
