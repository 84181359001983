import {
  FETCH_ALLCATEGORIES_REQUEST,
  FETCH_ALLCATEGORIES_SUCCESS,
  FETCH_ALLCATEGORIES_FALIURE,
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FALIURE,
  STOCK_PRODUCT_REQUEST,
  STOCK_PRODUCT_SUCCESS,
  STOCK_PRODUCT_FALIURE,
  STATUS_PRODUCT_REQUEST,
  STATUS_PRODUCT_SUCCESS,
  STATUS_PRODUCT_FALIURE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FALIURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FALIURE,
  UPDATE_PRODUCT_PRICE_REQUEST,
  UPDATE_PRODUCT_PRICE_SUCCESS,
  UPDATE_PRODUCT_PRICE_FALIURE,
  FETCH_SINGLEPRODUCTS_REQUEST,
  FETCH_SINGLEPRODUCTS_SUCCESS,
  FETCH_SINGLEPRODUCTS_FALIURE,
  ADD_PRODUCT_GROUP_REQUEST,
  ADD_PRODUCT_GROUP_SUCCESS,
  ADD_PRODUCT_GROUP_FALIURE,
  DELETE_PRODUCT_GROUP_REQUEST,
  DELETE_PRODUCT_GROUP_SUCCESS,
  DELETE_PRODUCT_GROUP_FALIURE
} from './AllCategoriesConstants'
import {config} from '../config'

export const fetchAllCategoriesRequest = (category_info) =>{
  return{
    type : FETCH_ALLCATEGORIES_REQUEST,
    payload:category_info
  }
}

export const fetchAllCategoriesSuccess = (all_categories_success) =>{
  return{
    type : FETCH_ALLCATEGORIES_SUCCESS,
    payload : all_categories_success
  }
}

export const fetchAllCategoriesFaliure = (error) =>{
  return{
    type : FETCH_ALLCATEGORIES_FALIURE,
    payload : error
  }
}

export const editCategoryRequest = (edit_category) =>{
  return{
    type : UPDATE_CATEGORY_REQUEST,
    payload:edit_category
  }
}

export const editCategorySuccess = (edit_category_success) =>{
  return{
    type : UPDATE_CATEGORY_SUCCESS,
    payload : edit_category_success
  }
}

export const editCategoryFaliure = (error) =>{
  return{
    type : UPDATE_CATEGORY_FALIURE,
    payload : error
  }
}

export const statusProductRequest = (status_product) =>{
  return{
    type : STATUS_PRODUCT_REQUEST,
    payload:status_product
  }
}

export const statusProductSuccess = (status_product_success) =>{
  return{
    type : STATUS_PRODUCT_SUCCESS,
    payload : status_product_success
  }
}

export const statusProductFaliure = (error) =>{
  return{
    type : STATUS_PRODUCT_FALIURE,
    payload : error
  }
}

export const editProductRequest = (edit_product) =>{
  return{
    type : UPDATE_PRODUCT_REQUEST,
    payload:edit_product
  }
}

export const editProductSuccess = (edit_product_success) =>{
  return{
    type : UPDATE_PRODUCT_SUCCESS,
    payload : edit_product_success
  }
}

export const editProductFaliure = (error) =>{
  return{
    type : UPDATE_PRODUCT_FALIURE,
    payload : error
  }
}

export const editProductPriceRequest = (edit_product_price) =>{
  return{
    type : UPDATE_PRODUCT_PRICE_REQUEST,
    payload:edit_product_price
  }
}

export const editProductPriceSuccess = (edit_product_price_success) =>{
  return{
    type : UPDATE_PRODUCT_PRICE_SUCCESS,
    payload : edit_product_price_success
  }
}

export const editProductPriceFaliure = (error) =>{
  return{
    type : UPDATE_PRODUCT_PRICE_FALIURE,
    payload : error
  }
}

export const fetchProductsRequest = (category_info) =>{
  return{
    type : FETCH_PRODUCTS_REQUEST,
    payload:category_info
  }
}

export const fetchProductsSuccess = (all_categories_success) =>{
  return{
    type : FETCH_PRODUCTS_SUCCESS,
    payload : all_categories_success
  }
}

export const fetchProductsFaliure = (error) =>{
  return{
    type : FETCH_PRODUCTS_FALIURE,
    payload : error
  }
}

export const stockProductRequest = (stock_product) =>{
  return{
    type : STOCK_PRODUCT_REQUEST,
    payload:stock_product
  }
}

export const stockProductSuccess = (stock_product_success) =>{
  return{
    type : STOCK_PRODUCT_SUCCESS,
    payload : stock_product_success
  }
}

export const stockProductFaliure = (error) =>{
  return{
    type : STOCK_PRODUCT_FALIURE,
    payload : error
  }
}

export const fetchsingleProductRequest = (category_info) =>{
  return{
    type : FETCH_SINGLEPRODUCTS_REQUEST,
    payload:category_info
  }
}

export const fetchsingleProductSuccess = (all_categories_success) =>{
  return{
    type : FETCH_SINGLEPRODUCTS_SUCCESS,
    payload : all_categories_success
  }
}

export const fetchsingleProductFaliure = (error) =>{
  return{
    type : FETCH_SINGLEPRODUCTS_FALIURE,
    payload : error
  }
}

export const addProductGroupRequest = (add_product_group) =>{
  return{
    type : ADD_PRODUCT_GROUP_REQUEST,
    payload:add_product_group
  }
}

export const addProductGroupSuccess = (add_product_group_success) =>{
  return{
    type : ADD_PRODUCT_GROUP_SUCCESS,
    payload : add_product_group_success
  }
}

export const addProductGroupFaliure = (error) =>{
  return{
    type : ADD_PRODUCT_GROUP_FALIURE,
    payload : error
  }
}

export const deleteProductGroupRequest = (delete_product_status) =>{
  return{
    type : DELETE_PRODUCT_GROUP_REQUEST,
    payload : delete_product_status
  }
}

export const deleteProductGroupSuccess = (delete_product_status_success) =>{
  return{
    type : DELETE_PRODUCT_GROUP_SUCCESS,
    payload : delete_product_status_success
  }
}

export const deleteProductGroupFaliure = (error) =>{
  return{
    type : DELETE_PRODUCT_GROUP_FALIURE,
    payload : error
  }
}

export const fetchAllCategories = (category_info,currentPage) =>{
  // console.log('apireq', category_info );
    return(dispatch) => {
      dispatch(fetchAllCategoriesRequest(category_info))
      const writter = category_info != undefined ? category_info.writter != "undefined" ? category_info.writter : undefined :undefined
      const classId = category_info != undefined ? category_info.classId != "undefined"  ? category_info.classId : undefined : undefined
      const published = category_info != undefined ? category_info.published != "undefined" ? category_info.published : undefined : undefined
      const subjectId = category_info != undefined ? category_info.subjectId != "undefined"  ? category_info.subjectId : undefined :undefined
      const bookType = category_info != undefined ? category_info.bookType != "undefined"  ? category_info.bookType : undefined :undefined
      const selectedFilter = category_info != undefined && category_info.filter != "undefined" && category_info.filter != "all" ? category_info.filter : undefined

      const url =`${config.api_root}/api/v1/merchants/categories?pageNumber=0&user_id=${config.user_id}&pageSize=100`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      }
    fetch(url, request_option)
    .then(response => response.json())
    .then(book_res =>{
      const all_categories_success = book_res
      dispatch(fetchAllCategoriesSuccess(all_categories_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchAllCategoriesFaliure(errorMsg))
    })
    }
}

export const fetchproducts = (category_info) =>{
  // console.log('apireq', category_info );
    return(dispatch) => {
      dispatch(fetchProductsRequest(category_info))

      const url =`${config.api_root}/api/v1/merchants/products/${category_info}?size=100&user_id=${config.user_id}&offset=0`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      }
    fetch(url, request_option)
    .then(response => response.json())
    .then(book_res =>{
      const all_categories_success = book_res
      dispatch(fetchProductsSuccess(all_categories_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchProductsFaliure(errorMsg))
    })
    }
}

// for manage category

export const fetchcategory = (category_info) =>{
  // console.log('apireq', category_info );
    return(dispatch) => {
      dispatch(fetchProductsRequest(category_info))

      const url =`${config.api_root}/api/v1/merchants/product/${category_info}?user_id=${config.user_id}&offset=0`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      }
    fetch(url, request_option)
    .then(response => response.json())
    .then(book_res =>{
      const all_categories_success = book_res
      dispatch(fetchProductsSuccess(all_categories_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchProductsFaliure(errorMsg))
    })
    }
}

export const fetchsingleproduct = (category_info) =>{
  // console.log('apireq', category_info );
    return(dispatch) => {
      dispatch(fetchsingleProductRequest(category_info))

      const url =`${config.api_root}/api/v1/merchants/products/${category_info}/addons?user_id=${config.user_id}&offset=0`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      }
    fetch(url, request_option)
    .then(response => response.json())
    .then(book_res =>{
      const all_categories_success = book_res
      console.log("all_categories_success==="+JSON.stringify(all_categories_success))
      dispatch(fetchsingleProductSuccess(all_categories_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchsingleProductFaliure(errorMsg))
    })
    }
}


export const updateCategory = (category_info) =>{
  // console.log('apireq', category_info );
    let resp = null;
    return(dispatch) => {
      dispatch(editCategoryRequest())
      const url =`${config.api_root}/api/v1/merchants/categories`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      body: JSON.stringify({
        user_id : config.user_id,
        fields:{
        categoryId: category_info.catID,
        categoryName:category_info.catName,
        order:category_info.orderIndex,
        descriptions:category_info.catDes,
        status:category_info.catStatus
        },
      })
    }
    fetch(url, request_option)
    .then(response => {
      resp = response.ok;
      return response.json();
    })
    .then(book_res => {
      const all_categories_success = book_res
      if(resp) {
        dispatch(editCategorySuccess(all_categories_success))
      } else {
        dispatch(editCategoryFaliure(all_categories_success))  
      }
    })
    .catch(error => {
      console.log('reset-->'+error)
      const errorMsg = error
      dispatch(editCategoryFaliure(errorMsg))
    })
    }
}


export const enableStock = (productId) =>{
    return(dispatch) => {
      dispatch(stockProductRequest())
      const url =`${config.api_root}/api/v1/merchants/enable_out_of_stock?user_id=${config.user_id}&product_id=${productId}`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(book_res =>{
      const all_categories_success = book_res
      dispatch(stockProductSuccess(all_categories_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(stockProductFaliure(errorMsg))
    })
    }
}

export const disableStock = (productId) =>{
    return(dispatch) => {
      dispatch(stockProductRequest())
      const url =`${config.api_root}/api/v1/merchants/disable_stock_management?user_id=${config.user_id}&product_id=${productId}`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(book_res =>{
      const all_categories_success = book_res
      dispatch(stockProductSuccess(all_categories_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(stockProductFaliure(errorMsg))
    })
    }
}



export const enableProduct = (productId) =>{
    return(dispatch) => {
     dispatch(statusProductRequest())
      const url =`${config.api_root}/api/v1/merchants/products/${productId}/enable?user_id=${config.user_id}`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(book_res =>{
      const all_categories_success = book_res;
      console.log(all_categories_success)
     dispatch(statusProductSuccess(all_categories_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(statusProductFaliure(errorMsg))
    })
    }
}

export const disableProduct = (productId) =>{
    return(dispatch) => {
      dispatch(statusProductRequest())
      const url =`${config.api_root}/api/v1/merchants/products/${productId}/disable?user_id=${config.user_id}`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(book_res =>{
      const all_categories_success = book_res;
      console.log(all_categories_success)
      dispatch(statusProductSuccess(all_categories_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(statusProductFaliure(errorMsg))
    })
    }
}


export const updateProductPrice = (productdata) =>{
    return(dispatch) => {
      dispatch(editProductPriceRequest())
      const url =`${config.api_root}/api/v1/merchants/products/${productdata.productId}/price?user_id=${config.user_id}`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            includeInSale: true,
            salePrice:productdata.price,
            regularPrice:productdata.price

          })
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(book_res =>{
      const edit_price_success = book_res
      if(edit_price_success.request_status) 
        dispatch(editProductPriceSuccess(edit_price_success))
      else  
        dispatch(editProductPriceFaliure(edit_price_success)) 
    })
    .catch(error => {
      const errorMsg = error
      dispatch(editProductPriceFaliure(errorMsg))
    })
    }
}

export const updateProductMemo = (productdata) =>{
    let resp = null;
    return(dispatch) => {
     dispatch(editProductRequest())
      const url =`${config.api_root}/api/v1/merchants/products/${productdata.productId}/memo?user_id=${config.user_id}`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: productdata.name,
            descriptions:productdata.description,
            shortDescription:productdata.shortDescription,
            image:productdata.image,
          })
    }
    fetch(url, request_option)
    .then(response => {
      resp = response.ok;
      return response.json();
    })
    .then(book_res =>{
      const all_categories_success = book_res
      if(resp) {
         dispatch(editProductSuccess(all_categories_success))
      } else {
         dispatch(editProductFaliure(all_categories_success))
      }
    })
    .catch(error => {
      const errorMsg = error
      dispatch(editCategoryFaliure(errorMsg))
    })
    }
}

export const updateProductAddons = (productId, addonGroupId) =>{
    return(dispatch) => {
      dispatch(addProductGroupRequest())
      const url =`${config.api_root}/api/v1/merchants/products/${productId}/addons/${addonGroupId}?user_id=${config.user_id}`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(book_res =>{
      const add_product_group = book_res
      dispatch(addProductGroupSuccess(add_product_group))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(addProductGroupFaliure(errorMsg))
    })
    }
}

export const deleteProductAddons = (productId, addonGroupId) =>{
    return(dispatch) => {
      dispatch(deleteProductGroupRequest())
      const url =`${config.api_root}/api/v1/merchants/products/${productId}/addons/${addonGroupId}?user_id=${config.user_id}`;
      const request_option = {
      method: "DELETE",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(book_res =>{
      const delete_product_group = book_res
     dispatch(deleteProductGroupSuccess(delete_product_group))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(deleteProductGroupFaliure(errorMsg))
    })
    }
}


export const deleteProductCategory = (productId, catId) =>{
  return(dispatch) => {
    dispatch(deleteProductGroupRequest())
    const url =`${config.api_root}/api/v1/merchants/product/${productId}/${catId}?user_id=${config.user_id}`;
    const request_option = {
    method: "DELETE",
    headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
        }
  }
  fetch(url, request_option)
  .then(response => response.json())
  .then(book_res =>{
    const delete_product_group = book_res
   dispatch(deleteProductGroupSuccess(delete_product_group))
  })
  .catch(error => {
    const errorMsg = error
    dispatch(deleteProductGroupFaliure(errorMsg))
  })
  }
}

export const updateProductCategory = (productId, catId) =>{
    return(dispatch) => {
  //    dispatch(fetchAllCategoriesRequest(productId))
      const url =`${config.api_root}/api/v1/merchants/product/${productId}/${catId}?user_id=${config.user_id}`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(book_res =>{
      const all_categories_success = book_res
    //  dispatch(fetchAllCategories())
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchAllCategoriesFaliure(errorMsg))
    })
    }
}

// export const deleteProductCategory = (productId, catId) =>{
 
//     return(dispatch) => {
//   //    dispatch(fetchAllCategoriesRequest(productId))
//       const url =`${config.api_root}/api/v1/merchants/product/${productId}/${catId}?user_id=${config.user_id}`;
//       const request_option = {
//       method: "DELETE",
//       headers: {
//               'Accept': 'application/json',
//               'Authorization': 'Bearer '+ config.token,
//               'Content-Type': 'application/json'
//           }
//     }
//     fetch(url, request_option)
//     .then(response => response.json())
//     .then(book_res =>{
//       const all_categories_success = book_res
//     //  dispatch(fetchAllCategories())
//     })
//     .catch(error => {
//       const errorMsg = error
//       dispatch(fetchAllCategoriesFaliure(errorMsg))
//     })
//     }
// }


// export const addCategory = (category_info) =>{
//   // console.log('apireq', category_info );
//     let resp = null;
//     return(dispatch) => {
//       dispatch(editCategoryRequest())
//       const url =`${config.api_root}/api/v1/merchants/categories`;
//       const request_option = {
//       method: "POST",
//       headers: {
//               'Accept': 'application/json',
//               'Authorization': 'Bearer '+ config.token,
//               'Content-Type': 'application/json'
//           },
//       body: JSON.stringify({
//         user_id : config.user_id,
//         fields:{
//         // categoryId: category_info.catID,
//         name: category_info.name,
//         shortDescription:category_info.shortdescription,
//         descriptions:category_info.description,
//         // price:category_info.price,
//         // categoryName:category_info.catName,
//         order:category_info.orderIndex,
//         // descriptions:category_info.catDes,
//         status:category_info.catStatus
//         },
//       })
//     }
//     fetch(url, request_option)
//     .then(response => {
//       resp = response.ok;
//       return response.json();
//     })
//     .then(book_res => {
//       const all_categories_success = book_res
//       if(resp) {
//         dispatch(editCategorySuccess(all_categories_success))
//       } else {
//         dispatch(editCategoryFaliure(all_categories_success))  
//       }
//     })
//     .catch(error => {
//       console.log('reset-->'+error)
//       const errorMsg = error
//       dispatch(editCategoryFaliure(errorMsg))
//     })
//     }
// }

export const addCategory = (category_info) =>{
  return(dispatch) => {
    dispatch(fetchAllCategoriesRequest(category_info))
    const url =`${config.api_root}/api/v1/merchants/categories`;
    const request_option = {
    method: "POST",
    headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
        },
    body: JSON.stringify({
      user_id : config.user_id,
      fields:{
        categoryName: category_info.name,
        descriptions:category_info.description,
        order:category_info.order,
        status:category_info.catStatus,
      },
    })
  }
  fetch(url, request_option)
  .then(response => response.json())
  .then(add_timing_res =>{
    const all_categories_success = add_timing_res.object.error
    if(add_timing_res.request_status === false){
      dispatch(fetchAllCategoriesFaliure(add_timing_res.object.error))
      console.log(fetchAllCategoriesFaliure(add_timing_res.object.error));
      alert(add_timing_res.object.error)
    }
    dispatch(fetchAllCategoriesSuccess(all_categories_success))
    // dispatch(fetchAllAddons())
  })
  .catch(error => {
    const errorMsg = error
    dispatch(fetchAllCategoriesFaliure(errorMsg))
  })
}
}

// delete category

export const deleteCategory = (addon_id) =>{
  return(dispatch) => {
  dispatch(fetchAllCategoriesRequest(addon_id))
    // const url =`${config.api_root}/api/v1/merchants/group/add_on/${addon_id}?user_id=${config.user_id}`;
    const url =`${config.api_root}/api/v1/merchants/categories/${addon_id}?user_id=${config.user_id}`;
    const request_option = {
    method: "DELETE",
    headers: {
      'Authorization': 'Bearer '+ config.token,
      'Content-Type': 'application/json'
        }
  }
  fetch(url, request_option)
  .then(response => response.json())
  .then(exam_res =>{
    const all_rest_timings_success = exam_res
    //dispatch(fetchAllRestTimingsSuccess(all_rest_timings_success))
  dispatch(fetchAllCategories())
  })
  .catch(error => {
    const errorMsg = error
    dispatch(fetchAllCategoriesFaliure(errorMsg))
  })
  }
}

// add category in product

export const addProductCategory = (productId,categoryId) =>{

  return(dispatch) => {
    dispatch(addProductGroupRequest())
    const url =`${config.api_root}/api/v1/merchants/product/${productId}/${categoryId}?user_id=${config.user_id}`;
    const request_option = {
    method: "POST",
    headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
        }
  }
  fetch(url, request_option)
  .then(response => response.json())
  .then(book_res =>{
    const add_product_group = book_res
    dispatch(addProductGroupSuccess(add_product_group))
  })
  .catch(error => {
    const errorMsg = error
    dispatch(addProductGroupFaliure(errorMsg))
  })
  }
}