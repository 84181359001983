import React, {useState,useEffect,useMemo} from 'react';

import { useSelector,useDispatch } from 'react-redux';
import { fetchSingleAddons, deleteSingleAddon} from '../Redux/AllBook/AllBookActions';
import { restShut } from '../Redux/RestShut/RestShutActions';
import { deliveryShut } from '../Redux/DeliveryShut/DeliveryShutActions';

import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";



function SingleAddonGroups(props){

  const currentAddOnGroupId =
    props &&
    props.currentAddOnGroupId ? props.currentAddOnGroupId
      : null;

  const currentAddOnGroupName =
        props &&
        props.currentAddOnGroupName ? props.currentAddOnGroupName
          : null;

const [restOnOff,setRestOnOff] = useState(false)
const [deliveryOnOff,setDeliveryOnOff] = useState(false)
const allBookData = useSelector(state =>state.AllBook)
const restStateData = useSelector(state =>state.RestShutStatus)
const deliveryStateData = useSelector(state =>state.DeliveryShutStatus)

// store data access End
const dispatch = useDispatch()  // for accessing the redux function

const [allBookResult,setBookResult] = useState([])

const [data,setData] = useState([])
const [currentorderTab,setCurrentorderTab] = useState(0)
  const [singleOrder, setSingleOrder] = useState([])



// fetch allBook  api hook start
useEffect(() =>{
  dispatch(fetchSingleAddons(currentAddOnGroupId))
  dispatch(restShut())
  dispatch(deliveryShut())
},[dispatch])
// fetch allBook api hook End


// add data of allFees api into constant,hook start
useMemo(() =>{
  if(allBookData && allBookData.all_addon && allBookData.all_addon.data && allBookData.all_addon.data.length > 0){
    setBookResult(allBookData.all_addon.data)
    setData(allBookData.all_addon.data)
  }
  else{
    setBookResult([])
    setData(allBookData.all_addon.data)
  }
},[allBookData])
// add data of allFees api into constant,hook End

// add data of allFees api into constant,hook start
useMemo(() =>{
  if(restStateData && restStateData.rest_shut && restStateData.rest_shut.status){
    setRestOnOff(restStateData.rest_shut.status)
  }
},[restStateData])
// add data of allFees api into constant,hook End

// add data of allFees api into constant,hook start
useMemo(() =>{
  if(deliveryStateData && deliveryStateData.delivery_shut && deliveryStateData.delivery_shut.status){
    setDeliveryOnOff(deliveryStateData.delivery_shut.status)
  }
},[deliveryStateData])
// add data of allFees api into constant,hook End





const handleDeleteAddon = (id) => {

dispatch(deleteSingleAddon(currentAddOnGroupId, id))
//dispatch(fetchAllAddons())
}




        return (

          <div className="dashboard-content-one">



        {/* Dashboard Content Start Here */}
      <div className="row gutters-20">
        <div className="col-12 col-xl-12 col-12-xxxl">
          <div className="card dashboard-card-one pd-b-20">
            <div className="card-body">
              <div className="heading-layout1">


              </div>
              <div className="earning-report1">
              {singleOrder && singleOrder.length > 0 ? (
                  <div className="card height-auto">
                    <div className="card-body">
                      <div className="heading-layout1">
                        <div className="item-title">

                        </div>

                      </div>
                        <div className="single-info-details">

                          <div className="item-content">
                            <div className="header-inline item-header">

                            </div>
                            <div className="info-table table-responsive">
                              <table className="table text-nowrap">
                                <tbody>
                                  <tr>
                                    <td>Service Type:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>
                                  <tr>
                                    <td>Order ID:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>
                                  <tr>
                                    <td>Date:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>
                                  <tr>
                                    <td>Time:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>
                                  <tr>
                                    <td>Order By:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>
                                  <tr>
                                    <td>Order Item Details:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>
                                  <tr>
                                    <td>SubTotal:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>
                                  <tr>
                                    <td>Tip:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>
                                  <tr>
                                    <td>Tax:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>
                                  <tr>
                                    <td>Total:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>
                                  <tr>
                                    <td>Note:</td>
                                    <td className="font-medium text-dark-medium"></td>
                                  </tr>

                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>


                    </div>
                  </div>

                ) : (
                  <div className="card">
                      <div className="card-body">

                        <div className="table-box-wrap">




                            {allBookData && allBookData.all_addon_loaded === true ?
                              (<div className="card-body">
                              <div className="Student-Loader">
                              <Loader
                              className = "student-detail-loader"
                            type="ThreeDots"
                            color="#fea801"
                            height={150}
                            width={150}

               />
                              </div>
                            </div>) : (<div className="table-responsive expenses-table-box">

                          <table className="table data-table text-nowrap">
                              <thead>
                                <tr>
                                  <th>
                                    Name
                                  </th>
                                  <th>
                                    NameKey(without spaces) *
                                  </th>

                                  <th className="hidethisfornow">Quantitative</th>
                                  <th className="hidethisfornow">Taxable</th>
                                  <th>Unit Price</th>
                                  <th className="hidethisfornow">Action</th>

                                </tr>
                              </thead>
                              <tbody>
                              {allBookResult && allBookResult && allBookResult.length > 0 && allBookResult.map((item,index)=>{
                                return (
                                  <tr>
                                    <td>{item.name}</td>
                                      <td>{item.nameKey}</td>
                                    <td className="hidethisfornow">{item.quantitative === true ? 'TRUE' : 'FALSE'}</td>
                                    <td className="hidethisfornow">{item.taxable === true ? 'TRUE' : 'FALSE'}</td>
                                    <td>${item.unitPrice}</td>
                                                            <td className="hidethisfornow">


                                    <button onClick={e=>handleDeleteAddon(item.addOnId)} type="button" className="btn btn-danger restdelete"><i className="fa fa-trash" aria-hidden="true"></i></button></td>
                                  </tr>
                                )
                              })}


                              </tbody>
                            </table>
                          </div>)}
                        </div>
                      </div>
                    </div>

                ) }
              </div>

            </div>
          </div>
        </div>



      </div>
      {/* Dashboard Content End Here */}

      </div>
        );
}

export default SingleAddonGroups;
