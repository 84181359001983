
export function wordwrap(wordArr, len, isTab, spaceLen){
  let sb = '';
  let sbStr = '';
  let space = '';

  for (var i=0; i < spaceLen; i++){
     space = space+" ";
  }
  wordArr.forEach(word => {
      if(sb.length < 1){
          sb = sb+word.trim()+" ";
          sbStr = sbStr+word.trim()+" ";
      } else if((sb.length+word.trim().length) > len){
          sb = '';
          sb = sb+word.trim()+" ";
          if(!isTab) {
            sbStr = sbStr+"\n\t"+space+word.trim()+" ";
          } else {
            sbStr = sbStr+"\n\t\t"+space+word.trim()+" ";
          }
      } else {
          sb = sb+word.trim()+" ";
          sbStr = sbStr+word.trim()+" ";
      }
  });
  return sbStr;
}

