import {
  EGIFTCARD_REDEEM_REQUEST,
  EGIFTCARD_REDEEM_SUCCESS,
  EGIFTCARD_REDEEM_FALIURE,
  EGIFTCARD_BALANCE_REQUEST,
  EGIFTCARD_BALANCE_SUCCESS,
  EGIFTCARD_BALANCE_FALIURE
} from './EGiftCardRedeemConstants'

const intialstate = {
  egift_card_redeem_loaded : true,
  egift_card_redeem : '',
  egift_card_redeem_error : '',
  egift_card_redeem_success : false,
  egift_card_balance_loaded : true,
  egift_card_balance : '',
  egift_card_balance_error : '',
  egift_card_balance_success : false
}

const EGiftCardRedeemReducers = (state = intialstate,action) =>{
  switch(action.type){
    case EGIFTCARD_REDEEM_REQUEST : return{
      ...state,
      egift_card_redeem_loaded:false
    }
    case EGIFTCARD_REDEEM_SUCCESS : return{
      ...state,
      egift_card_redeem_loaded:true,
      egift_card_redeem_success:true,
      egift_card_redeem:action.payload
    }
    case EGIFTCARD_REDEEM_FALIURE : return{
      ...state,
      egift_card_redeem_loaded:true,
      egift_card_redeem_error:action.payload
    }
    case EGIFTCARD_BALANCE_REQUEST : return{
      ...state,
      egift_card_balance_loaded:false
    }
    case EGIFTCARD_BALANCE_SUCCESS : return{
      ...state,
      egift_card_balance_loaded:true,
      egift_card_balance_success:true,
      egift_card_balance:action.payload
    }
    case EGIFTCARD_BALANCE_FALIURE : return{
      ...state,
      egift_card_balance_loaded:true,
      egift_card_balance_error:action.payload
    }
    default: return state
  }

}

export default EGiftCardRedeemReducers
