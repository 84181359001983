// Firebase Cloud Messaging Configuration File. 
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';


var firebaseConfig = {
  apiKey: "AIzaSyDFkHqO9LjHBRXs8t_HFBMFnjrTxZYD-Dk",
  authDomain: "diningtekprodweb.firebaseapp.com",
  projectId: "diningtekprodweb",
  storageBucket: "diningtekprodweb.appspot.com",
  messagingSenderId: "792145422840",
  appId: "1:792145422840:web:ca10606db687dd93a5ce0d",
  measurementId: "G-967NSCW1RB"
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: 'BDgjIPoOC2ri5q2-4XI8jZWYqP5tbqneRCeYOrruqpgj1LhPsT52pA7woPfE81WcoUpP5CYQOdQ9NOi3UlNEuAs' })
    .then((currentToken) => {
      if (currentToken) {

        if(!localStorage.getItem('device_token')) {
          localStorage.setItem('new_token', 'true');
        } else if(localStorage.getItem('device_token') != currentToken) {
          localStorage.setItem('new_token', 'true');
        }       
        localStorage.setItem('device_token', currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });

    
};



// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {    
    onMessage(messaging, (payload) => {
      
      resolve(payload);
    });
  });

  
