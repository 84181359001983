import {
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FALIURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FALIURE
} from './ProfileConstants'
import {config} from '../config'

export const updateProfileRequest = (update_profile_req) =>{
  return{
    type : UPDATE_PROFILE_REQUEST,
    payload : update_profile_req
  }
}

export const updateProfileSuccess = (update_profile) =>{
  return{
    type : UPDATE_PROFILE_SUCCESS,
    payload : update_profile
  }
}

export const updateProfileFaliure = (error) =>{
  return{
    type : UPDATE_PROFILE_FALIURE,
    payload : error
  }
}

export const getProfileRequest = (get_profile_req) =>{
  return{
    type : GET_PROFILE_REQUEST,
    payload : get_profile_req
  }
}

export const getProfileSuccess = (profile) =>{
  return{
    type : GET_PROFILE_SUCCESS,
    payload : profile
  }
}

export const getProfileFaliure = (error) =>{
  return{
    type : GET_PROFILE_FALIURE,
    payload : error
  }
}

export const updateProfile = (profile) =>{
  return(dispatch) => {
    dispatch(updateProfileRequest(false))
    const url =`${config.api_root}/api/v1/merchants/update_profile?mid=${config.mid}`
    const request_option = {
       method: "POST",
       headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
       },
       body: JSON.stringify({
          user_id : config.user_id,
          fields:{
            name: profile.amount,
            tagLine: profile.tagLine,
            address: profile.address,
            landMark: profile.landMark,
            city: profile.city,
            state: profile.state
          },
       }) 
    }
    fetch(url, request_option)
     .then(response => response.json() )
     .then(res =>{
        dispatch(updateProfileSuccess(res))
     })
    .catch(error => {
      const errorMsg = error
      dispatch(updateProfileFaliure(errorMsg))
    })
  }
}


export const getProfile = () =>{
  return(dispatch) => {
    dispatch(getProfileRequest(false))
    const url =`${config.api_root}/api/v1/merchants/profile?mid=${config.mid}`
    const request_option = {
       method: "GET",
       headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
       } 
    }
    fetch(url, request_option)
     .then(response => response.json() )
     .then(res =>{
        dispatch(getProfileSuccess(res))
     })
    .catch(error => {
      const errorMsg = error
      dispatch(getProfileFaliure(errorMsg))
    })
  }
}