import {
  DELIVERY_SHUT_REQUEST_UPDATE,
  DELIVERY_SHUT_REQUEST,
  DELIVERY_SHUT_SUCCESS,
  DELIVERY_SHUT_FALIURE
} from './DeliveryShutConstants'

const intialstate = {
  delivery_shut_loading : false,
  delivery_shut : [],
  delivery_shut_error : '',
  delivery_shut_success : false
}

const DeliveryShutReducers = (state = intialstate,action) =>{
  switch(action.type){
    case DELIVERY_SHUT_REQUEST : return{
      ...state,
      delivery_shut_loading:true
    }
    case DELIVERY_SHUT_REQUEST_UPDATE : return{
      ...state,
      delivery_shut_loading:true,
      delivery_shut:action.payload
    }
    case DELIVERY_SHUT_SUCCESS : return{
      ...state,
      delivery_shut_loading:false,
      delivery_shut_success:true,
      delivery_shut:action.payload
    }
    case DELIVERY_SHUT_FALIURE : return{
      ...state,
      delivery_shut_error:action.payload
    }
    default: return state
  }

}

export default DeliveryShutReducers
