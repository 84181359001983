import React, { useState, useEffect, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { useSelector, useDispatch } from 'react-redux'

import { fetchAllCategories, addProductCategory, deleteProductCategory, fetchcategory } from '../Redux/AllCategories/AllCategoriesActions';
import { Link, useHistory, useLocation } from "react-router-dom";

function EditProductCategory() {
  // store data access start
  const productGroupData = useSelector(state => state.AllCategories)
  // const addonGroupData = useSelector(state => state.AllBook);
  // store data access End
  const dispatch = useDispatch()  // for accessing the redux function
  const { state } = useLocation()
  const history = useHistory();
  // component all states define start
  const [checkedItems, setCheckedItems] = useState({});
  const [productAddonsResult, setProductAddonsResult] = useState([]);
  const [productAddonsloaded, setProductAddonsloaded] = useState('')
  const [saveState, setSaveState] = useState('')
  const [indexState, setIndexState] = useState(0)
  const [allGroupResult, setGroupResult] = useState([])
  const [selectedGroups, setSelectedGroups] = useState({});

  const [allCategoriesResult,setAllCategoriesResult] = useState([])
  const [productValues, setProductValues] = useState({
    productId: state.productId,
    name: state.productName
  })

  const goToBack = () => {
    history.goBack();
  }

  // try



  useEffect(() => {
    dispatch(fetchAllCategories())
    if (!productGroupData.single_product.data) {
      setIndexState(0);
      dispatch(fetchcategory(state.productId));
    }

  }, [dispatch]);


  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);


  
  const allProductsData = useSelector(state => state.AllProduct);
 

  const data1 = allProductsData.all_products.data;
  useEffect(() => {
    if (data1 && data1.length > 0) {
    setTotalPages(Math.ceil(data1.length / pageSize));
    }
    }, [data1, pageSize]);
    
   
  

  




  useMemo(() => {
    const groups = [];
    if (productGroupData && productGroupData.AllCategories && productGroupData.AllCategories && productGroupData.AllCategories.data.length > 0) {
      setGroupResult(productGroupData.AllCategories.data)
      productGroupData.AllCategories.data.map((item, index) => {
        groups.push({ value: item.categoryId, label: item.name })
      })
   
    }
    else {
      setGroupResult([])
    }
    setSelectedGroups(groups)
  }, [productGroupData])


  // displaying data in table of category
  useMemo(() => {
    if (productGroupData && productGroupData.single_product && productGroupData.single_product.data) {
      const selectedAddons = {};
      setProductAddonsloaded(false)
      let addonGroups = productGroupData.single_product.data.reverse();
      setProductAddonsResult(addonGroups);
      if (productGroupData.single_product_loaded) {
        setProductAddonsloaded(true);
      }
      
      addonGroups.map((item, index) => {
        selectedAddons[item.categoryId] = true;
      })
      setIndexState(0);
      setCheckedItems(selectedAddons);
      // console.log('checkedItems', checkedItems)
    }
  }, [productGroupData]);


  // end
  
  useMemo(() => {
    if (productGroupData && productGroupData.add_product_group && productGroupData.add_product_group_loaded && saveState == 'add') {
      setSaveState('');
      dispatch(fetchcategory(state.productId));
    }
  }, [productGroupData])

  useMemo(() => {
    if (productGroupData && productGroupData.delete_product_group && productGroupData.delete_product_group_loaded && saveState == 'delete') {
      setSaveState('');
      dispatch(fetchcategory(state.productId));
    }
  }, [productGroupData])

  

  const handleChange2 = event => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
    if (event.target.checked === true) {
      setSaveState('add');
      dispatch(addProductCategory(state.productId, event.target.name))
    }
    else {
      setSaveState('delete');
      dispatch(deleteProductCategory(state.productId, event.target.name))
    }
  
  };

  const Checkbox = ({ type = "checkbox", id, name, checked = false, onChange }) => {

    return (
      <input type={type} id={id} name={name} checked={checked} onChange={onChange} className="stv-radio-button" />
    );
  };


  const [categoryStatus,setCategoryStatus] = useState('ACTIVE')
 
  const [editCatMode,setEditCatMode] = useState(false)

const allCategoriesData = useSelector(state =>state.AllCategories)

useMemo(() =>{
    if(allCategoriesData && allCategoriesData.all_categories){
      setAllCategoriesResult(allCategoriesData.all_categories.data)
    }
  },[allCategoriesData])

  return (
    <>

      <div className="breadcrumbs-area">
        <button className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark right-float" onClick={(e) => goToBack()}>Back</button>
        <h3>Categories</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li><Link to="/product">All Products</Link></li>
        
          <li onClick={(e) => goToBack()}>{productValues.name}</li>
          <li>Categories</li>
        </ul>
      </div>


      <div className="row">
        <div className="col-12-xxxl col-12">
          <div className="card">
         
            <div className="heading-layout1">
              <div className="item-title">
                <h3>Manage Category Group</h3>

              </div>

            </div>
           
           
          <div className="card">
            <div className="card-body2" style={{ marginBottom: '15px'}}>            
             
              <div style={{padding:'10px', color:'#000000'}}>Categories:
                    
                   </div>    
                                                                             
                    <div className="row">
        <div className="col-lg-6 col-12 form-group">
        <div className="mainbox">
     

       

        {allCategoriesData && allCategoriesData.all_categories_loading === true ?
          (<div className={editCatMode && editCatMode ===  true ? 'card-body hidden' : 'card-body'}>
          <div className="Student-Loader">
          <Loader
          className = "student-detail-loader"
        type="ThreeDots"
        color="#fea801"
        height={150}
        width={150}

/>
          </div>
        </div>) : (
        <div className={ editCatMode && editCatMode === true ? 'table-responsive expenses-table-box hidden' : 'table-responsive expenses-table-box'}>
  <div className="col-lg-12 col-12 form-group">
                <span className="">

  
          {allCategoriesResult && allCategoriesResult.length > 0 && allCategoriesResult.filter(list => list.status === categoryStatus).map((item,index)=>{
          
            return (
              <>
              
              <label htmlFor={item.categoryId} key={item.categoryId} className="lableforaddon">
  
             
              <Checkbox
id={item.categoryId}
name={item.categoryId}
checked={checkedItems[item.categoryId]}
onChange={handleChange2}

/>


<span className="btn-fill-1md radius-30 text-dark bg-dodger-blue unselect">{item.categoryName} <span className="delete-addon"><i className="fa fa-times" aria-hidden="true"></i></span></span>
            </label>
           
              
              </>
            )
        
          })}
</span>
</div>

       
      </div>)}
        </div>
        </div>

       
        </div>    
              
            </div>
          
          </div>
        </div>



          </div>
        </div>
    


    </>

  );
}

export default EditProductCategory;
