import React,{useState}from 'react';
import { Link } from 'react-router-dom';
import Contact from './Contact';


function AllOrders() {
        return (
            
          <>
          <div className="breadcrumbs-area">
            <h3>Contact Us</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Contact Us</li>
            </ul>
          </div>
            {/* Breadcubs Area Start Here */}
            <Contact />
            {/* Add New Teacher Area End Here */}
          </>
        );
}

export default AllOrders;
