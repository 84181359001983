import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProfile, updateProfile } from '../Redux/Profile/ProfileActions'
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Link } from 'react-router-dom';
import { fetchStates } from '../Redux/AddressCommons/AddressCommonsActions';
import {
  FormControl,
  FormGroup,
  FormLabel
} from "react-bootstrap";

function EditProfile() {

  const profileData = useSelector(state => state.Profile)
  const addressCommonsData = useSelector(state => state.AddressCommons)
  // store data access End
  const dispatch = useDispatch()  // for accessing the redux function
  // const [profileResult, setProfileResult] = useState({})
  const [responseMessage, setResponseMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [statesResult, setStatesResult] = useState('')
  const [error, setError] = useState({
    name: "",
    tagLine: "",
    address: "",
    landMark: "",
    city: "",
    state: ""
  })
  const [inputValues, setInputValues] = useState({
    name: "",
    tagLine: "",
    address: "",
    landMark: "",
    city: "",
    state: ""
  })

  useEffect(() =>{
     dispatch(getProfile(inputValues))
  },[dispatch])

  // add data of allFees api into constant,hook start

  useMemo(() => {
    if (profileData && !profileData.is_loading) {  
      if (profileData.profile && profileData.profile.request_status && profileData.profile.object) {
        //  setProfileResult(profileData.profile.object);
         let profileObj = {
            name: profileData.profile.object.name,
            tagLine: profileData.profile.object.tagLine,
            address: profileData.profile.object.address,
            landMark: profileData.profile.object.landMark,
            city: profileData.profile.object.city,
            state: profileData.profile.object.state
         }
         dispatch(fetchStates(254));
         setInputValues(profileObj)
      }
      if (profileData.update_profile && profileData.update_profile.request_status && profileData.update_profile.object) {
         setResponseMessage('update Successfully!')
      } else if (profileData.error) {
         setErrorMessage(profileData.error)
      }
    }
  }, [profileData])

  useMemo(() => {
    if (addressCommonsData && addressCommonsData.states) {
      if (addressCommonsData.states.data) {
        setStatesResult(addressCommonsData.states.data)
      }
    }
    //setResponseMessage("");
    //setErrorMessage('');
  }, [addressCommonsData])
  

  useMemo(() => {
    if (error) {
      setError(error);
    }
  }, [error])


  const profileValidate = () => {
    let error = {
      name: "",
      tagLine: "",
      address: "",
      landMark: "",
      city: "",
      state: ""
    }
    let isError = false;
    if (!inputValues.name) {
      error.name = 'please enter name!';
      isError = true;
    }
    if (!inputValues.tagLine) {
      error.tagLine = 'please enter tagLine!';
      isError = true;
    }
    if (!inputValues.address) {
      error.address = 'please enter address!';
      isError = true;
    }
    if (!inputValues.landMark) {
      error.landMark = 'please enter land Mark!';
      isError = true;
    }
    if (!inputValues.city) {
      error.city = 'please enter city!';
      isError = true;
    }
    if (!inputValues.state) {
      error.state = 'please enter state!';
      isError = true;
    }
    setError(error);
    return isError;
  }

  const editProfile = () => {
    if (!profileValidate()) {
      profileData.profile.object.state=inputValues.state;
      dispatch(updateProfile(inputValues))
    }
  }


  return (
    <>
      <div className="breadcrumbs-area">
        <h3>Edit Profile</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Edit Profile</li>
        </ul>
      </div>
      {/* Breadcubs Area Start Here */}


      <div className="card-body mx-width-600">

        <div className="heading-layout1">
          <div className="item-title">

          </div>

        </div>

        {responseMessage ?
          <p className="alert alert-success w-auto" role="alert">
            {responseMessage}
          </p> : <p></p>
        }

        {errorMessage ?
          <p className="alert alert-danger w-auto" role="alert">
            {errorMessage}
          </p> : <p></p>
        }


<div className="pad-5 bg-darkblue height-50">
          <ul className="nav nav-tabs justify-content-center" role="tablist">
            <li>
              <a href="#" className='nav-link mg-r-10 active show' aria-controls="billing" role="link" aria-expanded="true" aria-disabled="true">Update Profile Details</a>
            </li>
          </ul>
        </div>
        <div className="tab-content single-info-details mx-width-600">
          <div role="tabpanel" className='tab-pane active show' id="billing">
          {profileData.is_loading ?
               <div style={{textAlign: 'center'}}>
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={90}
                    width={150}
                  />
                </div> : <span></span> }
            <div className="row">
              <div className="col-12-xxxl col-lg-6 col-12 form-group mg-0">
                <label>Name</label>
                <input type="text" className="form-control" value={inputValues.name} onChange={(e) => setInputValues({ ...inputValues, name: e.target.value })} required />
                {error.name ? (<span className="addStudent-error float-right">*{error.name}</span>) : ''}
              </div>
              <div className="col-12-xxxl col-lg-6 col-12 form-group mg-0">
                <label>Tag Line</label>
                <input type="text" className="form-control" value={inputValues.tagLine} onChange={(e) => setInputValues({ ...inputValues, tagLine: e.target.value })} required />
                {error.tagLine ? (<span className="addStudent-error float-right">*{error.tagLine}</span>) : ''}
              </div>
              <div className="col-12-xxxl col-lg-6 col-12 form-group mg-0">
                <label>Address</label>
                <input type="text" className="form-control" value={inputValues.address} onChange={(e) => setInputValues({ ...inputValues, address: e.target.value })} required />
                {error.address ? (<span className="addStudent-error float-right">*{error.address}</span>) : ''}
              </div>

              <div className="col-12-xxxl col-lg-6 col-12 form-group mg-0">
                <label>Land Mark</label>
                <input type="text" className="form-control" value={inputValues.landMark} onChange={(e) => setInputValues({ ...inputValues, landMark: e.target.value })} required />
                {error.landMark ? (<span className="addStudent-error float-right">*{error.landMark}</span>) : ''}
              </div>

              <div className="col-12-xxxl col-lg-6 col-12 form-group mg-0">
                <label>City</label>
                <input type="text" className="form-control" value={inputValues.city} onChange={(e) => setInputValues({ ...inputValues, city: e.target.value })} required />
                {error.city ? (<span className="addStudent-error float-right">*{error.city}</span>) : ''}
              </div>
              <div className="col-12-xxxl col-lg-6 col-12 form-group mg-0">

              <FormGroup>
                    <FormLabel>State *</FormLabel>
                    <FormControl
                      type="text"
                      onChange={(e) => setInputValues({ ...inputValues, state: e.target.value })}
                      as="select"
                    >
                      <option value="">Select State</option>
                      {statesResult && statesResult.length > 0 && statesResult.map((state,index)=>{
                           return (<option value={state.name} selected={state.name == inputValues.state}>{state.name}</option>)
                        })
                      }
                    </FormControl>
                    {error.state ? (<span className="addStudent-error float-right">*{error.state}</span>) : ''}
                  </FormGroup> 
              </div>    
            

            </div>
            <div className='float-right mg-t-30'>
               <button type="button" onClick={(e) => editProfile()} className="btn-fill-lg bg-blue-dark btn-hover-yellow right-float">Update Profile</button>
            </div>
          </div>       


        </div>



      </div>

    </>
  );
};

export default EditProfile;
