import React, {useState,useEffect,useMemo} from 'react';
import Loader from 'react-loader-spinner';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
  FormControl,
  FormGroup,
  FormLabel
} from "react-bootstrap";
import { useSelector,useDispatch } from 'react-redux'

import { addTiming } from '../Redux/AddTiming/AddTimingActions'
import {fetchAllRestTimings} from '../Redux/AllRestTimings/AllRestTimingsActions'
import moment from 'moment';
function AddTiming(){
  // store data access start
const classes = useSelector(state =>state.AllClasses)
const sections = useSelector(state =>state.AllSections)
const subjects = useSelector(state =>state.AllSubjects)
const teachers = useSelector(state =>state.AllTeachers)
const addExam_data = useSelector(state =>state.AddTiming)
// store data access End
  const dispatch = useDispatch()  // for accessing the redux function

  // component all states define start
  const [classesResult,setClassesResult] = useState([])
  const [allClassesInfo,setClassesInfo] = useState([])
  const [allSectionsInfo,setSectionsInfo] = useState([])
  const [allSubjectsInfo,setSubjectsInfo] = useState([])
  const [teacherResult,setTeacherResult] = useState([])
  const [allTeachersInfo,setTeachersInfo] = useState([])
  const [inputValues,setInputValues] = useState({
                                          openingTime:"00:00",
                                          closingTime:"18:00",
                                          timingName:"",
                                          openingDay:"",
                                          closingDay:"",
                                          timingType:""

  })
  const [mediumId,setMediumId] = useState('')
    const [error,setError] = useState(null)
    const [successStatus,setSuccessStatus] = useState(null)


const format = 'HH:mm';

const now = moment().hour(0).minute(0);

  // component all states define End

   //hooks start
   // fetch classes and teachers api hook start
   useEffect(() =>{
    // dispatch(fetchAllClasses())
  //   dispatch(fetchAllTeachers())
   },[dispatch])
// fetch classes and teachers api hook End

// add data of classes api into constant,hook start
   useMemo(() =>{
     if(classes && classes.all_classes && classes.all_classes.result && classes.all_classes.success === true){
       setClassesResult(classes.all_classes.result)
     }
   },[classes])
// add data of classes api into constant,hook End

// when classesResult change add data into classInfo,hook start
   useMemo(()=>{
     if(classesResult && classesResult.data){
           setClassesInfo(classesResult.data)
     }
   },[classesResult])
// when classesResult change add data into classInfo,hook End

// fetch allSections and allsubject api,hook start
   useMemo(() =>{
     if(mediumId !='' && inputValues.classId !=''){
      //   dispatch(fetchAllSections(inputValues.classId,mediumId))
      //   dispatch(fetchAllSubjects(inputValues.classId,mediumId))
     }
   },[mediumId,inputValues.classId])
// fetch allSections and allsubject api,hook End

// add data of allSections api into constant,hook start
   useMemo(()=>{
     if(sections && sections.all_sections && sections.all_sections.result && sections.all_sections.success === true){
       setSectionsInfo(sections.all_sections.result)
     }
   },[sections])
// add data of allSections api into constant,hook End

// add data of allsubject api into constant,hook start
   useMemo(()=>{
     if(subjects && subjects.all_subjects && subjects.all_subjects.result && subjects.all_subjects.success === true ){
       setSubjectsInfo(subjects.all_subjects.result)
     }
   },[subjects])
// add data of allsubject api into constant,hook End

// add data of teachers api into constant,hook start
   useMemo(()=>{
     if(teachers && teachers.all_teachers && teachers.all_teachers.result && teachers.all_teachers.success === true){
       setTeacherResult(teachers.all_teachers.result)
     }
   },[teachers])
// add data of teachers api into constant,hook End

// when teacherResult change add data into teachersInfo,hook start
   useMemo(()=>{
     if(teacherResult && teacherResult.data){
           setTeachersInfo(teacherResult.data)
     }
   },[teacherResult])
// when teacherResult change add data into teachersInfo,hook End

// add data of addExam api into constant,hook start
   useMemo(()=>{
     if(addExam_data && addExam_data.add_timing && addExam_data.add_timing.error){
       setError(addExam_data.add_timing.error)
     }
     else if(addExam_data && addExam_data.add_timing && addExam_data.add_timing.success){
        setSuccessStatus(addExam_data.add_timing.success)
     }
   },[addExam_data])
// add data of addExam api into constant,hook End

   //hooks end

// component function start
// classHandler function start
   const classHandler = (event) =>{

     const classInfo = event.target.value
     console.log("classInfo",event.target.value)
     if(classInfo !=""){
       setInputValues({...inputValues,classId:classInfo})
       allClassesInfo.filter(classid =>classid.id ==
          classInfo).map((item,index) =>{
         setMediumId(item.ClassMediumId)
       })

     }
     else{
       setInputValues({...inputValues,classId:classInfo})
     }

   }
// classHandler function End

// examHandler function start
   const examHandler = (event) =>{
   event.preventDefault()
   const exam_info = inputValues;
     dispatch(addTiming(exam_info))
      dispatch(fetchAllRestTimings())
 }
// examHandler function End

// resetHandler function start
   const resetHandler = (event) =>{
     event.preventDefault()
     window.location.reload(false);
   }
// resetHandler function start


// resetHandler function start
   const handleTimeChange = (value) =>{

    const selectedTime = value && value.format('HH:mm') ? value.format('HH:mm') : "00:00";
    setInputValues({...inputValues,openingTime:selectedTime})
      console.log('selectedTime', selectedTime);
   }

   const handleClosingTimeChange = (value) =>{

    const selectedTime = value && value.format('HH:mm') ? value.format('HH:mm') : "00:00";
    setInputValues({...inputValues,closingTime:selectedTime})
    //  console.log('selectedTime', selectedTime);
   }
// resetHandler function start

// component function end

        return (
            <div className="col-4-xxxl col-12">
                <div className="card height-auto">
                {addExam_data && addExam_data.add_timing_loading === false ? successStatus === false || successStatus === null  ? (
                  <div className="card-body">
                    <div className="heading-layout1 bg-darkblue">
                      <div className="item-title">
                        <h3 className='text-light'>Add Timing</h3>
                        {addExam_data.add_timing && addExam_data.add_timing.request_status && addExam_data.add_timing.request_status === true ? (<div className="success-greeting1">
                        <h2 className="addtimesuccess">Timing Added Successfully!</h2>
                        </div>): null}
                        {addExam_data.add_timing && addExam_data.add_timing.object && addExam_data.add_timing.object && addExam_data.add_timing.object.error ? (<div className="success-greeting1">

                        <h2 className="addtimeerror">{addExam_data.add_timing.object.error}</h2>
                        </div>): null}
                      </div>

                    </div>
                    <form className="new-added-form" onSubmit={(e) =>examHandler(e)}>
                      <div className="row">
                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                          <label>Timing Name *</label>
                          <input type="text" placeholder className="form-control" value={inputValues.timingName} onChange={(e) =>setInputValues({...inputValues,timingName:e.target.value})} required/>
                          {error != null && error.timingName ? (<h6 className="addStudent-error">*{JSON.stringify(error.timingName).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>
                        <div className="col-6-xxxl col-lg-6 col-12 form-group">
                        <FormGroup>
                            <FormLabel>Opening Day *</FormLabel>
                            <FormControl
                              required
                              type="text"
                              onChange={(e) =>setInputValues({...inputValues,openingDay:e.target.value})}
                              as="select"
                            >
                            <option value="">Select Opening Day</option>
                            <option value="MONDAY">MONDAY</option>
                            <option value="TUESDAY">TUESDAY</option>
                            <option value="WEDNESDAY">WEDNESDAY</option>
                            <option value="THURSDAY">THURSDAY</option>
                            <option value="FRIDAY">FRIDAY</option>
                            <option value="SATURDAY">SATURDAY</option>
                            <option value="SUNDAY">SUNDAY</option>
                            </FormControl>
                            {error != null && error.openingDay ? (<h6 className="addStudent-error">*{JSON.stringify(error.openingDay).replace(/[\[\]"]+/g,"")}</h6>):null}
                          </FormGroup>
                        </div>
                        <div className="col-6-xxxl col-lg-6 col-12 form-group">
                        <FormGroup>
                            <FormLabel>Closing Day *</FormLabel>
                            <FormControl
                              required
                              type="text"
                              onChange={(e) =>setInputValues({...inputValues,closingDay:e.target.value})}
                              as="select"
                            >
                            <option value="">Select Closing Day</option>
                            <option value="MONDAY">MONDAY</option>
                            <option value="TUESDAY">TUESDAY</option>
                            <option value="WEDNESDAY">WEDNESDAY</option>
                            <option value="THURSDAY">THURSDAY</option>
                            <option value="FRIDAY">FRIDAY</option>
                            <option value="SATURDAY">SATURDAY</option>
                            <option value="SUNDAY">SUNDAY</option>
                            </FormControl>
                            {error != null && error.closingDay ? (<h6 className="addStudent-error">*{JSON.stringify(error.closingDay).replace(/[\[\]"]+/g,"")}</h6>):null}
                          </FormGroup>
                        </div>
                        <div className="col-6-xxxl col-lg-6 col-12 form-group">
                          <label>Opening Time *</label>
                          <TimePicker
                                 showSecond={false}
                                 defaultValue={now}
                                 className="form-control timeselector"
                                 onChange={handleTimeChange}
                                 format={format}
                                 use12Hours
                                 inputReadOnly
                               />
                          {error != null && error.openingTime ? (<h6 className="addStudent-error">*{JSON.stringify(error.openingTime).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>
                        <div className="col-6-xxxl col-lg-6 col-12 form-group">
                          <label>Closing Time *</label>
                          <TimePicker
                                 showSecond={false}
                                 defaultValue={now}
                                 className="form-control timeselector"
                                 onChange={handleClosingTimeChange}
                                 format={format}
                                 use12Hours
                                 inputReadOnly
                               />
                          {error != null && error.closingTime ? (<h6 className="addStudent-error">*{JSON.stringify(error.closingTime).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>
                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                        <FormGroup>
                            <FormLabel>Timing Type *</FormLabel>
                            <FormControl
                              required
                              type="text"
                              onChange={(e) =>setInputValues({...inputValues,timingType:e.target.value})}
                              as="select"
                            >
                            <option value="">Select Timing Type</option>
                            <option value="OPEN">OPEN</option>
                            <option value="CLOSED">CLOSED</option>

                            </FormControl>
                            {error != null && error.timingType ? (<h6 className="addStudent-error">*{JSON.stringify(error.timingType).replace(/[\[\]"]+/g,"")}</h6>):null}
                          </FormGroup>
                        </div>
                        <div className="col-12 form-group mg-t-8" style={{paddingTop:"20px"}}>
                          <button type="submit" style={{marginLeft:"10px"}} className="btn-fill-lg bg-blue-dark btn-hover-yellow right-float">Save</button>
                          <button type="reset" className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark right-float" onClick={(e) =>resetHandler(e)}>Reset</button>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : (<div className="card-body">
                      <div className="success-greeting">
                      <h2>Thank You!</h2>
                      <h2>Timing Added Successfully!</h2>
                      </div>
                  </div>):(<div className="card-body">
                      <div className="Student-Loader">
                      <Loader
                      className = "student-detail-loader"
                    type="MutatingDots"
                    color="#fea801"
                    height={150}
                    width={150}

       />
                      </div>
                    </div>)}
                </div>
              </div>
        );
}

export default AddTiming;
