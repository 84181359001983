import React, {useState,useEffect,useRef,useMemo} from 'react';
import Socialmedia from './Socialmedia';
//import Notification from './Notification';
import Holidays from './Holidays';
import Breadcubs from './Breadcubs';
import { useSelector,useDispatch } from 'react-redux';
import { fetchAllBook, acceptOrder, declineOrder, fetchOrderDetail, fetchAllDetails } from '../Redux/AllBook/AllBookActions';
import { restShut, restShutUpdate } from '../Redux/RestShut/RestShutActions';
import { wakeUpHours } from '../Redux/WakeUpSettings/WakeUpSettingsActions';

import { registerDevice } from '../Redux/Login/LoginActions';

import DatePicker from "react-datepicker";
import moment from 'moment';
import Loader from 'react-loader-spinner';
import { useHistory, useLocation } from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import fees from '../Assets/Images/fees.png';
import system from '../Assets/Images/system.png';
import discount from '../Assets/Images/discount.png';
import delivery from '../Assets/Images/delivery.png';
import gift from '../Assets/Images/gift.png';
import tax from '../Assets/Images/tax.png';
import tips from '../Assets/Images/tips.png';
import payment from '../Assets/Images/payment.png';
import toast from 'react-hot-toast';
import { Button } from 'react-bootstrap';

function Admindashboard(){

const [restOnOff,setRestOnOff] = useState(false)
//const [deliveryOnOff,setDeliveryOnOff] = useState(false)
const allBookData = useSelector(state =>state.AllBook)
const restStateData = useSelector(state =>state.RestShutStatus)

const loginData = useSelector(state =>state.Login)
const wakeUpData = useSelector(state =>state.WakeUpSettings)

// store data access End
const dispatch = useDispatch()  // for accessing the redux function
const history = useHistory();
const [allBookResult,setBookResult] = useState([])
const [allDetailResult,setDetailResult] = useState('')
const [startDate, setStartDate] = useState();
const [endDate, setEndDate] = useState();
const [error,setError] = useState(null)
const [hour,setHour] = useState(0)
const [st,setSt] = useState(0)
const [pageNumber, setPageNumber] = useState(0)
const [data,setData] = useState([])
const [currentorderTab,setCurrentorderTab] = useState(2)
const [responsMessage, setResponsMessage] = useState('')
const [errorMessage, setErrorMessage] = useState('')
const [curTime, setCurTime] = useState('')
const listInnerRef = useRef();
//const [updateTime, setUpdateTime] = useState('')

// fetch allBook  api hook start

const { state } = useLocation();


useEffect(() =>{
  toast("Printer Not Connected!")
  setSt(0);
  dispatch(fetchAllBook(0, false))
  dispatch(fetchAllDetails(0, false, {
    dateFilter: {
      startDate: moment(startDate).format('DD-MM-YYYY'),
      endDate: moment(endDate).format('DD-MM-YYYY'),
    },
    filter: currentorderTab
  }))
  if (state?.order && !isNaN(state.order)) {
    // only dispatch the action if the condition is true
    dispatch(fetchOrderDetail(state.order))
    }
  dispatch(restShut())
 // dispatch(deliveryShut())
  if(localStorage.getItem('device_token') && localStorage.getItem('new_token') && localStorage.getItem('new_token') == 'true') {
     dispatch(registerDevice(localStorage.getItem('device_token')))
  }
  componentDidMount();
},[dispatch,state?.order])


// fetch allBook api hook End

const componentDidMount = () => {
  setInterval(() => {
    const time = new Date().toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    setCurTime(time)  
    setSt(1);
  }, 1000)
}





// add data of allFees api into constant,hook start
useMemo(() =>{
  if(allBookData && allBookData.all_book && allBookData.all_book && !allBookData.all_book_loading){
    setError('');
    if(allBookData.is_added){
      allBookData.all_book.forEach((item) => {
        if(allBookResult.indexOf(item) == -1){
          setBookResult(allBookResult.concat(allBookData.all_book));
        }
      });
    } else if(!allBookData.is_added) {
      setBookResult(allBookData.all_book)
      //setData(allBookData.all_book)
    }
  }
},[allBookData])

useMemo(() => {
  if (allBookData && allBookData.all_detail && !allBookData.all_book_loading) {
  setError("");
  if (allBookData.is_added) {
  // Use Array.from to create an array from allBookData.all_detail
  Array.from(allBookData.all_detail).forEach((item) => {
  if (allDetailResult.indexOf(item) == -1) {
  setDetailResult(allBookResult.concat(allBookData.all_detail));
  }
  });
  } else if (!allBookData.is_added) {
  setDetailResult(allBookData.all_detail);
  //setData(allBookData.all_book)
  }
  }
  }, [allBookData]);


const onScroll = () => {
  if (listInnerRef.current) {
    const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
 
    if (Math.ceil(scrollTop + clientHeight) == scrollHeight) {

      if(startDate && endDate){
        dispatch(fetchAllBook(pageNumber+1, true, {
           dateFilter: {
             startDate: moment(startDate).format('DD-MM-YYYY'),
             endDate: moment(endDate).format('DD-MM-YYYY'),
           },
           filter: currentorderTab
        }))
      } else {
        dispatch(fetchAllBook(pageNumber+1, true, {
          filter: currentorderTab
        }))
      }
      setPageNumber(pageNumber+1);
      // This will be triggered after hitting the last element.
      // API call should be made here while implementing pagination.
    }
  }
};

// add data of allFees api into constant,hook End

useMemo(() => {
  if(loginData && loginData.device) {
    if(loginData.device.object && loginData.device.object.pushEndpoint) {
      localStorage.setItem('new_token', 'false');
    }
  }
}, [loginData])

// add data of allFees api into constant,hook start
useMemo(() =>{
  if(restStateData && restStateData.rest_shut && restStateData.rest_shut.success){
    setRestOnOff(restStateData.rest_shut.status)
  }
},[restStateData])
// useMemo(() =>{
//   if(restStateData && restStateData.rest_post_shut && restStateData.rest_post_shut.success){
//     setRestOnOff(restStateData.rest_post_shut.status)
//   }
// },[restStateData])

useMemo(() =>{
  if(wakeUpData && wakeUpData.wake_up) {
    if(wakeUpData.wake_up.success=="true") {
      setRestOnOff(false)
      dispatch(restShut())
      setErrorMessage('')
      setResponsMessage('Restaurant is closed now for '+hour+' hour(s)! Open At: '+wakeUpData.wake_up.times)
    } else if(wakeUpData.wake_up.object && wakeUpData.wake_up.object.error) {
      setResponsMessage('')
      setErrorMessage(wakeUpData.wake_up.object.error)
    }
  }
},[wakeUpData])
// add data of allFees api into constant,hook End


const handlefilterSelect = (e) => {
    setPageNumber(0);
    if(e === 'all') {
      setCurrentorderTab(0);
    }
    else {
      setCurrentorderTab(e);
    }
    if(startDate && endDate) {
      if(startDate > endDate || startDate > new Date() || endDate > new Date()) setError('invalid Date')
      else if((endDate - startDate) / (1000 * 3600 * 24) > 31) setError('Orders can not be more than 31 days!')
      else dispatch(fetchAllBook(0, false, {
        dateFilter: {
          startDate: moment(startDate).format('DD-MM-YYYY'),
          endDate: moment(endDate).format('DD-MM-YYYY'),
        },
        filter: e
      }))
    } else {
       dispatch(fetchAllBook(0, false, {filter:e}))
    }
}


const handleDateChange = (value) =>{
  setStartDate(value)
}

const handleEndDateChange = (value) =>{
  setEndDate(value)
}

const handleOrderSelect = (orderNo) => {
  history.push({
    pathname: "/order_detail",
    state: {order: orderNo, isNotify: false}
  })
}

const filterOrders = () =>{
  if(startDate && endDate){
   setPageNumber(0); 
   if(startDate > endDate || startDate > new Date() || endDate > new Date()) setError('invalid Date')
   else if((endDate - startDate) / (1000 * 3600 * 24) > 31) setError('Orders can not be more than 31 days!')
   else 
   dispatch(fetchAllBook(0, false, {
    dateFilter: {
      startDate: moment(startDate).format('DD-MM-YYYY'),
      endDate: moment(endDate).format('DD-MM-YYYY'),
    },
    filter: currentorderTab
  }))

  }

}

const filterDetail = () =>{
  if(startDate && endDate){
   setPageNumber(0); 
   if(startDate > endDate || startDate > new Date() || endDate > new Date()) setError('invalid Date')
   else if((endDate - startDate) / (1000 * 3600 * 24) > 31) setError('Orders can not be more than 31 days!')
   else 
   dispatch(fetchAllDetails(0, false, {
    dateFilter: {
      startDate: moment(startDate).format('DD-MM-YYYY'),
      endDate: moment(endDate).format('DD-MM-YYYY'),
    },
    filter: currentorderTab
  }))

  }

}

function viewSales(){
  history.push("/viewsalesanalytics");
}

const clearOrders = () =>{
  setStartDate();
  setEndDate();
  dispatch(fetchAllBook(0, false, {filter:currentorderTab}))
}





return (
    <>
     <Breadcubs />

    


               <div className="row gutters-20">
        <div className="col-12 col-xl-12 col-8-xxxl">
          <div className="card dashboard-card-one pd-b-20">
          <div className="card-body">
             
          <div className="row">
                    <div className="col-3-xxxl col-lg-4 col-12 form-group">
                         <label>Start Date</label>
                        <DatePicker selected={startDate} className="form-control" onChange={(date) => handleDateChange(date)} />
                          {error != null && error.from_date ? (<h6 className="addStudent-error">*{JSON.stringify(error.from_date).replace(/[\[\]"]+/g,"")}</h6>):null}
                    </div>
                    <div className="col-3-xxxl col-lg-4 col-12 form-group">
                          <label>End Date</label>
                        <DatePicker selected={endDate} className="form-control" onChange={(date) => handleEndDateChange(date)} />
                          {error != null && error.to_date ? (<h6 className="addStudent-error">*{JSON.stringify(error.to_date).replace(/[\[\]"]+/g,"")}</h6>):null}
                    </div>
                    <div className="col-6-xxxl col-lg-4 col-12 form-group mg-t-35">
                       {/* <button type="button" className="btn-fill-lg bg-blue-dark btn-hover-yellow" onClick={(e) =>filterOrders()}>Apply Filter</button>              */}
                       <button type="button" className="btn-fill-lg bg-blue-dark btn-hover-yellow" onClick={() => { filterOrders(); filterDetail();}}>Apply Filter</button> 
                       <button type="reset" className="btn-fill-lg bg-blue-dark btn-hover-yellow mg-l-10" onClick={(e) =>clearOrders()}>Clear</button>
                    </div>
               </div>
            
            </div>
          </div>
        </div>

      



      </div>

      {/* Dashboard summery start Here  */}

<div className="card-body">
<div className='row'>
    <div class="col-xl-3 col-sm-6 col-12">
        <div class="card">
          <div class="card-content">
            <div class="card-body">
              <div class="media d-flex">
             
                <div class="align-self-center">
                  <i class="flaticon-couple text-orange font-large-2 float-left"></i>
                </div>
                <div class="media-body text-right" style={{fontWeight:"bold"}}>
                <div className="item-number">Total Orders</div>

                {allDetailResult.object ? (
allDetailResult.object.error ? (
<div className="item-number">0</div>
) : (
<div className="item-number">{allDetailResult.object.total_orders}</div>
)
) : (
<div className="item-number">0</div>
)}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-sm-6 col-12">
        <div class="card">
          <div class="card-content">
            <div class="card-body">
              <div class="media d-flex">
             
                <div class="align-self-center">
                <img src={payment} width={40}/> 
                </div>
                <div class="media-body text-right" style={{fontWeight:"bold"}}>
                <div className="item-number">Total Payment</div>
 
                {allDetailResult.object ? (
allDetailResult.object.error ? (
<div className="item-number">0</div>
) : (<div className="item-number">{allDetailResult.object.total}</div>)
) : (
<div className="item-number">0</div>
)}
 

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-sm-6 col-12">
        <div class="card">
          <div class="card-content">
            <div class="card-body">
              <div class="media d-flex">
             
                <div class="align-self-center">
                <img src={tax} width={40}/>  
                  {/* <i class="flaticon-couple text-orange font-large-2 float-left"></i> */}
                </div>
                <div class="media-body text-right" style={{fontWeight:"bold"}}>
                <div className="item-number">Total Tax</div>
  
                  
                {allDetailResult.object ? (
allDetailResult.object.error ? (
<div className="item-number">0</div>
) :(<div className="item-number">{allDetailResult.object.tax}</div>)
) : (
<div className="item-number">0</div>
)}


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-sm-6 col-12">
      <button type="button" style={{margin:"10% 20% 0% 20%"}} className="btn-fill-lg bg-blue-dark btn-hover-yellow" onClick={(e) =>viewSales()}>View Sales Analytics</button>   
        
      </div>
{/* <div className="col-xl-3 col-sm-12 col-xs-12 col-lg-3 col-md-12">
          <div className="dashboard-summery-one mg-b-20">
            <div className="row align-items-center">
              <div className="col-6">
                <div className="item-icon bg-light-yellow">
                  <i className="flaticon-couple text-orange" />
                </div>
              </div>
              <div className="col-6">
                <div className="item-content">
                  <div className="item-number">Total Orders</div>

                  {allDetailResult.object ? (<div className="item-number">{allDetailResult.object.total_orders}</div>) : (<div className="item-number">0</div>)}

                  
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="col-xl-3 col-sm-4 col-xs-12 col-lg-3 col-md-12">
          <div className="dashboard-summery-one mg-b-20">
            <div className="row align-items-center">
              <div className="col-6">
              <div className="item-icon bg-light-yellow">
                <img src={fees} width={40}/>
                
                </div>
              </div>
              <div className="col-6">
                <div className="item-content">
                  <div className="item-number">PG Fees</div>
  
                  

                  {allDetailResult.object ? (<div className="item-number">{allDetailResult.object.pg_fees}</div>) : (<div className="item-number">0</div>)}

                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="col-xl-3 col-sm-4 col-xs-12 col-lg-3 col-md-12">
          <div className="dashboard-summery-one mg-b-20">
            <div className="row align-items-center">
              <div className="col-6">
                <div className="item-icon bg-light-yellow">
                <img src={system} width={40}/>
                
                </div>
              </div>
              <div className="col-6">
                <div className="item-content">
                  <div className="item-number">System Fees</div>
  
                  

                  {allDetailResult.object ? (<div className="item-number">{allDetailResult.object.system_fees}</div>) : (<div className="item-number">0</div>)}

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-sm-4 col-xs-12 col-lg-3 col-md-12">
          <div className="dashboard-summery-one mg-b-20">
            <div className="row align-items-center">
              <div className="col-6">
                <div className="item-icon bg-light-yellow">
                <img src={discount} width={40}/>
                
                </div>
              </div>
              <div className="col-6">
                <div className="item-content">
                  <div className="item-number">Discounts</div>
  
                  

                  {allDetailResult.object ? (<div className="item-number">{allDetailResult.object.discounts}</div>) : (<div className="item-number">0</div>)}

                </div>
              </div>
            </div>
          </div>
        </div>
  
          <div className="col-xl-3 col-sm-4 col-xs-12 col-lg-3 col-md-12">
          <div className="dashboard-summery-one mg-b-20">
            <div className="row align-items-center">
              <div className="col-6">
                <div className="item-icon bg-light-yellow">
                <img src={delivery} width={40}/>
                
                </div>
              </div>
              <div className="col-6">
                <div className="item-content">
                  <div className="item-number">Delivery Fees</div>
  
                  

                  {allDetailResult.object ? (<div className="item-number">{allDetailResult.object.delivery_fees}</div>) : (<div className="item-number">0</div>)}

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-sm-4 col-xs-12 col-lg-3  col-md-12">
          <div className="dashboard-summery-one mg-b-20">
            <div className="row align-items-center">
              <div className="col-6">
                <div className="item-icon bg-light-yellow">
                <img src={gift} width={40}/>
                
                </div>
              </div>
              <div className="col-6">
                <div className="item-content">
                  <div className="item-number">Gift Payments</div>
  
                  

                  {allDetailResult.object ? (<div className="item-number">{allDetailResult.object.gift_payments}</div>) : (<div className="item-number">0</div>)}

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-sm-4 col-xs-12 col-lg-3 col-md-12">
          <div className="dashboard-summery-one mg-b-20">
            <div className="row align-items-center">
              <div className="col-6">
                <div className="item-icon bg-light-yellow">
                <img src={tax} width={40}/>  
                </div>
              </div>
              <div className="col-6">
                <div className="item-content">
                  <div className="item-number">Tax</div>
  
                  

                  {allDetailResult.object ? (<div className="item-number">{allDetailResult.object.tax}</div>) : (<div className="item-number">0</div>)}

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-sm-4 col-xs-12 col-lg-3 col-md-12">
          <div className="dashboard-summery-one mg-b-20">
            <div className="row align-items-center">
              <div className="col-6">
                <div className="item-icon bg-light-yellow">
                <img src={tips} width={40}/>
                </div>
              </div>
              <div className="col-6">
                <div className="item-content">
                  <div className="item-number">Tip</div>
  
                  

                  {allDetailResult.object ? (<div className="item-number">{allDetailResult.object.tip}</div>) : (<div className="item-number">0</div>)}

                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="col-xl-3 col-sm-4 col-xs-12 col-lg-3 col-md-12">
          <div className="dashboard-summery-one mg-b-20">
            <div className="row align-items-center">
              <div className="col-6">
                <div className="item-icon bg-light-yellow">
                <img src={payment} width={40}/>
                </div>
              </div>
              <div className="col-6">
                <div className="item-content">
                  <div className="item-number">Total Payment</div>
  
                  

                  {allDetailResult.object ? (<div className="item-number">{allDetailResult.object.total}</div>) : (<div className="item-number">0</div>)}

                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="col-xl-3 col-sm-4 col-xs-12 col-lg-3 col-md-12">
          <div className="dashboard-summery-one mg-b-20">
            <div className="row align-items-center">
              <div className="col-6">
                <div className="item-icon bg-light-yellow">
                <img src={tax} width={40}/>  
                </div>
              </div>
              <div className="col-6">
                <div className="item-content">
                  <div className="item-number">Total Tax</div>
  
                  

                  {allDetailResult.object ? (<div className="item-number">{allDetailResult.object.tax}</div>) : (<div className="item-number">0</div>)}

                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="col-xl-3 col-sm-4 col-xs-12 col-lg-3 col-md-12">
        <button type="button" style={{margin:"10% 20% 0% 20%"}} className="btn-fill-lg bg-blue-dark btn-hover-yellow" onClick={(e) =>viewSales()}>View Sales Analytics</button>   
        </div> */}
    
</div>
</div>

<br/>
    

    

        {/* Dashboard Content Start Here */}
      <div className="row gutters-20">
        <div className="col-12 col-xl-12 col-8-xxxl">
          <div className="card dashboard-card-one pd-b-20">
          <div className="card-body">
              <div className="heading-layout1">
                <div className="item-title">
                  <h3>Recent Orders</h3>
                </div>
                <div className="dropdown">
                  <a className="dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false"></a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a className="dropdown-item" href="#"><i className="fas fa-times text-orange-red" />Close</a>
                    <a className="dropdown-item" href="#"><i className="fas fa-cogs text-dark-pastel-green" />Edit</a>
                    <a className="dropdown-item" href="#"><i className="fas fa-redo-alt text-orange-peel" />Refresh</a>
                  </div>
                </div>
              </div>

              {allBookData.all_book_error ? (<span style={{color:'red'}}>{allBookData.all_book_error.error}</span>): (<></>)}
              {error ? (<span style={{color:'red'}}>{error}</span>): (<></>)}

              {/* <div className="row">
                    <div className="col-3-xxxl col-lg-4 col-12 form-group">
                         <label>Start Date</label>
                        <DatePicker selected={startDate} className="form-control" onChange={(date) => handleDateChange(date)} />
                          {error != null && error.from_date ? (<h6 className="addStudent-error">*{JSON.stringify(error.from_date).replace(/[\[\]"]+/g,"")}</h6>):null}
                    </div>
                    <div className="col-3-xxxl col-lg-4 col-12 form-group">
                          <label>End Date</label>
                        <DatePicker selected={endDate} className="form-control" onChange={(date) => handleEndDateChange(date)} />
                          {error != null && error.to_date ? (<h6 className="addStudent-error">*{JSON.stringify(error.to_date).replace(/[\[\]"]+/g,"")}</h6>):null}
                    </div>
                    <div className="col-6-xxxl col-lg-4 col-12 form-group mg-t-35">
                       <button type="button" className="btn-fill-lg bg-blue-dark btn-hover-yellow" onClick={(e) =>filterOrders()}>Apply Filter</button>             
                       <button type="reset" className="btn-fill-lg bg-blue-dark btn-hover-yellow mg-l-10" onClick={(e) =>clearOrders()}>Clear</button>
                    </div>
               </div> */}

              <div className="earning-report1">
              <div className="card">
                  <div className="card-body">

                    <div className="table-box-wrap">

                        <div className="row gutters-8">


                          <div className="col-lg-8 form-group filterbtn">
                          <button type="submit" onClick={e=>handlefilterSelect(2)} className={currentorderTab === 2 ? "btn btn-outline-primary active" : "btn btn-outline-primary"}>New</button>
                            <button type="submit" onClick={e=>handlefilterSelect(4)} className={currentorderTab === 4 ? "btn btn-outline-primary active" : "btn btn-outline-primary"}>Completed</button>
                            <button type="submit" onClick={e=>handlefilterSelect(17)} className={currentorderTab === 17 ? "btn btn-outline-primary active" : "btn btn-outline-primary"}>Cancelled</button>
                          </div>
                          <div className="col-lg-4 form-group">

                          </div>
                        </div>


                        {allBookData && allBookData.all_book_loading === true ?
                          (
                          <div style={{marginLeft:'40%'}}>
                          <Loader
                          className = "student-detail-loader"
                        type="ThreeDots"
                        color="#fea801"
                        height={80}
                        width={150}

                        /></div>
                        ) : <></>}
                        <div className="table-responsive expenses-table-box">
                       <div onScroll={onScroll} ref={listInnerRef} className="table-height">
                      <table className="table data-table text-nowrap">
                       
                            <tr>
                              <th>
                                Order ID
                              </th>
                              <th>Order Total</th>
                              <th>Order Status</th>
                              <th>Name</th>
                              <th>E-Mail</th>
                              <th>Order Date</th>

                            </tr>
                        
                          
                          <tbody>
                          {allBookResult && allBookResult.length > 0 && allBookResult.map((item,index)=>{
                            return (
                              <tr>
                                <td>
                                <a className="orderclick" onClick={e=>handleOrderSelect(item.order_id)}>{item.order_id}</a>
                                </td>

                                <td>${item.order_total}</td>
                                {item.order_status === 'Processing' ? <td className="badge badge-pill badge-primary d-block mg-t-8">New</td> : null}
                                {item.order_status === 'Order AHead' ? <td className="badge badge-pill badge-primary d-block mg-t-8 futureorder">Order Ahead</td> : null}
                                {item.order_status === 'Complete' ? <td className="badge badge-pill badge-success d-block mg-t-8">Completed</td> : null}
                                {item.order_status === 'Cancelled' ? <td className="badge badge-pill badge-danger d-block mg-t-8">Cancelled</td> : null}
                                {item.order_status === 'Pending Payment' ? <td className="badge badge-pill badge-secondary d-block mg-t-8">Pending Payment</td> : null}
                                {item.order_status !== 'Order AHead' && item.order_status !== 'Processing' && item.order_status !== 'Complete' && item.order_status && 'Cancelled' && item.order_status !== 'Pending Payment' ? 'N/A' : null}
                                <td>{item.billing_details.first_name} {item.billing_details.last_name}</td>
                                <td>{item.billing_details.email}</td>
                                <td>{item.order_date}</td>
                                <td className="hidden">
                                  <div className="dropdown">
                                    <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                      <span className="flaticon-more-button-of-three-dots" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right" style={{display:'none'}}>
                                      <a className="dropdown-item" href="#"><i className="fas fa-times text-orange-red" />Close</a>
                                      <a className="dropdown-item" href="#"><i className="fas fa-cogs text-dark-pastel-green" />Edit</a>
                                      <a className="dropdown-item" href="#"><i className="fas fa-redo-alt text-orange-peel" />Refresh</a>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })}


                          </tbody>
                        </table></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="col-lg-6 col-xl-6 col-4-xxxl">
        <div className="card dashboard-card-six pd-b-20 homeholidays">
       
       <br/>
       <Holidays />
       </div>
       </div>



      </div>
      {/* Dashboard Content End Here */}
      <Socialmedia />
      </>

        );
}

export default Admindashboard;
