import {
  ON_DEMAND_DELIVERY_REQUEST,
  ON_DEMAND_DELIVERY_SUCCESS,
  ON_DEMAND_DELIVERY_FALIURE,
  DOORDASH_DELIVERY_REQUEST,
  DOORDASH_DELIVERY_SUCCESS,
  DOORDASH_DELIVERY_FALIURE,
  AUTO_ACCEPT_DELIVERY_REQUEST,
  AUTO_ACCEPT_DELIVERY_SUCCESS,
  AUTO_ACCEPT_DELIVERY_FALIURE,
  ON_DEMAND_DELIVERY_UPDATE_SUCCESS,
  DOORDASH_DELIVERY_UPDATE_SUCCESS,
  AUTO_ACCEPT_DELIVERY_UPDATE_SUCCESS,
  ON_DEMAND_DELIVERY_UPDATE_FALIURE,
  DOORDASH_DELIVERY_UPDATE_FALIURE,
  AUTO_ACCEPT_DELIVERY_UPDATE_FALIURE,
  ON_DEMAND_DELIVERY_UPDATE_REQUEST,
  DOORDASH_DELIVERY_UPDATE_REQUEST,
  AUTO_ACCEPT_DELIVERY_UPDATE_REQUEST
} from './DeliverySettingsConstants'

const intialstate = {
  on_demand_delivery_loading : false,
  on_demand_delivery : [],
  on_demand_delivery_error : '',
  on_demand_delivery_success : false,
  doordash_delivery_loading : false,
  doordash_delivery : [],
  doordash_delivery_error : '',
  doordash_delivery_success : false,
  auto_accept_delivery_loading : false,
  auto_accept_delivery : [],
  auto_accept_delivery_error : '',
  auto_accept_delivery_success : false,
  on_demand_delivery_update_loading : false,
  on_demand_delivery_update : [],
  on_demand_delivery_update_success : false,
  on_demand_delivery_update_error : false,
  doordash_delivery_update_loading : false,
  doordash_delivery_update : [],
  doordash_delivery_update_success : false,
  doordash_delivery_update_error : false,
  auto_accept_delivery_update_loading : false,
  auto_accept_delivery_update : [],
  auto_accept_delivery_update_success : false,
  auto_accept_delivery_update_error : false
}

const DeliverySettingsReducers = (state = intialstate,action) =>{
  switch(action.type){
    case ON_DEMAND_DELIVERY_REQUEST : return{
      ...state,
      on_demand_delivery_loading:true
    }
    case ON_DEMAND_DELIVERY_SUCCESS : return{
      ...state,
      on_demand_delivery_loading:false,
      on_demand_delivery_success:true,
      on_demand_delivery:action.payload
    }
    case ON_DEMAND_DELIVERY_FALIURE : return{
      ...state,
      on_demand_delivery_error:action.payload
    }

    case DOORDASH_DELIVERY_REQUEST : return{
      ...state,
      doordash_delivery_loading:true
    }
    case DOORDASH_DELIVERY_SUCCESS : return{
      ...state,
      doordash_delivery_loading:false,
      doordash_delivery_success:true,
      doordash_delivery:action.payload
    }
    
    case DOORDASH_DELIVERY_FALIURE : return{
      ...state,
      doordash_delivery_error:action.payload
    }
    case AUTO_ACCEPT_DELIVERY_REQUEST : return{
      ...state,
      auto_accept_delivery_loading:true
    }
    case AUTO_ACCEPT_DELIVERY_SUCCESS : return{
      ...state,
      auto_accept_delivery_loading:false,
      auto_accept_delivery_success:true,
      auto_accept_delivery:action.payload
    }
    
    case AUTO_ACCEPT_DELIVERY_FALIURE : return{
      ...state,
      auto_accept_delivery_error:action.payload
    }

    case ON_DEMAND_DELIVERY_UPDATE_REQUEST : return{
      ...state,
      on_demand_delivery_update_loading:true,
      on_demand_delivery_update:action.payload
    }
    case ON_DEMAND_DELIVERY_UPDATE_SUCCESS : return{
      ...state,
      on_demand_delivery_update_loading:false,
      on_demand_delivery_update_success:true,
      on_demand_delivery_update:action.payload
    }
    case ON_DEMAND_DELIVERY_UPDATE_FALIURE : return{
      ...state,
      on_demand_delivery_update_error:action.payload
    }

    case DOORDASH_DELIVERY_UPDATE_REQUEST : return{
      ...state,
      doordash_delivery_update_loading:true,
      doordash_delivery_update:action.payload
    }
    case DOORDASH_DELIVERY_UPDATE_SUCCESS : return{
      ...state,
      doordash_delivery_update_loading:false,
      doordash_delivery_update_success:true,
      doordash_delivery_update:action.payload
    }
    case DOORDASH_DELIVERY_UPDATE_FALIURE : return{
      ...state,
      doordash_delivery_update_error:action.payload
    }

    case AUTO_ACCEPT_DELIVERY_UPDATE_REQUEST : return{
      ...state,
      auto_accept_delivery_update_loading:true,
      auto_accept_delivery_update:action.payload
    }
    case AUTO_ACCEPT_DELIVERY_UPDATE_SUCCESS : return{
      ...state,
      auto_accept_delivery_update_loading:false,
      auto_accept_delivery_update_success:true,
      auto_accept_delivery_update:action.payload
    }
    case AUTO_ACCEPT_DELIVERY_UPDATE_FALIURE : return{
      ...state,
      auto_accept_delivery_update_error:action.payload
    }
    default: return state
  }

}

export default DeliverySettingsReducers
