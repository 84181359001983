import {
  REST_SHUT_REQUEST,
  REST_SHUT_SUCCESS,
  REST_SHUT_FALIURE,
  REST_POST_SHUT_REQUEST,
  REST_POST_SHUT_SUCCESS,
  REST_POST_SHUT_FALIURE,
  REST_POST_SHUT2_REQUEST
} from './RestShutConstants'

const intialstate = {
  rest_shut_loading : false,
  rest_shut : [],
  rest_post_shut : [],
  rest_shut_error : '',
  rest_shut_success : false,
  rest_post_shut_error : '',
  rest_post_shut_success : false
}

const RestShutReducers = (state = intialstate,action) =>{
  switch(action.type){
    case REST_SHUT_REQUEST : return{
      ...state,
      rest_shut_loading:true
    }
    case REST_SHUT_SUCCESS : return{
      ...state,
      rest_shut_loading:false,
      rest_shut_success:true,
      rest_shut:action.payload
    }
    case REST_SHUT_FALIURE : return{
      ...state,
      rest_shut_error:action.payload
    }
    case REST_POST_SHUT_REQUEST : return{
      ...state,
      rest_shut_loading:true
    }
    case REST_POST_SHUT_SUCCESS : return{
      ...state,
      rest_shut_loading:false,
      rest_post_shut_success:true,
      rest_post_shut:action.payload
    }
    case REST_POST_SHUT_FALIURE : return{
      ...state,
      rest_post_shut_error:action.payload
    }
    case REST_POST_SHUT2_REQUEST : return{
      ...state,
      rest_post_shut:action.payload
    }
    default: return state
  }

}

export default RestShutReducers
