import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';

import { broadCastMessage } from '../Redux/BroadCastMessage/AddMessageActions';


function BroadCast() {
  const [restSettingsData,setRestSettingsData] = useState({
    
    title: "",
    message: "",
   
  })

  const [inputValuesdefault,setInputValuesdefault] = useState({
    title: "",
    message: "",
  })

const dispatch = useDispatch();
const [show,setShow] = useState(true);
  const messageHandler = (event) =>{
    event.preventDefault()
      dispatch(broadCastMessage(restSettingsData))
      setInterval(() => {
        setShow(false); 
    }, 2000); 
    setRestSettingsData(inputValuesdefault);
  }



  const [responseMessage, setResponseMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const BroadCast = useSelector(state => state.BroadCastMessage)
 
  useMemo(() => {
 
      if (BroadCast.add_message.request_status === true) {
         setResponseMessage("BroadCast Message Sent Successfully!")
      }
  
  }, [BroadCast])


  useMemo(() => {
 
    if (BroadCast.add_message.request_status === false) {
       setErrorMessage(BroadCast.add_message.object.error)
    }
 
}, [BroadCast])

 

  return (
    <>
      <div className="breadcrumbs-area">
        <h3>BroadCast Messages</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>BroadCast Messages</li>
        </ul>
      </div>
      {/* Breadcubs Area Start Here */}


      <div className="card-body mx-width-600">

        <div className="heading-layout1">
          <div className="item-title">

          </div>

        </div>

     
    

        <div className="pad-5 bg-darkblue height-50">
          <ul className="nav nav-tabs justify-content-center" role="tablist">
            <li>
              <a href="#" className='nav-link mg-r-10 active show' aria-controls="billing" role="link" aria-expanded="true" aria-disabled="true">BroadCast</a>
            </li>
          </ul>
        </div>
        
        {responseMessage && show ?
      
      <p className="alert alert-success w-auto" role="alert" style={{marginTop:"1%"}}>
      {responseMessage}
      </p>: <p></p>
    }

    {errorMessage ?
      <p className="alert alert-danger w-auto" role="alert">
        {errorMessage}
      </p> : <p></p>
    }
       
        <div className="tab-content single-info-details mx-width-600">
          <div role="tabpanel" className='tab-pane active show' id="billing">
         
          
                           
          <form onSubmit={(e) =>messageHandler(e)}>  
            <div className="row">
        
              <div className="col-12-xxxl col-lg-6 col-12 form-group mg-0">

            
                <label>Title</label>
                <input type="text" className="form-control" placeholder='Enter Title' value={restSettingsData.title} onChange={(e) =>setRestSettingsData({...restSettingsData,title:e.target.value})} required />
                </div>
             
              <div className="col-12-xxxl col-lg-6 col-12 form-group mg-0">
              <label htmlFor="exampleFormControlTextarea1">Example textarea</label>
    <textarea className="form-control" id="exampleFormControlTextarea1" rows="12" style={{height:"20vh"}} value={restSettingsData.message} onChange={(e) =>setRestSettingsData({...restSettingsData,message:e.target.value})} required></textarea>
                 </div>
            </div>
            
            <div className='float-right mg-t-30'>

               <button type="submit" className="btn-fill-lg bg-blue-dark btn-hover-yellow">Send</button>
            </div>
            </form>
          </div>       
         

        </div>
       



      </div>

    </>
  );
};

export default BroadCast;
