import {
  ADD_CONNECTION_SUCCESS,
  ADD_CONNECTION_FALIURE
} from './AddConnectionConstants'

const intialstate = {
  add_connection : null,
  add_connection_error : '',
  add_connection_success : false
}

const AddConnectionReducers = (state = intialstate,action) =>{
  
  switch(action.type){
    case ADD_CONNECTION_SUCCESS : return{
      ...state,
      add_connection_success:true,
      add_connection:action.payload
    }
    case ADD_CONNECTION_FALIURE : return{
      ...state,
      add_connection_error:action.payload
    }
    default: return state
  }
}

export default AddConnectionReducers
