import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import 'toolcool-range-slider/dist/plugins/tcrs-generated-labels.min.js';
import 'toolcool-range-slider/dist/plugins/tcrs-marks.min.js';
import 'toolcool-range-slider';
import { addConnection } from '../Redux/AddConnection/AddConnectionActions';
import Switch from "react-switch";

function PrinterSetting() {


    const dispatch = useDispatch();

    const [printerIPAddress, setPrinterIPAddress] = useState("192.168.1.46");
    const [printerPort, setPrinterPort] = useState("8043");
    const [connectionStatus, setConnectionStatus] = useState("");
    const [loading, setLoading] = useState("false");
    const [autoConnectStatus, setAutoConnectStatus] = useState("Disable");
    const [autoConnect, setAutoConnect] = useState(false);
    const [numberOfCopy, setNumberOfCopy] = useState("1");
    const ePosDevice = useRef();
    const printer = useRef();

    
    useEffect(() => {
      
      if(localStorage.getItem("ipAddress")) {
         setPrinterIPAddress(localStorage.getItem("ipAddress"));        
      }

      if(localStorage.getItem("port")) {
         setPrinterPort(localStorage.getItem("port"));
      }

      if(null != localStorage.getItem("auto_print") || localStorage.getItem("auto_print") != 'undefined') {
        setAutoConnect(localStorage.getItem("auto_print"));
        if(localStorage.getItem("auto_print") == 'true') {
           setAutoConnectStatus('Enable');
        } else if(localStorage.getItem("auto_print") == 'false') {
           setAutoConnectStatus('Disable');
        }
      }
      if(!localStorage.getItem("number_of_copies")) {
        localStorage.setItem("number_of_copies", "1");
      }
      setNumberOfCopy(localStorage.getItem("number_of_copies"));
      const $slider = document.getElementById('r_slider');
      $slider.addEventListener('change', (evt) => {
        if(numberOfCopy != evt.detail.value) {
           saveCopies(evt.detail.value)
        }
      });
    }, []);
  
    const STATUS_CONNECTED = "Connected";
  
    const connect = () => {
      if (!printerIPAddress) {
        setConnectionStatus("Type the printer IP address");
        return;
      }
      if (!printerPort) {
        setConnectionStatus("Type the printer port");
        return;
      } 
      setConnectionStatus("");
      setLoading('true')
      let ePosDev = new window.epson.ePOSDevice();
      ePosDevice.current = ePosDev;
  
      ePosDev.connect(printerIPAddress, printerPort, (data) => {
        if (data == "OK" || data == "SSL_CONNECT_OK") {
          ePosDev.createDevice(
            "local_printer",
            ePosDev.DEVICE_TYPE_PRINTER,
            { crypto: false, buffer: false },
            (devobj, retcode) => {
              if (retcode === "OK") {
                localStorage.setItem("ipAddress", printerIPAddress);
                localStorage.setItem("port", printerPort);
                printer.current = devobj;
                dispatch(addConnection(printer.current));
                setConnectionStatus(STATUS_CONNECTED);
                setLoading('false')
              } else {
                setConnectionStatus(retcode);
                setLoading('false')
                throw retcode;
              }
            }
          );
        } else {
          setConnectionStatus(data);
          setLoading('false')
          throw data;
        }
      });
    };

    const autoConnectCall = (enable) => {
       localStorage.setItem("auto_print", !enable);
       setAutoConnect(!enable);
       if(!enable){
          setAutoConnectStatus('Enable');
       } else {
          setAutoConnectStatus('Disable');
       }
    }

    const saveCopies = (num) => {
      localStorage.setItem("number_of_copies", num);
    }

    return (        
      <>
      <div className="breadcrumbs-area">
        <h3>Printer Settings</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Printer Settings</li>
        </ul>
      </div>
        
        <div className="row dashboard-card-six mx-width-600">

           <div className="col-12-xxxl col-12">
           <div className="pad-5 bg-darkblue height-42"></div>
                <div className="card height-auto">
                <div className="card-body">
                
                  
                {connectionStatus == STATUS_CONNECTED ?
                  <p className="alert alert-success w-auto" role="alert">
                    {connectionStatus}
                 </p> : (connectionStatus ?
                  <p className="alert alert-danger w-auto" role="alert">
                      {connectionStatus}
                  </p> : <p></p>)
                }              


                      <div className="row">
                        <div className="form-group width-100">
                          <table className="width-100">
                            <tr>
                              <td className="cl-center">
                                 <label>Printer IP Address *</label>
                                 
                              </td>
                              <td className="cl-center">
                                 <input id="printerIPAddress" type="text" className="form-control form-length" size="16" value={printerIPAddress} onChange={(e) => setPrinterIPAddress(e.currentTarget.value)} required/>
                                
                              </td>
                              {!connectionStatus && loading == 'true' ?
                              <td className="cl-center text-green">
                                 <i className="fa fa-spinner fa-spin"></i>
                              </td> : <td></td>}
                              <td className="cl-center">
                                 <button type="button" className="btn-info btn-light btn-lg"
                                   disabled={connectionStatus === STATUS_CONNECTED}
                                   onClick={() => connect()}>
                                   <i className='fas fa-paper-plane'> Connect</i></button>
                              </td>
                            </tr>
                            
                          </table>                         
                                           
                        </div> 

                        <div className="center-300 pad-10 cl-center">
                               Number of Copies
                        </div>
                        
                        <div className="center-300 pad-10 mrg-bottom-10">
                           <tc-range-slider id="r_slider" min="1" max="3" value={numberOfCopy}
                               slider-width="400px"
                               slider-height="0.5rem"
                               pointer-width="1.5rem"
                               pointer-height="1.5rem"
                               pointer-bg="#6AD3BA"
                               pointer-bg-hover="#50BDA3"
                               pointer-shadow="none"
                               pointer-shadow-hover="none"
                               theme="rect"
                               pointer-border="0"
                               data="1,2,3"
                             
                               generate-labels="true"
                               origin-at-center="true"                 
                               pointer-border-hover="1px solid #3F8A8A"
                               pointer-border-focus="1px solid #3F8A8A"
                               ></tc-range-slider>
                        </div>                 

                        <div className="col-12-xxxl col-12 form-group">
                        <label className="width-100 cl-center">Current Auto Print Status is {autoConnectStatus}</label><br/>
                          <div className="width-100 cl-center">

                          <Switch onChange={(e)=>autoConnectCall(autoConnect)} checked={localStorage.getItem("auto_print") == 'true'} uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"/>

                          </div>   
                        </div>

                       

                      </div>
                  
               </div>
               </div>
         </div>

          </div>
      </>
    );   

   
};

export default PrinterSetting;