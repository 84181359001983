import {
  DOWNLOAD_REPORT_REQUEST,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_FALIURE
} from './DownloadReportConstants'

const intialstate = {
  download_report_loading : false,
  download_report : [],
  download_report_error : '',
  download_report_success : false
}

const DownloadReportReducers = (state = intialstate,action) =>{
  switch(action.type){
    case DOWNLOAD_REPORT_REQUEST : return{
      ...state,
      download_report_loading:true
    }
    case DOWNLOAD_REPORT_SUCCESS : return{
      ...state,
      download_report_loading:false,
      download_report_success:true,
      download_report:action.payload
    }
    case DOWNLOAD_REPORT_FALIURE : return{
      ...state,
      download_report_error:action.payload
    }
    default: return state
  }

}

export default DownloadReportReducers
