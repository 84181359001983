import {
  ADD_TIMING_REQUEST,
  ADD_TIMING_SUCCESS,
  ADD_TIMING_FALIURE,
  ADD_GROUP_REQUEST,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FALIURE
} from './AddTimingConstants'
import { fetchAllAddons } from '../AllBook/AllBookActions';
import { fetchAllRestTimings } from '../AllRestTimings/AllRestTimingsActions';
import { fetchSingleAddons } from '../AllBook/AllBookActions';

import {config} from '../config'
import toast from 'react-hot-toast';
import { useState } from 'react';

const currentPage = 0;
const totalPages = 0;

export const addTimingRequest = (timing_info) =>{
  return{
    type : ADD_TIMING_REQUEST,
    payload : timing_info
  }
}

export const addTimingSuccess = (timing_success) =>{
  return{
    type : ADD_TIMING_SUCCESS,
    payload : timing_success
  }
}

export const addTimingFaliure = (error) =>{
  return{
    type : ADD_TIMING_FALIURE,
    payload : error
  }
}

export const addGroupRequest = (add_group) =>{
  return{
    type : ADD_GROUP_REQUEST,
    payload: add_group
  }
}

export const addGroupSuccess = (add_group_success) =>{
  return{
    type : ADD_GROUP_SUCCESS,
    payload : add_group_success
  }
}

export const addGroupFaliure = (error) =>{
  return{
    type : ADD_GROUP_FALIURE,
    payload : error
  }
}

export const addTiming = (timing_info) =>{
    return(dispatch) => {
      dispatch(addTimingRequest(timing_info))
      const url =`${config.api_root}/api/v1/merchants/shop/timing`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      body: JSON.stringify({
        user_id : config.user_id,
        fields:{
           name: timing_info.timingName,
           from_day: timing_info.openingDay,
           to_day: timing_info.closingDay,
           from_time: timing_info.openingTime,
           to_time: timing_info.closingTime,
           time_type: timing_info.timingType,
        },
      })
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(add_timing_res =>{
      const add_timing_success = add_timing_res
      dispatch(addTimingSuccess(add_timing_success))
      dispatch(fetchAllRestTimings())
    })
    .catch(error => {
      const errorMsg = error
      dispatch(addTimingFaliure(errorMsg))
    })
}
}

export const addAddon = (timing_info) =>{
    return(dispatch) => {
      dispatch(addGroupRequest(timing_info))
      const url =`${config.api_root}/api/v1/merchants/group/add_on`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      body: JSON.stringify({
        user_id : config.user_id,
        fields:{
        name: timing_info.name,
        description:timing_info.description,
        type:timing_info.type,
        nameKey:timing_info.nameKey,
        isRequired:timing_info.isRequired,
        requires: timing_info.requires,
        isGlobal:timing_info.isGlobal,
        isSystem:timing_info.isSystem
        },
      })
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(add_timing_res =>{
      const add_timing_success = add_timing_res
      dispatch(addGroupSuccess(add_timing_success))
      dispatch(fetchAllAddons(totalPages,currentPage))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(addGroupFaliure(errorMsg))
    })
}
}



export const addSingleAddon = (addongroupid, timing_info) =>{
    return(dispatch) => {
      dispatch(addTimingRequest(timing_info))
      const url =`${config.api_root}/api/v1/merchants/group/add_on/${addongroupid}`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      body: JSON.stringify({
        user_id : config.user_id,
        fields:{
           name: timing_info.name,
           nameKey: timing_info.nameKey,
           unitPrice: timing_info.unitPrice,
           taxable: timing_info.taxable,
           quantitative: timing_info.quantitative
        },
      })
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(add_timing_res =>{
      // const add_timing_success = add_timing_res

     
      // dispatch(addTimingSuccess(add_timing_success))
      toast.success("Addon Added Successfully!")
     dispatch(fetchSingleAddons(addongroupid))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(addTimingFaliure(errorMsg))
    })
}
}
