import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { punchTicket } from '../Redux/ETicketRedeem/ETicketRedeemActions'
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Link, useParams } from 'react-router-dom';

function ETicketRedeem() {

  const eTicketRedeemData = useSelector(state => state.ETicketRedeem)
  // store data access End
  const dispatch = useDispatch()  // for accessing the redux function
  let { id } = useParams();
  const [responsMessage, setResponsMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [error, setError] = useState({
    ticket_number: '',
    pin: ''
  })
  const [inputValues, setInputValues] = useState({
    ticket_number: "",
    pin: ""
  })

  // add data of allFees api into constant,hook start

  useMemo(() => {
    if (eTicketRedeemData && eTicketRedeemData.eticket_redeem && eTicketRedeemData.eticket_redeem.object) {      
      if (eTicketRedeemData.eticket_redeem.request_status) {
         setErrorMessage('')
         setResponsMessage(eTicketRedeemData.eticket_redeem.object.message)
      } else if (eTicketRedeemData.eticket_redeem.object.error) {
         setResponsMessage('')
         setErrorMessage(eTicketRedeemData.eticket_redeem.object.error)
      }
    }
   
  }, [eTicketRedeemData])

  

  useMemo(() => {
    if (error) {
      setError(error);
    }
  }, [error])


  const eTicketValidate = (isAmount) => {
    let error = {
      ticket_number: "",
      pin: ""
    }
    let isError = false;
    if (!inputValues.ticket_number) {
      error.ticket_number = 'please enter ticket number!';
      isError = true;
    }
    if (inputValues.ticket_number && inputValues.ticket_number.length != 16) {
      error.ticket_number = 'invalid ticket number!';
      isError = true;
    }
    if (!inputValues.pin) {
      error.pin = 'please enter pin!';
      isError = true;
    }
    if (inputValues.pin && (inputValues.pin.length < 4 || inputValues.pin.length > 6)) {
      error.pin = 'invalid pin!';
      isError = true;
    }
    setError(error);
    return isError;
  }

  const eTicketPunch = () => {
    setResponsMessage('')
    setErrorMessage('')
    if (!eTicketValidate(true)) {
      dispatch(punchTicket(inputValues, id))
    }
  }


 


  return (
    <>
      <div className="breadcrumbs-area">
        <h3>ETicket Redeem</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>ETicket Redeem</li>
        </ul>
      </div>
      {/* Breadcubs Area Start Here */}


      <div className="card-body mx-width-600">

        <div className="heading-layout1">
          <div className="item-title">

          </div>

        </div>

        {responsMessage ?
          <p className="alert alert-success w-auto" role="alert">
            {responsMessage}
          </p> : <p></p>
        }

        {errorMessage ?
          <p className="alert alert-danger w-auto" role="alert">
            {errorMessage}
          </p> : <p></p>
        }


        <div className="pad-5 bg-dark height-50">
          <ul className="nav nav-tabs justify-content-center" role="tablist">
            <li>
              <a href="#" className='nav-link mg-r-10 active show' aria-controls="billing" role="link" aria-expanded="true" aria-disabled="true">Punch E-Ticket</a>
            </li>
          </ul>
        </div>
        <div className="tab-content single-info-details mx-width-600">
          <div role="tabpanel" className='tab-pane active show' id="billing">
          {!eTicketRedeemData.eticket_redeem_loaded ?
               (<div style={{textAlign: 'center'}}>
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={90}
                    width={150}
                  />
                </div>) : (<span></span>) }          
            <div className="row">
              <div className="col-12-xxxl col-lg-6 col-12 form-group mg-0">
                <label>Ticket Number</label>
                <input type="number" className="form-control" value={inputValues.ticket_number} onChange={(e) => setInputValues({ ...inputValues, ticket_number: e.target.value })} required />
                {error.ticket_number ? (<span className="addStudent-error float-right">*{error.ticket_number}</span>) : ''}
              </div>
              <div className="col-12-xxxl col-lg-6 col-12 form-group mg-0">
                <label>Pin</label>
                <input type="number" className="form-control" value={inputValues.pin} onChange={(e) => setInputValues({ ...inputValues, pin: e.target.value })} required />
                {error.pin ? (<span className="addStudent-error float-right">*{error.pin}</span>) : ''}
              </div>
            </div>
            <div className='float-right mg-t-30'>
               <button type="button" onClick={(e) => eTicketPunch()} className='btn-fill-lg gradient-pastel-green btn-hover-bluedark'>Punch Ticket</button>
            </div>
          </div>       


        </div>



      </div>

    </>
  );
};

export default ETicketRedeem;
