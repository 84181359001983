import {
  PUBLISH_DATA_REQUEST,
  PUBLISH_DATA_SUCCESS,
  PUBLISH_DATA_FALIURE
} from './PublishDataConstants'
import {config} from '../config'
import toast from 'react-hot-toast'

export const publishDataRequest = (publish_data_info) =>{
  return{
    type : PUBLISH_DATA_REQUEST,
    payload : publish_data_info
  }
}

export const publishDataSuccess = (publish_data_success) =>{
  return{
    type : PUBLISH_DATA_SUCCESS,
    payload : publish_data_success
  }
}

export const publishDataFaliure = (error) =>{
  return{
    type : PUBLISH_DATA_FALIURE,
    payload : error
  }
}

export const publishData = (publish_data_info) =>{
    return(dispatch) => {
      dispatch(publishDataRequest(publish_data_info))
      const url =`${config.api_root}/api/v1/merchants/products/publish?user_id=${config.user_id}`;
      const request_option = {
      method: "PATCH",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(publish_data_res =>{
      const publish_data_success = publish_data_res
      dispatch(publishDataSuccess(publish_data_success))
      toast.success("Changes published successfully!")
    })
    .catch(error => {
      const errorMsg = error
      dispatch(publishDataFaliure(errorMsg))
    })
    }
}

export const publishDataUpdate = (publish_data_info) =>{
    return(dispatch) => {
      dispatch(publishDataRequest(publish_data_info))
      const url =`${config.api_root}/api/v1/merchants/products/publish?user_id=${config.user_id}`;
      const request_option = {
      method: "PATCH",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(publish_data_res =>{
      const publish_data_success = publish_data_res
      dispatch(publishDataSuccess(publish_data_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(publishDataFaliure(errorMsg))
    })
    }
}

export const testingAuto = (publish_data_info) =>{
  return(dispatch) => {
    
    const url =`${config.api_root}/api/v1/merchants/test_auto_run?user_id=${config.user_id}`;
    const request_option = {
    method: "PATCH",
    headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
        }
  }
  fetch(url, request_option)
  .then(response => response.json())
  .then(publish_data_res =>{
    const publish_data_success = publish_data_res
    if(publish_data_res.request_status === false){
      dispatch(publishDataFaliure(publish_data_res.object.error))
      toast.error(publish_data_res.object.error)
    }else{
      toast.success("Test Order is in Progressing...")
    }
    // console.log(dispatch(publishDataSuccess(publish_data_success)));
    dispatch(publishDataSuccess(publish_data_success));
    // toast.success("Test Order is in Progressing...");
  })
  .catch(error => {
    const errorMsg = error
    dispatch(publishDataFaliure(errorMsg))
    toast.error("Something went wrong. Please try again later.");
  })
  }
}



