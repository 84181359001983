import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import Loader from 'react-loader-spinner';
import { onDemandDelivery, doordashDelivery, autoAcceptDelivery, onDemandDeliveryUpdate, doordashDeliveryUpdate, autoAcceptDeliveryUpdate } from '../Redux/DeliverySettings/DeliverySettingsActions';
import { deliveryShut, deliveryShutUpdate } from '../Redux/DeliveryShut/DeliveryShutActions';
import Switch from "react-switch";


function DeliverySettings() {

    const dispatch = useDispatch();
    const [deliveryOnOff,setDeliveryOnOff] = useState(false)
    const deliverySettings = useSelector(state => state.DeliverySettings)
    const deliveryStateData = useSelector(state =>state.DeliveryShutStatus)
    const [onDemandStatus, setOnDemandStatus] = useState();
    const [doordashStatus, setDoordashStatus] = useState();
    const [autoAcceptStatus, setAutoAcceptStatus] = useState();
    const [isSave, setSave] = useState(false);
    const [isDoordashSave, setDoordashSave] = useState(false);
    const [isAutoAcceptSave, setAutoAcceptSave] = useState(false);
    const [isDoordashEnable, setDoordashEnable] = useState(false);
    const [isAutoAcceptEnable, setAutoAcceptEnable] = useState(false);
    const [isFetched, setFetched] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {   
      setSave(false);
      setLoading(true);
      setFetched(false);
      dispatch(deliveryShut())
      dispatch(onDemandDelivery());
      dispatch(doordashDelivery());
      dispatch(autoAcceptDelivery());
    }, [dispatch])

    const onDemandDeliveryEdit = (status) => {
      setSave(true)
      setFetched(true)
      setLoading(true)
      localStorage.setItem("success_status", status);
      dispatch(onDemandDeliveryUpdate(status));
    }

    const setdeliveryStatus = (deliverystatus) =>{
      //setDeliveryOnOff(!deliverystatus)
        dispatch(deliveryShutUpdate(!deliverystatus))
    }

    const doordashDeliveryEdit = (status) => {
      setDoordashSave(true)
      setLoading(true)
      setFetched(true)
      localStorage.setItem("success_status", status);
      dispatch(doordashDeliveryUpdate(status));
    }

    const autoAcceptDeliveryEdit = (status) => {
      setAutoAcceptSave(true)
      setLoading(true)
      setFetched(true)
      localStorage.setItem("success_status", status);
      dispatch(autoAcceptDeliveryUpdate(status));
    }

    useMemo(() => {
      if (deliverySettings && deliverySettings.on_demand_delivery && deliverySettings.doordash_delivery.success && !isFetched) { 
        if(deliverySettings.on_demand_delivery.status == true) {
          setDoordashEnable(false)
          setAutoAcceptEnable(false)
          setOnDemandStatus(deliverySettings.on_demand_delivery.status);
        } else if(deliverySettings.on_demand_delivery.status == false) {
          setDoordashEnable(true)
          setAutoAcceptEnable(true)
          setOnDemandStatus(deliverySettings.on_demand_delivery.status);
        }
      }
    }, [deliverySettings])

    useMemo(() => {
      if (deliverySettings && deliverySettings.doordash_delivery && deliverySettings.doordash_delivery.success && !isFetched) {  
        setDoordashStatus(deliverySettings.doordash_delivery.status);  
        if(deliverySettings.doordash_delivery.status == false) {
          setAutoAcceptEnable(true)
        } else {
          setAutoAcceptEnable(false)
        }    
      }
    }, [deliverySettings])

    useMemo(() => {
      if (deliverySettings && deliverySettings.auto_accept_delivery && deliverySettings.auto_accept_delivery.success && !isFetched) {        
        setAutoAcceptStatus(deliverySettings.auto_accept_delivery.status);
        setLoading(false)
      }
    }, [deliverySettings])

    useMemo(() =>{
      if(deliveryStateData && deliveryStateData.delivery_shut && deliveryStateData.delivery_shut.success){
        setDeliveryOnOff(deliveryStateData.delivery_shut.status)
      }
    },[deliveryStateData])

    useMemo(() => {
      if (deliverySettings.on_demand_delivery_update && deliverySettings.on_demand_delivery_update.on_demand_success && isSave) {    
      setOnDemandStatus(localStorage.getItem("success_status") == 'true');
        if(localStorage.getItem("success_status") == 'false') {
          setDoordashEnable(true)
          setAutoAcceptEnable(true)
          doordashDeliveryEdit(false);
        } else {
          setDoordashEnable(false)
          setAutoAcceptEnable(false)
          setLoading(false)
        }
        setSave(false)
      }
    }, [deliverySettings])

    useMemo(() => {
      if (deliverySettings.doordash_delivery_update && deliverySettings.doordash_delivery_update.doordash_success && isDoordashSave) {    
        setDoordashStatus(localStorage.getItem("success_status") == 'true');
        if(localStorage.getItem("success_status") == 'false') {
          setAutoAcceptEnable(true)    
          autoAcceptDeliveryEdit(false); 
        } else {
          setAutoAcceptEnable(false)
          setLoading(false)
        }
        setDoordashSave(false)
      }
    }, [deliverySettings])

    useMemo(() => {
      if (deliverySettings.auto_accept_delivery_update && deliverySettings.auto_accept_delivery_update.auto_success && isAutoAcceptSave) {      
        setAutoAcceptStatus(localStorage.getItem("success_status") == 'true');
        setLoading(false)
        setAutoAcceptSave(false)
      }      
    }, [deliverySettings])

    return (        
      <>
      <div className="breadcrumbs-area">
        <h3>Delivery Settings</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Delivery Settings</li>
        </ul>
      </div>
        <div style={{margin:'0 10%'}}>
        <div className="dashboard-card-six mx-width-700-no">

           <div className="col-12-xxxl col-12 cl-center">
           <div className="pad-5 bg-darkblue height-42"></div>
                <div className="card height-auto">

                {loading && loading === true ?
                (
                <div className="card-body" style={{marginBottom:'20px'}}>
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={150}
                    width={150}
                  />
                </div>
              ) : (<>
                  <div className="card-body" style={{marginBottom: '30px'}}>
                    <div className="row">
                        <div className="col-lg-6">
                          <label>Delivery By Restaurant Staff</label>
                          {/* Self Delivery Status*/}
                        </div>

                        <div className="col-lg-6">
                         <Switch onChange={e=>setdeliveryStatus(deliveryOnOff)} checked={deliveryOnOff} uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"/>
                        </div>
                      </div>
                  </div>

                  <div className="pad-5 bg-darkblue height-42"></div>
                  <div className="card-body">

                      <div className="row">
                        <div className="col-lg-6">
                          <label>Delivery Companies</label>
                        </div>

                        <div className="col-lg-6">
                           <Switch onChange={(e)=>onDemandDeliveryEdit(!onDemandStatus)} checked={onDemandStatus} uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"/>
                        </div>
                      </div>  

                      <div className="row">
                        <div className="col-lg-6">
                          <label>Doordash Delivery</label>
                        </div>

                        <div className="col-lg-6">
                           <Switch onChange={(e)=>doordashDeliveryEdit(!doordashStatus)} checked={doordashStatus} disabled={isDoordashEnable} uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"/>
                        </div>
                      </div> 

                      <div className="row">
                        <div className="col-lg-6">
                          <label>Auto Accept Delivery</label>
                        </div>

                        <div className="col-lg-6">
                           <Switch onChange={(e)=>autoAcceptDeliveryEdit(!autoAcceptStatus)} checked={autoAcceptStatus} disabled={isAutoAcceptEnable} uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"/>
                        </div>
                      </div> 

                  </div></>)}
                </div>
           </div>          


          </div></div>
      </>
    );   

   
};

export default DeliverySettings;