import React, { useEffect, useMemo,useRef,useState } from 'react';
import { loginCheck } from '../Redux/LoginCheck/LoginCheckActions';
import {useDispatch,useSelector } from 'react-redux'
import { publishData, testingAuto } from '../Redux/PublishData/PublishDataActions';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { addConnection } from '../Redux/AddConnection/AddConnectionActions';
import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { config } from '../Redux/config';

function Header({parentCallback}){
  // store data access start
const specificUserDetailData = useSelector(state =>state.SpecificUserDetail)
// store data access End
  const dispatch = useDispatch()
  const [active,setActivestate] = useState(false)
  const [roleName,setRoleName] = useState("")
  const [publishedData, setPublishedData] = useState(false);
  const [publishdataLoading, setPublishdataLoading] = useState(false);

  useMemo(() =>{
    if(specificUserDetailData &&
      specificUserDetailData.specific_user_detail &&
      Object.keys(specificUserDetailData.specific_user_detail).length > 0 &&
      specificUserDetailData.specific_user_detail.success === true &&
      specificUserDetailData.specific_user_detail.result &&
      Object.keys(specificUserDetailData.specific_user_detail.result).length > 0 &&
      specificUserDetailData.specific_user_detail.result.Role
    ){
      if(specificUserDetailData.specific_user_detail.result.Role === 1){
        setRoleName("Admin")
      }
      else if(specificUserDetailData.specific_user_detail.result.Role === 2){
        setRoleName("Teacher")
      }
      else if(specificUserDetailData.specific_user_detail.result.Role === 3){
        setRoleName("Student")
      }
      else if(specificUserDetailData.specific_user_detail.result.Role === 4){
        setRoleName("Parent")
      }
      else if(specificUserDetailData.specific_user_detail.result.Role === 5){
        setRoleName("Clerk")
      }
      else if(specificUserDetailData.specific_user_detail.result.Role === 6){
        setRoleName("Librarian")
      }
      else if(specificUserDetailData.specific_user_detail.result.Role === 7){
        setRoleName("Bus Driver")
      }
    }

  },[specificUserDetailData])


  const toggleClass =() =>{
    setActivestate(!active)
  }

  const sendData = (value1) => {
    parentCallback(value1);
}

useMemo(() =>{
  sendData(active)
},[active])

const logout = () =>{
  localStorage.removeItem('user');
  localStorage.removeItem('access_token');
  localStorage.removeItem('mid');
  Cookies.set('RS_ACCESS_TOKEN', null, { domain: '.diningtek.com', expires: 0 });
  Cookies.set('USER', null, { domain: '.diningtek.com', expires: 0 });
  Cookies.remove('RS_ACCESS_TOKEN');
  Cookies.remove('USER');
  const login_check_info = false
  dispatch(loginCheck(login_check_info))

  if (localStorage.getItem('user') === null && localStorage.getItem('access_token') === null){
    window.location.reload();

  }
}

const publishDataClick = (item) => {
  setPublishdataLoading(true);
  setTimeout( () => {
    dispatch(publishData());
    setPublishedData(true);
    setPublishdataLoading(false);
}, 2000);

};

// for printing


const [printerIPAddress, setPrinterIPAddress] = useState("192.168.1.46");
const [printerPort, setPrinterPort] = useState("8043");
const [connectionStatus, setConnectionStatus] = useState("");
const [loading, setLoading] = useState("false");
const [autoConnectStatus, setAutoConnectStatus] = useState("Disable");
const [autoConnect, setAutoConnect] = useState(false);
const [numberOfCopy, setNumberOfCopy] = useState("1");
const ePosDevice = useRef();
const printer = useRef();
const [color,setColor] = useState(false);

useEffect(() => {
  
  if(localStorage.getItem("ipAddress")) {
     setPrinterIPAddress(localStorage.getItem("ipAddress"));        
  }

  if(localStorage.getItem("port")) {
     setPrinterPort(localStorage.getItem("port"));
  }

  if(null != localStorage.getItem("auto_print") || localStorage.getItem("auto_print") != 'undefined') {
    setAutoConnect(localStorage.getItem("auto_print"));
    if(localStorage.getItem("auto_print") == 'true') {
       setAutoConnectStatus('Enable');
       setColor(true);
    } else if(localStorage.getItem("auto_print") == 'false') {
       setAutoConnectStatus('Disable');
       setColor(false);
    }
  }
  if(!localStorage.getItem("number_of_copies")) {
    localStorage.setItem("number_of_copies", "1");
  }
  setNumberOfCopy(localStorage.getItem("number_of_copies"));
  const $slider = document.getElementById('r_slider');
  
}, []);

const STATUS_CONNECTED = "Connected";

const connect = () => {
  if (!printerIPAddress) {
    setConnectionStatus("Type the printer IP address");
    return;
  }
  if (!printerPort) {
    setConnectionStatus("Type the printer port");
    return;
  } 
  setConnectionStatus("");
  setLoading('true')
  let ePosDev = new window.epson.ePOSDevice();
  ePosDevice.current = ePosDev;

  ePosDev.connect(printerIPAddress, printerPort, (data) => {
    if (data == "OK" || data == "SSL_CONNECT_OK") {
      ePosDev.createDevice(
        "local_printer",
        ePosDev.DEVICE_TYPE_PRINTER,
        { crypto: false, buffer: false },
        (devobj, retcode) => {
          if (retcode === "OK") {
            localStorage.setItem("ipAddress", printerIPAddress);
            localStorage.setItem("port", printerPort);
            printer.current = devobj;
            dispatch(addConnection(printer.current));
            setConnectionStatus(STATUS_CONNECTED);
            setColor(true);
            setLoading('false')
          } else {
            setConnectionStatus(retcode);
            setColor(false);
            setLoading('false')
            throw retcode;
          }
        }
      );
    } else {
      setConnectionStatus(data);
      setLoading('false')
      throw data;
    }
  });
};

const autoConnectCall = (enable) => {
   localStorage.setItem("auto_print", !enable);
   setAutoConnect(!enable);
   if(!enable){
      setAutoConnectStatus('Enable');
   } else {
      setAutoConnectStatus('Disable');
   }
}

const saveCopies = (num) => {
  localStorage.setItem("number_of_copies", num);
}

// end of printing

// for auto testing
 function testAuto(){
  // toast("Test Order is in Progressing...")
dispatch(testingAuto());
 }

// end of auto testing


  return(
    <div className="navbar navbar-expand-md header-menu-one bg-light">
    <div className="nav-bar-header-one">
      <div className="header-logo">
        <a href="/">
          <img src="img/logo.png" alt="logo" />
        </a>
      </div>
      <div className="toggle-button sidebar-toggle">
        <button type="button" className="item-link" onClick={() =>toggleClass()}>
          <span className="btn-icon-wrap">
            <span />
            <span />
            <span />
          </span>
        </button>
      </div>
    </div>
    <div className="d-md-none mobile-nav-bar">
      <button className="navbar-toggler pulse-animation" type="button" data-toggle="collapse" data-target="#mobile-navbar" aria-expanded="false">
        <i className="far fa-arrow-alt-circle-down" />
      </button>
      <button type="button" className="navbar-toggler sidebar-toggle-mobile">
        <i className="fas fa-bars" />
      </button>
    </div>
    <div className="header-main-menu collapse navbar-collapse" id="mobile-navbar">
      <ul className="navbar-nav">
        <li className="navbar-item header-search-bar">
          <div className="input-group stylish-input-group">
            <span className="betalist">Beta Vesion 1.0</span>
          </div>
        </li>
      </ul>
      
      {/* {publishedData ? (<div className="col-6 publishchange success"><span>Changes published successfully!</span></div>) :null} */}
      <ul className="navbar-nav">
      <li className="navbar-item publishData">
      {!connectionStatus && loading == 'true' ?
                              <td className="cl-center text-green">
                                 <i className="fa fa-spinner fa-spin"></i>
                              </td> : <td></td>}
                             <Button size='lg' style={{marginRight:"100%"}} onClick={testAuto} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Run Order Test</Button>
                            
                              </li>

      <li className="navbar-item publishData">
      {!connectionStatus && loading == 'true' ?
                              <td className="cl-center text-green">
                                 <i className="fa fa-spinner fa-spin"></i>
                              </td> : <td></td>}
                              {color?(<>  <td className="cl-center">
                                 <button type="button" className="btn-info btn-light btn-lg" style={{border:"none"}}
                                   disabled={connectionStatus === STATUS_CONNECTED}
                                   onClick={() => connect()}>
                                   <i className='fa fa-print' style={{color:"green"}}></i></button>
                              </td></>):(<>  <td className="cl-center">
                                 <button type="button" className="btn-info btn-light btn-lg"
                                   disabled={connectionStatus === STATUS_CONNECTED}
                                   onClick={() => connect()}>
                                   <i className='fa fa-print' style={{color:"red"}}></i></button>
                              </td></>)}
                            
                              </li>
      <li className="navbar-item publishData">

      {publishdataLoading && publishdataLoading === true ? (<i className="fas fa-spinner fa-spin"></i>):(<i onClick={(e) => publishDataClick(e)} className="fas fa-upload"></i>)}
       <span className="tooltipText tw">Publish Data</span>
      </li>
     
        <li className="navbar-item dropdown header-admin">
          <a className="navbar-nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
          {specificUserDetailData &&
             specificUserDetailData.specific_user_detail &&
              Object.keys(specificUserDetailData.specific_user_detail).length > 0 &&
              specificUserDetailData.specific_user_detail.success === true &&
              specificUserDetailData.specific_user_detail.result &&
              Object.keys(specificUserDetailData.specific_user_detail.result).length > 0 ? (
                <div className="admin-title">
                  <h5 className="item-title">{
                      specificUserDetailData.specific_user_detail.result.Firstname ?
                      specificUserDetailData.specific_user_detail.result.Firstname + " " + specificUserDetailData.specific_user_detail.result.Lastname
                      : null
                    }</h5>
                  <span>{roleName != "" ? roleName : null}</span>
                </div>
              ):null}
            <div className="admin-img">
              <img src={specificUserDetailData && specificUserDetailData.specific_user_detail && specificUserDetailData.specific_user_detail.LOGO ? specificUserDetailData.specific_user_detail.LOGO : "img/figure/admin.jpg"} alt="Admin" />
            </div>
          </a>

          <div className="dropdown-menu dropdown-menu-right">
           
            <div className="item-content">
              <ul className="settings-list" style={{backgroundColor:"#042954"}}>
                <li><Link to="/edit_profile"><i className="flaticon-user" />My Profile</Link></li>
                <li><a href="#"><i className="flaticon-list" />Task</a></li>
                <li><a href="#"><i className="flaticon-chat-comment-oval-speech-bubble-with-text-lines" />Message</a></li>
                <li><a href="#"><i className="flaticon-gear-loading" />Account Settings</a></li>
                <li onClick={() =>logout()}><a href="#"><i className="flaticon-turn-off" />Log Out</a></li>
              </ul>
            </div>
          </div>
        </li>
        <li className="navbar-item dropdown header-message hidethis">
          <a className="navbar-nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
            <i className="far fa-envelope" />
            <div className="item-title d-md-none text-16 mg-l-10">Message</div>
            <span>5</span>
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            <div className="item-header">
              <h6 className="item-title">05 Message</h6>
            </div>
            <div className="item-content">
              <div className="media">
                <div className="item-img bg-skyblue author-online">
                  <img src="img/figure/student11.png" alt="img" />
                </div>
                <div className="media-body space-sm">
                  <div className="item-title">
                    <a href="#">
                      <span className="item-name">Maria Zaman</span>
                      <span className="item-time">18:30</span>
                    </a>
                  </div>
                  <p>What is the reason of buy this item.
                    Is it usefull for me.....</p>
                </div>
              </div>
              <div className="media">
                <div className="item-img bg-yellow author-online">
                  <img src="img/figure/student12.png" alt="img" />
                </div>
                <div className="media-body space-sm">
                  <div className="item-title">
                    <a href="#">
                      <span className="item-name">Benny Roy</span>
                      <span className="item-time">10:35</span>
                    </a>
                  </div>
                  <p>What is the reason of buy this item.
                    Is it usefull for me.....</p>
                </div>
              </div>
              <div className="media">
                <div className="item-img bg-pink">
                  <img src="img/figure/student13.png" alt="img" />
                </div>
                <div className="media-body space-sm">
                  <div className="item-title">
                    <a href="#">
                      <span className="item-name">Steven</span>
                      <span className="item-time">02:35</span>
                    </a>
                  </div>
                  <p>What is the reason of buy this item.
                    Is it usefull for me.....</p>
                </div>
              </div>
              <div className="media">
                <div className="item-img bg-violet-blue">
                  <img src="img/figure/student11.png" alt="img" />
                </div>
                <div className="media-body space-sm">
                  <div className="item-title">
                    <a href="#">
                      <span className="item-name">Joshep Joe</span>
                      <span className="item-time">12:35</span>
                    </a>
                  </div>
                  <p>What is the reason of buy this item.
                    Is it usefull for me.....</p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="navbar-item dropdown header-notification hidethis">
          <a className="navbar-nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
            <i className="far fa-bell" />
            <div className="item-title d-md-none text-16 mg-l-10">Notification</div>
            <span>8</span>
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            <div className="item-header">
              <h6 className="item-title">03 Notifiacations</h6>
            </div>
            <div className="item-content">
              <div className="media">
                <div className="item-icon bg-skyblue">
                  <i className="fas fa-check" />
                </div>
                <div className="media-body space-sm">
                  <div className="post-title">Complete Today Task</div>
                  <span>1 Mins ago</span>
                </div>
              </div>
              <div className="media">
                <div className="item-icon bg-orange">
                  <i className="fas fa-calendar-alt" />
                </div>
                <div className="media-body space-sm">
                  <div className="post-title">Director Metting</div>
                  <span>20 Mins ago</span>
                </div>
              </div>
              <div className="media">
                <div className="item-icon bg-violet-blue">
                  <i className="fas fa-cogs" />
                </div>
                <div className="media-body space-sm">
                  <div className="post-title">Update Password</div>
                  <span>45 Mins ago</span>
                </div>
              </div>
            </div>
          </div>
        </li>

      </ul>
    </div>
  </div>
  )
}


export default Header;
