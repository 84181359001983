import {
  REST_SETTINGS_REQUEST,
  REST_SETTINGS_SUCCESS,
  REST_SETTINGS_FALIURE,
  REST_SETTINGSUPDATE_REQUEST,
  REST_SETTINGSUPDATE_SUCCESS,
  REST_SETTINGSUPDATE_FALIURE
} from './RestSettingsConstants'

const intialstate = {
  rest_settings_loading : false,
  rest_settings : [],
  rest_settings_error : '',
  rest_settings_success : false,
  rest_update_loading:false,
  rest_update_success:false
}

const RestSettingsReducers = (state = intialstate,action) =>{
  switch(action.type){
    case REST_SETTINGS_REQUEST : return{
      ...state,
      rest_settings_loading:true
    }
    case REST_SETTINGS_SUCCESS : return{
      ...state,
      rest_settings_loading:false,
      rest_settings_success:true,
      rest_settings:action.payload
    }
    case REST_SETTINGS_FALIURE : return{
      ...state,
      rest_settings_error:action.payload
    }
    case REST_SETTINGSUPDATE_REQUEST : return{
      ...state,
      rest_update_loading:true,
      rest_update_success:false
    }
    case REST_SETTINGSUPDATE_SUCCESS : return{
      ...state,
      rest_update_loading:false,
      rest_update_success:true
    }
    default: return state
  }

}

export default RestSettingsReducers
