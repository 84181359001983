import React, {useState,useEffect,useMemo} from 'react';
import PrintCommon from './PrintCommon';
import { wordwrap } from './Commons';
import { useSelector,useDispatch } from 'react-redux';
import { fetchOrderDetail, acceptOrder, declineOrder } from '../Redux/AllBook/AllBookActions';
import { useLocation, Link } from "react-router-dom";
import dateFormat from "dateformat";
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


function OrderDetailPrint(){
  //const { order_id } = useParams();
  const { state } = useLocation();
const OrderDetailData = useSelector(state =>state.AllBook)
// store data access End
const dispatch = useDispatch()  // for accessing the redux function

const [responseMessage, setResponseMessage] = useState('Order Confirmed!')
const [orderDetailResult, setOrderResult] = useState('')

// fetch allBook  api hook start
useEffect(() =>{
     dispatch(fetchOrderDetail(state.order))
},[dispatch])
// fetch allBook api hook End

// add data of allFees api into constant,hook start


useMemo(() =>{
  if(OrderDetailData && OrderDetailData.order_detail && OrderDetailData.order_detail.order_id) {
    setOrderResult(OrderDetailData.order_detail)
  } else {
    setOrderResult('')
  }
  setResponseMessage("");
},[OrderDetailData])

useMemo(() =>{
  if(OrderDetailData && OrderDetailData.single_order_update){    
    if(OrderDetailData.single_order_update.order_status) {
      if(OrderDetailData.single_order_update.order_status == 'Complete') {
        setResponseMessage("Order Confirmed!");
      } else if(OrderDetailData.single_order_update.order_status.toLowerCase().startsWith("cancel")) {
        setResponseMessage("Order Cancelled!");
      }
      OrderDetailData.order_detail.order_status = OrderDetailData.single_order_update.order_status;
      setOrderResult(OrderDetailData.order_detail)
    }
  }
},[OrderDetailData])

// add data of allFees api into constant,hook End

const calSubTot = () => {
  let subtotal = 0.0;
  orderDetailResult && orderDetailResult.items.map((item,index) => {
    subtotal += parseFloat(item.total);
  });
  return subtotal.toFixed(2);
  //setSubtot(subtotal);
}

const printSlip = (order) => {
    let map = new Map();
    map.set('orderId', order.order_id);
    map.set('date', order.order_date);
    map.set('userName', order.billing_details.first_name + ' ' + order.billing_details.last_name);
    map.set('mobile', order.billing_details.phone);
    map.set('restaurantName', order.shipping_details.first_name);
    map.set('address1', order.billing_details.address_1);
    map.set('city', order.billing_details.city); 
    map.set('orderType', order.shipping_method);
    map.set('note', order.order_note);
    let items = [];
    // setPageLoader(false);
    order.items.map((item,index)=>{
      let itemMap = new Map();
      let sb = '';
      if (item.name.trim().length < 16) {
        sb = sb+"\t"+item.qty[0]+"x: "+item.name.trim();
      } else {
        let words = item.name.trim().split(" ");
        sb = sb+"\t"+item.qty[0]+"x: "+wordwrap(words, 15, false, 4).trim();
      }
      itemMap.set('item', sb);

      sb = '';
      item.addons.map((addon,index)=> {
        if (addon.addon_full_name) {
            if (addon.addon_full_name.trim().startsWith("Additional Instruction")) {
                if (addon.addon_full_name.trim().length < 31) {
                    sb = sb+"\t\t"+addon.addon_full_name.trim()+"\n";
                } else {
                    var words = addon.addon_full_name.trim().split(" ");
                    sb = sb+"\t\t"+wordwrap(words, 30, true, 0)+"\n";
                }
            } else {
                if (addon.addon_full_name.trim().length < 31) {
                    sb = sb+"\t\t"+addon.addon_full_name.trim()+"\n";
                } else {
                    var words = addon.addon_full_name.trim().split(" ");
                    sb = sb+"\t\t"+wordwrap(words, 30, true, 0)+"\n";
                }
            }
        }
      });
      if (sb) {
        itemMap.set("addons", sb);
      }
      items.push(itemMap);
    });
    return <PrintCommon items={items} datamap={map} isNotify = {state.isNotify}/>
}

const handleacceptOrder = (order_id) =>{
   dispatch(acceptOrder(order_id))
}

const handledeclineOrder = (order_id) =>{
   dispatch(declineOrder(order_id))
}

        return (
          <>
          <div className="breadcrumbs-area">
            <h3>Order Detail</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Order Detail</li>
            </ul>
          </div>
            {/* Breadcubs Area Start Here */}
          
            <div className="">
            <div className="pad-5 bg-dark height-42">
            {orderDetailResult && (orderDetailResult.order_status != 'Complete' && !orderDetailResult.order_status.toLowerCase().startsWith("cancel")) ? (<>
                <a className="btn btn-success btn-lg" style={{'color':'#ffffff'}} onClick={(e) =>handleacceptOrder(orderDetailResult.order_id)}><i className="fas fa-check" aria-hidden="true"> Accept</i></a>
                                  <a style={{'margin-left': '10px', 'color': '#ffffff'}} className="btn btn-danger btn-lg" onClick={(e) =>handledeclineOrder(orderDetailResult.order_id)}><i className="fas fa-times" aria-hidden="true"> Decline</i></a>
               </>)
              : ''
            }
            {orderDetailResult && orderDetailResult.order_id == state.order? printSlip(orderDetailResult) : ''}
           </div>
           <span className='pad-r-5 right-float text-danger'>Reconnect printer only if printer connection lost or after printer restarted</span>
        {OrderDetailData && OrderDetailData.order_detail_loading === true ?
            (<div className="card-body">
                <div className="Student-Loader">
                 <Loader
                   className = "student-detail-loader"
                   type="ThreeDots"
                   color="#fea801"
                   height={150}
                   width={150}
               />
            </div>
          </div>) : (
         <div className="card-body">
          
        <div className="heading-layout1">
          <div className="item-title">
          
          </div>

        </div>

        {responseMessage ? 
          <p className="alert alert-success w-auto" role="alert">
            {responseMessage}
          </p> : <p></p>
         }

        {OrderDetailData && OrderDetailData.order_update_loading === true ?
            (<div>               
                 <Loader
                   className = "cl-center"
                   type="ThreeDots"
                   color="#fea801"
                   height={60}
                   width={100}
               />
          </div>) : ''}
            
          <div className="single-info-details mx-width-600">

            <div className="item-content">
              <div className="header-inline item-header">

              </div>
              {orderDetailResult ? 
               <>
               <div className="font-italic">
                 <div className='cl-center font-medium text-dark-medium'>Service Type: {orderDetailResult.shipping_method}</div>  
                 <hr/>
                 <div>
                 <table className="tbl">
                  <tbody className='tbl-bottom'>
                      <tr><td className='all-mgn'><span className='font-medium text-dark-medium'>Order:</span> {orderDetailResult.order_id}</td><td className='all-mgn width-190'><span className='font-medium text-dark-medium'>Date:</span> {dateFormat(new Date(orderDetailResult.order_date),'dd mmm yyyy')}</td></tr>                   
                      <tr><td className='all-mgn'><span className='font-medium text-dark-medium'>Order By:</span> {orderDetailResult.billing_details.first_name} {orderDetailResult.billing_details.last_name}</td><td className='all-mgn width-190'><span className='font-medium text-dark-medium'>Time:</span> {dateFormat(new Date(orderDetailResult.order_date),'hh:MM TT')}</td></tr>
                   
                   </tbody>
                   </table>
                 </div>
                 
                 <div>
                 
                   <table className="tbl">
                    <tbody className='tbl-bottom'>
                       <tr>
                         <td className='all-mgn'>
                           
                           <ul className="itemsDetails">{orderDetailResult.items.map((item,index) => {
                             return (<><li className='font-l font-medium text-dark-medium'><span className="value">{item.qty[0]}x: </span><span>{item.name} </span> <span className='right-float'>{orderDetailResult.currency_symbol} {item.total}</span></li>                          
                                  {
                                     item.addons.map((addon,index) => {
                                        return (<li className='mg-l-20'>{addon.addon_full_name}</li>);
                                     })
                                  }
                                 </>)
                              })}
                           </ul>          
                         </td>                        
                       </tr>
                    </tbody>
                   </table>
                   
                 </div>

                 <div>
                   <table className="tbl">
                    <tbody className='tbl-bottom'>

                       <tr>
                         <td className='width-perc-50 font-medium text-dark-medium all-mgn'><span className='right-float'>SubTotal:</span></td>
                         <td className="width-perc-50 font-medium text-dark-medium all-mgn"><span className='right-float'>{orderDetailResult.currency_symbol} {calSubTot()}</span></td>
                       </tr>

                       {orderDetailResult.tip_amount ?
                        <tr>
                          <td className='width-perc-50 all-mgn'><span className='right-float'>Tip:</span></td>
                          <td className="width-perc-50 all-mgn"><span className='right-float'>{orderDetailResult.currency_symbol} {orderDetailResult.tip_amount}</span></td>
                        </tr> : ''
                       }

                       <tr>
                         <td className='width-perc-50 all-mgn'><span className='right-float'>Tax:</span></td>
                         <td className="width-perc-50 all-mgn"><span className='right-float'>{orderDetailResult.currency_symbol} {orderDetailResult.sales_tax}</span></td>
                       </tr>

                       <tr>
                         <td className='width-perc-50 all-mgn font-medium text-dark-medium'><span className='right-float'>Total:</span></td>
                         <td className="width-perc-50 all-mgn font-medium text-dark-medium"><span className='right-float'>{orderDetailResult.currency_symbol} {orderDetailResult.order_total}</span></td>
                       </tr>

                       
                    </tbody>
                   </table>
                  </div> 
                  
                  <table className="tbl">
                    <tbody className="tbl-bottom">
                      <tr>
                        <td className='cl-top font-medium text-dark-medium'>Note:</td>
                        <td>{orderDetailResult.order_note}</td>
                      </tr>
                    </tbody>
                  </table>    

                
              </div></> :''}
            </div>
          </div>


         </div>
          )}  
         </div>
          </>
        );
};

export default OrderDetailPrint;
