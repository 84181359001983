import {
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FALIURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FALIURE
} from './ProfileConstants'

const intialstate = {
  is_loading : false,
  success_status : false,
  update_profile : null,
  profile : null,
  error : ''
}

const ProfileReducers = (state = intialstate,action) =>{
  switch(action.type){
    case UPDATE_PROFILE_REQUEST : return{
      ...state,
      is_loading: true,
      success_status: false,
      update_profile: null
    }
    case UPDATE_PROFILE_SUCCESS : return{
      ...state,
      is_loading: false,
      success_status: true,
      update_profile: action.payload
    }
    case UPDATE_PROFILE_FALIURE : return{
      ...state,
      is_loading: false,
      success_status: false,
      error:action.payload
    }
    case GET_PROFILE_REQUEST : return{
      ...state,
      is_loading: true,
      success_status: false,
      profile: null
    }
    case GET_PROFILE_SUCCESS : return{
      ...state,
      is_loading: false,
      success_status: true,
      profile: action.payload
    }
    case GET_PROFILE_FALIURE : return{
      ...state,
      is_loading: false,
      success_status: false,
      error:action.payload
    }
    default: return state
  }

}

export default ProfileReducers
