import React, {useState,useEffect,useMemo} from 'react';
import Loader from 'react-loader-spinner';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import 'rc-time-picker/assets/index.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { useSelector,useDispatch } from 'react-redux'
import { addHoliday } from '../Redux/AddHoliday/AddHolidayActions'
import moment from 'moment';
import { fetchAllDriver } from '../Redux/AllDriver/AllDriverActions';
function AddHoliday(){
  // store data access start
const classes = useSelector(state =>state.AllClasses)
const sections = useSelector(state =>state.AllSections)
const subjects = useSelector(state =>state.AllSubjects)
const teachers = useSelector(state =>state.AllTeachers)
const addExam_data = useSelector(state =>state.AddHoliday)

const [startDate, setStartDate] = useState(new Date());
// store data access End
  const dispatch = useDispatch()  // for accessing the redux function

  // component all states define start
  const [classesResult,setClassesResult] = useState([])
  const [allClassesInfo,setClassesInfo] = useState([])
  const [allSectionsInfo,setSectionsInfo] = useState([])
  const [allSubjectsInfo,setSubjectsInfo] = useState([])
  const [teacherResult,setTeacherResult] = useState([])
  const [allTeachersInfo,setTeachersInfo] = useState([])

  const [inputValues,setInputValues] = useState({
                                          holidayName:"",
                                          holiday_date:startDate,

  })
  const [mediumId,setMediumId] = useState('')
    const [error,setError] = useState(null)
    const [successStatus,setSuccessStatus] = useState(null)




  // component all states define End

   //hooks start
   // fetch classes and teachers api hook start
   useEffect(() =>{
    // dispatch(fetchAllClasses())
  //   dispatch(fetchAllTeachers())
   },[dispatch])
// fetch classes and teachers api hook End

// add data of classes api into constant,hook start
   useMemo(() =>{
     if(classes && classes.all_classes && classes.all_classes.result && classes.all_classes.success === true){
       setClassesResult(classes.all_classes.result)
     }
   },[classes])
// add data of classes api into constant,hook End

// when classesResult change add data into classInfo,hook start
   useMemo(()=>{
     if(classesResult && classesResult.data){
           setClassesInfo(classesResult.data)
     }
   },[classesResult])
// when classesResult change add data into classInfo,hook End

// fetch allSections and allsubject api,hook start
   useMemo(() =>{
     if(mediumId !='' && inputValues.classId !=''){
      //   dispatch(fetchAllSections(inputValues.classId,mediumId))
      //   dispatch(fetchAllSubjects(inputValues.classId,mediumId))
     }
   },[mediumId,inputValues.classId])
// fetch allSections and allsubject api,hook End

// add data of allSections api into constant,hook start
   useMemo(()=>{
     if(sections && sections.all_sections && sections.all_sections.result && sections.all_sections.success === true){
       setSectionsInfo(sections.all_sections.result)
     }
   },[sections])
// add data of allSections api into constant,hook End

// add data of allsubject api into constant,hook start
   useMemo(()=>{
     if(subjects && subjects.all_subjects && subjects.all_subjects.result && subjects.all_subjects.success === true ){
       setSubjectsInfo(subjects.all_subjects.result)
     }
   },[subjects])
// add data of allsubject api into constant,hook End

// add data of teachers api into constant,hook start
   useMemo(()=>{
     if(teachers && teachers.all_teachers && teachers.all_teachers.result && teachers.all_teachers.success === true){
       setTeacherResult(teachers.all_teachers.result)
     }
   },[teachers])
// add data of teachers api into constant,hook End

// when teacherResult change add data into teachersInfo,hook start
   useMemo(()=>{
     if(teacherResult && teacherResult.data){
           setTeachersInfo(teacherResult.data)
     }
   },[teacherResult])
// when teacherResult change add data into teachersInfo,hook End

// add data of addExam api into constant,hook start
   useMemo(()=>{
     if(addExam_data && addExam_data.add_holiday && addExam_data.add_holiday.error){
       setError(addExam_data.add_holiday.error)
     }
     else if(addExam_data && addExam_data.add_holiday && addExam_data.add_holiday.success){
        setSuccessStatus(addExam_data.add_holiday.success)
     }
   },[addExam_data])
// add data of addExam api into constant,hook End

   //hooks end

// component function start
// classHandler function start
   const classHandler = (event) =>{

     const classInfo = event.target.value
     if(classInfo !=""){
       setInputValues({...inputValues,classId:classInfo})
       allClassesInfo.filter(classid =>classid.id ==
          classInfo).map((item,index) =>{
         setMediumId(item.ClassMediumId)
       })

     }
     else{
       setInputValues({...inputValues,classId:classInfo})
     }

   }
// classHandler function End

// examHandler function start
   const examHandler = (event) =>{
   event.preventDefault()
   const exam_info = inputValues;
     dispatch(addHoliday(exam_info))
      dispatch(fetchAllDriver())
 }
// examHandler function End

// resetHandler function start
   const resetHandler = (event) =>{
     event.preventDefault()
     window.location.reload(false);
   }
// resetHandler function start


// resetHandler function start
   const handleDateChange = (value) =>{
     const selectedData = moment(value).format('D-MM-YYYY');

    setInputValues({...inputValues,holiday_date:selectedData})
    setStartDate(value)
   }

   
// resetHandler function start

// component function end

        return (
            <div className="col-4-xxxl col-12">
                <div className="card height-auto">
                {addExam_data && addExam_data.add_holiday_loading === false ? successStatus === false || successStatus === null  ? (
                  <div className="card-body">
                    <div className="heading-layout1 bg-darkblue">
                      <div className="item-title">
                        <h3 className='text-light'>Add Holiday</h3>
                        {addExam_data.add_holiday && addExam_data.add_holiday.request_status && addExam_data.add_holiday.request_status === true ? (<div className="success-greeting1">
                        <h2 className="addtimesuccess">Holiday Added Successfully!</h2>
                        </div>): null}
                        {addExam_data.add_holiday && addExam_data.add_holiday.object && addExam_data.add_holiday.object && addExam_data.add_holiday.object.error ? (<div className="success-greeting1">

                        <h2 className="addtimeerror">{addExam_data.add_holiday.object.error}</h2>
                        </div>): null}
                      </div>

                    </div>
                    <form className="new-added-form" onSubmit={(e) =>examHandler(e)}>
                      <div className="row">
                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                          <label>Holiday Name *</label>
                          <input type="text" placeholder className="form-control" value={inputValues.holidayName} onChange={(e) =>setInputValues({...inputValues,holidayName:e.target.value})} required/>
                          {error != null && error.holidayName ? (<h6 className="addStudent-error">*{JSON.stringify(error.holidayName).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>


                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                          <label>Date</label>
                        <DatePicker selected={startDate} className="form-control" onChange={(date) => handleDateChange(date)} dateFormat="dd-MM-yyyy" />
                          {error != null && error.holiday_date ? (<h6 className="addStudent-error">*{JSON.stringify(error.holiday_date).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>


                        <div className="col-12 form-group mg-t-8" style={{paddingTop:"20px"}}>
                          <button type="submit" style={{marginLeft:"10px"}} className="btn-fill-lg bg-blue-dark btn-hover-yellow right-float">Save</button>
                          <button type="reset" className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark right-float" onClick={(e) =>resetHandler(e)}>Reset</button>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : (<div className="card-body">
                      <div className="success-greeting">
                      <h2>Thank You!</h2>
                      <h2>Holiday Added Successfully!</h2>
                      </div>
                  </div>):(<div className="card-body">
                      <div className="Student-Loader">
                      <Loader
                      className = "student-detail-loader"
                    type="MutatingDots"
                    color="#fea801"
                    height={150}
                    width={150}

       />
                      </div>
                    </div>)}
                </div>
              </div>
        );
}

export default AddHoliday;
