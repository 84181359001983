import {
  FETCH_SPECIFIC_USER_DETAIL_REQUEST,
  FETCH_SPECIFIC_USER_DETAIL_SUCCESS,
  FETCH_SPECIFIC_USER_DETAIL_FALIURE
} from './SpecificUserDetailConstants'
import {config} from '../config'
import Cookies from 'js-cookie';


export const fetchSpecificUserDetailRequest = () =>{
  return{
    type : FETCH_SPECIFIC_USER_DETAIL_REQUEST
  }
}

export const fetchSpecificUserDetailSuccess = (specific_user_detail_success) =>{
  return{
    type : FETCH_SPECIFIC_USER_DETAIL_SUCCESS,
    payload : specific_user_detail_success
  }
}

export const fetchSpecificUserDetailFaliure = (error) =>{
  return{
    type : FETCH_SPECIFIC_USER_DETAIL_FALIURE,
    payload : error
  }
}

export const fetchSpecificUserDetail = () =>{
    return(dispatch) => {
      dispatch(fetchSpecificUserDetailRequest())
      const url =`${config.api_root}/api/v1/merchants/config?access_token=${config.token}`

      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json() )
    .then(specific_user_detail_res =>{
      const specific_user_detail_success = specific_user_detail_res.object
      const url2 =`${specific_user_detail_res.object.STATIC_RESOURCE_ENDPOINT}${config.mid}${specific_user_detail_res.object.STATIC_RESOURCE_SUFFIX}`;
      localStorage.setItem("merchant_url", url2);
      fetch(url2, request_option)
      .then(response => response.json() )
      .then(specific_user_detail_res2 =>{
        const specific_user_detail_success2 = specific_user_detail_res2.object
        dispatch(fetchSpecificUserDetailSuccess(specific_user_detail_success2))
        // console.log(specific_user_detail_success2);
      })
      .catch(error => {
        const errorMsg = error
        dispatch(fetchSpecificUserDetailFaliure(errorMsg))
      })
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchSpecificUserDetailFaliure(errorMsg))
    })
    }
}


export const fetchSpecificUserDetailfromCookies = (cookies_token, cookies_user) =>{
    return(dispatch) => {
      dispatch(fetchSpecificUserDetailRequest())
      const url =`${config.api_root}/api/v1/merchants/config?access_token=${cookies_token}`

      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ cookies_token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json() )
    .then(specific_user_detail_res =>{
      const specific_user_detail_success = specific_user_detail_res.object
      const url2 =`${specific_user_detail_res.object.STATIC_RESOURCE_ENDPOINT}${config.mid}${specific_user_detail_res.object.STATIC_RESOURCE_SUFFIX}`;
      localStorage.setItem("merchant_url", url2);
      fetch(url2, request_option)
      .then(response => response.json() )
      .then(specific_user_detail_res2 =>{
        const specific_user_detail_success2 = specific_user_detail_res2.object
        dispatch(fetchSpecificUserDetailSuccess(specific_user_detail_success2))
      })
      .catch(error => {
        const errorMsg = error
        dispatch(fetchSpecificUserDetailFaliure(errorMsg))
      })
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchSpecificUserDetailFaliure(errorMsg))
    })
    }
}
