import React, {useState,useEffect,useMemo} from 'react';
import Loader from 'react-loader-spinner';
import 'rc-time-picker/assets/index.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
  FormControl,
  FormGroup,
  FormLabel
} from "react-bootstrap";
import { useSelector,useDispatch } from 'react-redux'

import { addSingleAddon } from '../Redux/AddTiming/AddTimingActions'
import moment from 'moment';
function AddSingleAddonGroup(props){
  // console.log('newpprp', props)

  const currentAddOnGroupId =
    props &&
    props.currentAddOnGroupId ? props.currentAddOnGroupId
      : null;

  const currentAddOnGroupName =
        props &&
        props.currentAddOnGroupName ? props.currentAddOnGroupName
          : null;
  // store data access start
const classes = useSelector(state =>state.AllClasses)
const sections = useSelector(state =>state.AllSections)
const subjects = useSelector(state =>state.AllSubjects)
const teachers = useSelector(state =>state.AllTeachers)
const addExam_data = useSelector(state =>state.AddTiming)
// store data access End
  const dispatch = useDispatch()  // for accessing the redux function

  // component all states define start
  const [classesResult,setClassesResult] = useState([])
  const [allClassesInfo,setClassesInfo] = useState([])
  const [saveAddonGroup,setSaveAddonGroup] = useState('')
  const [allSectionsInfo,setSectionsInfo] = useState([])
  const [allSubjectsInfo,setSubjectsInfo] = useState([])
  const [teacherResult,setTeacherResult] = useState([])
  const [allTeachersInfo,setTeachersInfo] = useState([])
  const [inputValues,setInputValues] = useState({
                                          name:"",
                                          nameKey:"",
                                          unitPrice:0,
                                          taxable:false,
                                          quantitative:false
  })
  const [inputValuesdefault,setInputValuesdefault] = useState({
                                          name:"",
                                          nameKey:"",
                                          unitPrice:0,
                                          taxable:false,
                                          quantitative:false
  })
  const [mediumId,setMediumId] = useState('')
    const [error,setError] = useState(null)
    const [successStatus,setSuccessStatus] = useState(null)




  // component all states define End

   //hooks start
   // fetch classes and teachers api hook start
   useEffect(() =>{
    // dispatch(fetchAllClasses())
  //   dispatch(fetchAllTeachers())
   },[dispatch])
// fetch classes and teachers api hook End

// add data of classes api into constant,hook start
   useMemo(() =>{
     if(classes && classes.all_classes && classes.all_classes.result && classes.all_classes.success === true){
       setClassesResult(classes.all_classes.result)
     }
   },[classes])
// add data of classes api into constant,hook End

// when classesResult change add data into classInfo,hook start
   useMemo(()=>{
     if(classesResult && classesResult.data){
           setClassesInfo(classesResult.data)
     }
   },[classesResult])
// when classesResult change add data into classInfo,hook End

// fetch allSections and allsubject api,hook start
   useMemo(() =>{
     if(mediumId !='' && inputValues.classId !=''){
      //   dispatch(fetchAllSections(inputValues.classId,mediumId))
      //   dispatch(fetchAllSubjects(inputValues.classId,mediumId))
     }
   },[mediumId,inputValues.classId])
// fetch allSections and allsubject api,hook End

// add data of allSections api into constant,hook start
   useMemo(()=>{
     if(sections && sections.all_sections && sections.all_sections.result && sections.all_sections.success === true){
       setSectionsInfo(sections.all_sections.result)
     }
   },[sections])
// add data of allSections api into constant,hook End

// add data of allsubject api into constant,hook start
   useMemo(()=>{
     if(subjects && subjects.all_subjects && subjects.all_subjects.result && subjects.all_subjects.success === true ){
       setSubjectsInfo(subjects.all_subjects.result)
     }
   },[subjects])
// add data of allsubject api into constant,hook End

// add data of teachers api into constant,hook start
   useMemo(()=>{
     if(teachers && teachers.all_teachers && teachers.all_teachers.result && teachers.all_teachers.success === true){
       setTeacherResult(teachers.all_teachers.result)
     }
   },[teachers])
// add data of teachers api into constant,hook End

// when teacherResult change add data into teachersInfo,hook start
   useMemo(()=>{
     if(teacherResult && teacherResult.data){
           setTeachersInfo(teacherResult.data)
     }
   },[teacherResult])
// when teacherResult change add data into teachersInfo,hook End

// add data of addExam api into constant,hook start
const [show,setShow] = useState(true);

  useMemo(() => {
    if(addExam_data && addExam_data.add_timing && addExam_data.add_timing.object && addExam_data.add_timing.request_status && saveAddonGroup == 'save'){        
      setSuccessStatus(addExam_data.add_timing.request_status)   
    //   setInterval(() => {
    //     setShow(false); 
    // }, 3000);  
      props.addonsGroupIdAction(currentAddOnGroupId, currentAddOnGroupName)
      setSaveAddonGroup(null)
    } else if(addExam_data && addExam_data.add_timing && addExam_data.add_timing.object && addExam_data.add_timing.object.error && saveAddonGroup == 'save'){   
       setError(addExam_data.add_timing.object.error)
       setSaveAddonGroup(null)
    }      
  }, [addExam_data])
// add data of addExam api into constant,hook End

   //hooks end

// component function start
// classHandler function start
   const classHandler = (event) =>{

     const classInfo = event.target.value
    //  console.log("classInfo",event.target.value)
     if(classInfo !=""){
       setInputValues({...inputValues,classId:classInfo})
       allClassesInfo.filter(classid =>classid.id == classInfo).map((item,index) =>{
         setMediumId(item.ClassMediumId)
       })

     }
     else{
       setInputValues({...inputValues,classId:classInfo})
     }

   }
// classHandler function End

// examHandler function start
  const examHandler = (event) =>{
      event.preventDefault()
      setSaveAddonGroup(null)
      const exam_info = inputValues;
      setSaveAddonGroup('save')
      dispatch(addSingleAddon(currentAddOnGroupId, exam_info))
      setInputValues(inputValuesdefault)
      //dispatch(fetchAllRestTimings())
    //   setInputValues(inputValuesdefault)
    //   setInterval(() => {
    //     setShow(false); 
    // }, 2000);  
 
      //event.target.reset();
  }
// examHandler function End




        return (
          <>
        
            <div className="col-12">
                <div className="card height-auto">
                  <div className="card-body">
                    <div className="heading-layout1">
                      <div className="item-title">
                        {/* <h3>Add Addon</h3> */}
                      </div>
                    </div>
                    {/* {addExam_data.add_timing_loading ?
                          <div className='mg-l-40'>
                          <Loader
                            className = "cl-center"
                            type="MutatingDots"
                            color="#fea801"
                            height={150}
                            width={150}    
                          /></div>
                         : addExam_data.add_timing && addExam_data.add_timing.request_status && addExam_data.add_timing.request_status === true ? (<div className="success-greeting1">
                             <h2 className="addtimesuccess cl-center">Addon Added Successfully!</h2>
                          </div>) : addExam_data.add_timing && addExam_data.add_timing.object && addExam_data.add_timing.object && addExam_data.add_timing.object.error ? (<div className="success-greeting1">
                             <h2 className="addtimeerror cl-center">{addExam_data.add_timing.object.error}</h2>
                           </div>): null} */}
                <form className="new-added-form" id="addonaddform" onSubmit={(e) =>examHandler(e)}>
                      <div className="row">
                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                          <label>Name *</label>
                          <input type="text" placeholder className="form-control" value={inputValues.name} onChange={(e) =>setInputValues({...inputValues,name:e.target.value})} required/>
                          {error != null && error.name ? (<h6 className="addStudent-error">*{JSON.stringify(error.name).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>
                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                          <label>NameKey(without spaces) *</label>
                          <input type="text" placeholder className="form-control" value={inputValues.nameKey} onChange={(e) =>setInputValues({...inputValues,nameKey:e.target.value})} required/>
                          {error != null && error.nameKey ? (<h6 className="addStudent-error">*{JSON.stringify(error.nameKey).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>
                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                          <label>Unit Price *</label>
                          <input type="text" placeholder className="form-control" value={inputValues.unitPrice} onChange={(e) =>setInputValues({...inputValues,unitPrice:e.target.value})} required/>
                          {error != null && error.unitPrice ? (<h6 className="addStudent-error">*{JSON.stringify(error.unitPrice).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>
                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                        <FormGroup>
                            <FormLabel>Taxable *</FormLabel>
                            {inputValues.unitPrice > 0 ? (<FormControl
                              required
                              type="text"
                              value='true'
                        
                            >
                           
                            </FormControl>):(<FormControl
                              required
                              type="text"
                              value={inputValues.taxable}
                        
                            >
                           

                            </FormControl>)}
                            
                            {error != null && error.taxable ? (<h6 className="addStudent-error">*{JSON.stringify(error.taxable).replace(/[\[\]"]+/g,"")}</h6>):null}
                          </FormGroup>
                        </div>

                        {/* this field is hidden right now because I just wanna add data in database which is bydefault false */}
                        <div className="col-12-xxxl col-lg-6 col-12 form-group">
                        <FormGroup hidden>
                            <FormLabel>Quantitative</FormLabel>
                            <FormControl
                              required
                              type="text"
                              value={inputValues.quantitative}
                              onChange={(e) =>setInputValues({...inputValues,quantitative:e.target.value})}
                              as="select"
                            >
                            <option value={false}>FALSE</option>
                            <option value={true}>TRUE</option>

                            </FormControl>
                            {error != null && error.quantitative ? (<h6 className="addStudent-error">*{JSON.stringify(error.quantitative).replace(/[\[\]"]+/g,"")}</h6>):null}
                          </FormGroup>
                        </div>

                        <div className="col-12 mg-t-20">
                          <button type="submit" className="btn-fill-lg bg-blue-dark btn-hover-yellow right-float mg-l-10">Save</button>
                          </div>
                      </div>
                    </form>
               
                  
                  </div>             
                </div>
              </div>
            
              </>
        );
}

export default AddSingleAddonGroup;
