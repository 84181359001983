import React, { useState, useEffect, useMemo } from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useSelector, useDispatch } from "react-redux";
import Switch from "react-switch";

import {
  fetchsingleproduct,
  updateProductMemo,
  disableStock,
  enableStock,
  enableProduct,
  disableProduct,
  deleteProductAddons,
  updateProductPrice,
  fetchProductsSuccess,
  deleteProductCategory,
  fetchcategory,
} from "../Redux/AllCategories/AllCategoriesActions";
import { Link, useHistory, useLocation } from "react-router-dom";
import { config } from "../Redux/config";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";

function DirectEditProduct() {
  // store data access start
  
  const productData = useSelector((state) => state.AllCategories);

  // store data access End
  const dispatch = useDispatch(); // for accessing the redux function
  const { state } = useLocation();
  const history = useHistory();
  // component all states define start
  const [productAddonsResult, setProductAddonsResult] = useState([]);
  const [productCategoriesResult, setProductCategoriesResult] = useState([]);
  const [productAddonsloaded, setProductAddonsloaded] = useState("");
  const [stockProductStatus, setStockProductStatus] = useState([]);
  const [enableDisableProductStatus, setEnableDisableProductStatus] = useState([]);
  const [saveState, setSaveState] = useState("");
  const [indexState, setIndexState] = useState(0);
  const [editProductResp, setEditProductResp] = useState({
    type: "",
    message: "",
  });


  const [productValues, setProductValues] = useState({
    productId: state.productId,
    name: state.name,
    description: state.description,
    categoryName: state.categoryName,
    shortDescription: state.shortDescription,
    price: state.price,
    image: state.image,
    stockStatus: state.stockStatus,
    productStatus: state.productStatus
  });

  console.log(state.productId);
  console.log(productValues.productId);


  useEffect(() => {
    dispatch(fetchsingleproduct(state.productId));
    dispatch(fetchcategory(state.productId));
    setProductValues(state);
    resetEditProduct();
  }, [dispatch,state.productId,state]);

  const goToBack = () => {
    history.goBack();
  };

  const removeGroup = (addonGroupId) => {
    setSaveState("delete");
    dispatch(deleteProductAddons(state.productId, addonGroupId));
  };

  const removeCategoryGroup = (catId) => {
    setSaveState("delete");
    dispatch(deleteProductCategory(state.productId, catId));
  };

  const setProductStatus = (id, status) => {
    setEnableDisableProductStatus(status)
    if (status === true) {
      dispatch(disableProduct(id));
    } else {
      dispatch(enableProduct(id));
    }
  };

  const updateProductStatus = (id, status) => {
    setSaveState('stock_update');
    setStockProductStatus(status)
    console.log(status)
    if (status === true) {
      dispatch(enableStock(id));
    } else {
      dispatch(disableStock(id));
    }
  };

  useMemo(() => {
    if (
      productData &&
      productData.stock_product &&
      saveState == "stock_update"
    ) {
      productValues.stockStatus = !stockProductStatus;
      // console.log('productValue===>' + productValues.stockStatus)
      setProductValues(productValues);
      editProductResp.type = "stock_success";
      editProductResp.message = "Stock Status Change Successfully!";
      setEditProductResp(editProductResp);
    }
  }, [productData]);

  useMemo(() => {
    if (
      productData &&
      productData.status_product && productData.status_product.object
    ) {
      productValues.productStatus = !enableDisableProductStatus;
      setProductValues(productValues);
    }
  }, [productData]);
  

  const showAddons = (index) => {
    setIndexState(index);
  };

  const hideAddons = () => {
    setIndexState(-1);
  };


  const editProductHandler = (event) => {
    event.preventDefault();
    setSaveState("update");
    const product_info = productValues;
    dispatch(updateProductMemo(product_info));

  };

  const editProductPrice = () => {
    setSaveState("price_update");
    const productdata = {
      productId: productValues.productId,
      price: productValues.price,
    };
    dispatch(updateProductPrice(productdata));
  };



  
  function handleChange(e) {
    
    setProductValues(URL.createObjectURL(e.target.files[0]));

    // Get the file object from the event
    var file = e.target.files[0];

    // Get the ArrayBuffer from the file object
    file
      .arrayBuffer()
      .then((buffer) => {
        // Fetch the API with the ArrayBuffer as the body
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + config.token,
            "Content-Type": file.type, // e.g. 'image/png', 'image/jpeg', 'image/jpg'
          },
          body: buffer, // pass the ArrayBuffer directly
        })
          .then((response) => response.text())
          .then((data) => {
            // Handle the response data
           
            dispatch(fetchProductsSuccess(data));
            var jsonString = data;

            // Parse the JSON string into a JavaScript object
            var jsonObject = JSON.parse(jsonString);

            // Get the imageId from the object
            var imageId = jsonObject.imageId;
            var imagePoint = jsonObject.endpoint;
            setProductValues({ ...productValues, image: [imagePoint] });
            setProductValues({ ...productValues, productImageUrl: imagePoint });
            // Create a new XMLHttpRequest object
            var xhr = new XMLHttpRequest();

            // Set the responseType to blob
            xhr.responseType = "blob";

            // Use the open method to specify the GET method and the imageId URL
            xhr.open("GET", imageId);

            // Use the send method to send the request
            xhr.send();

            // Use the onload event handler to execute a function when the response is ready
            xhr.onload = function () {
              // Create a new FileReader object
              var reader = new FileReader();

              // Use the readAsDataURL method to read the response blob as a data URL
              reader.readAsDataURL(xhr.response);

              // Use the onloadend event handler to execute another function when the reading is finished
              reader.onloadend = function () {
                // Get the data URL of the image, which contains the Base64 encoded string
                var dataURL = reader.result;

                // Optionally, remove the data:image/png;base64, prefix and get only the Base64 string
                var base64 = dataURL.replace(/^data:image\/png;base64,/, "");

                // Print the Base64 string to the console
                
              };
            };

            
          })
          .catch((error) => {
            // Handle the error
            console.error(error);
          });
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  }



  const addAddonGroups = () => {
    const addonGroupData = {
      catName: state.catName,
      categoryId: state.categoryId,
      productId: state.productId,
      productName: state.name,
    };
    return addonGroupData;
  };

  const addCategoryGroups = () => {
    const addonGroupData = {
      catName: state.catName,
      categoryId: state.categoryId,
      productId: state.productId,
      productName: state.name,
    };
    return addonGroupData;
  };

  useMemo(() => {
    if (productData && productData.edit_product && saveState == "update") {
      editProductResp.type = "success";
      editProductResp.message = "Updated Successfully!";
      setEditProductResp(editProductResp);
    }
  }, [productData]);

  useMemo(() => {
    if (
      productData &&
      productData.edit_product_price &&
      saveState == "price_update"
    ) {
      editProductResp.type = "price_success";
      editProductResp.message = "Price Updated Successfully!";
      setEditProductResp(editProductResp);
    }
  }, [productData]);

  const resetEditProduct = () => {
    productData.edit_product = null;
    const editProductRes = { type: "", message: "" };
    setEditProductResp(editProductRes);
  };

  useMemo(() => {
    if (
      productData &&
      productData.edit_product_error &&
      productData.edit_product_error.object
    ) {
      editProductResp.type = "error";
      editProductResp.message = productData.edit_product_error.object.error;
      setEditProductResp(editProductResp);
    }
  }, [productData]);

  useMemo(() => {
    if (
      productData &&
      productData.stock_product_error &&
      productData.stock_product_error.object
    ) {
      editProductResp.type = "error";
      editProductResp.message = productData.stock_product_error.object.error;
      setEditProductResp(editProductResp);
    }
  }, [productData]);

  useMemo(() => {
    if (
      productData &&
      productData.edit_product_price_error &&
      productData.edit_product_price_error.object
    ) {
      editProductResp.type = "error";
      editProductResp.message =
        productData.edit_product_price_error.object.error;
      setEditProductResp(editProductResp);
    }
  }, [productData]);

  useMemo(() => {
    if (
      productData &&
      productData.delete_product_group &&
      productData.delete_product_group_loaded &&
      saveState == "delete"
    ) {
      setSaveState("");
      dispatch(fetchsingleproduct(state.productId));
    }
  }, [productData]);

  useMemo(() => {
    if (
      productData &&
      productData.delete_product_group &&
      productData.delete_product_group_loaded &&
      saveState == "delete"
    ) {
      setSaveState("");
      dispatch(fetchcategory(state.productId));
    }
  }, [productData]);

  useMemo(() => {
    if (
      productData &&
      productData.add_product_group &&
      productData.add_product_group_loaded &&
      saveState == "add"
    ) {
      setSaveState("");
      dispatch(fetchcategory(state.productId));
    }
  }, [productData]);

  useMemo(() => {
    if (productData && productData.single_product) {
      setIndexState(0);
      setProductAddonsloaded(false);
      setProductAddonsResult(productData.single_product.data);
      if (productData.single_product_loaded) {
        setProductAddonsloaded(true);
      }
      
    }
  }, [productData]);

  // for category

  useMemo(() => {
    if (productData && productData.all_products) {
      setIndexState(0);
      setProductAddonsloaded(false);
      setProductCategoriesResult(productData.all_products.object);
      if (productData.single_product_loaded) {
        setProductAddonsloaded(true);
      }
    }
  }, [productData]);


  // when allExpenseResult data change than data add into constant,hook End
 

  // preview image

 

  const url = `${config.api_root}/api/v1/media/upload?user_id=${config.user_id}`;

  // end of preview image

 

  return (
    <>
       <div className="breadcrumbs-area">
        <button
          style={{ float: "right" }}
          className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
          onClick={(e) => goToBack()}
        >
          Back
        </button>
        <h3>Edit Product</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/product">All Products</Link>
          </li>

        
          <li>{productValues.name}</li>
        </ul>
      </div>

      <div className="row">
        <div className="col-12-xxxl col-12">
          <div className="card">
            <div className="heading-layout1">
              <div className="item-title">
                {/* <h3>Edit Product</h3> */}
              </div>
            </div>
            {editProductResp &&
            editProductResp.type &&
            editProductResp.type == "success" ? (
              <div style={{ textAlign: "center", color: "green" }}>
                {editProductResp.message}
              </div>
            ) : editProductResp.type == "stock_success" ? (
              <div style={{ textAlign: "center", color: "green" }}>
                {editProductResp.message}
              </div>
            ) : editProductResp.type == "price_success" ? (
              <div style={{ textAlign: "center", color: "green" }}>
                {editProductResp.message}
              </div>
            ) : (
              <div style={{ textAlign: "center", color: "red" }}>
                {editProductResp.message}
              </div>
            )}
            <div className="card-body">
              <form
                className="new-added-form"
                onSubmit={(e) => editProductHandler(e)}
              >
                <div className="row">
                  <div className="col-lg-6 col-12 form-group">
                    <label>Product Name</label>
                    <input
                      type="text"
                      placeholder="Product Name"
                      className="form-control"
                      value={productValues.name}
                      onChange={(e) =>
                        setProductValues({
                          ...productValues,
                          name: e.target.value,
                        })
                      }
                      required
                    />
                  </div>

                  <div className="col-lg-6 col-12 form-group">
                    <label>Product Short Description</label>
                    <textarea
                      placeholder="Product Short Description"
                      className="form-control"
                      onChange={(e) =>
                        setProductValues({
                          ...productValues,
                          shortDescription: e.target.value,
                        })
                      }
                    >
                      {productValues.shortDescription}
                    </textarea>
                  </div>
                  <div className="col-lg-6 col-12 form-group">
                    <label>Product Description</label>
                    <textarea
                      placeholder="Product Description"
                      className="form-control"
                      onChange={(e) =>
                        setProductValues({
                          ...productValues,
                          description: e.target.value,
                        })
                      }
                    >
                      {productValues.description}
                    </textarea>
                  </div>

                  <div className="col-lg-6 col-12 form-group">
                    <label>Product Image:</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6 col-12 form-group"></div>
                  <div className="col-lg-6 col-12 form-group">
                    <label>Product Preview:</label>
                    <br />
                    <img src={productValues.image} width={200} />
                  </div>

                  <div className="col-12 form-group mg-t-8">
                    <button
                      type="submit"
                      className="btn-fill-lg bg-blue-dark btn-hover-yellow"
                      style={{
                        float: "right",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="card-body mg-t-20 pad-5">
            <div className="col-lg-12 col-12 form-group">
                   <div className="row btmrow" style={{background:'none', border: 'none'}}>
                      <div style={{textAlign: 'left', paddingTop:'10px'}}>
                          <div className="status product"><span className="lables">Product Stock Enable / Disable</span> <span><Switch onChange={(e) => updateProductStatus(productValues.productId, productValues.stockStatus)} checked={productValues.stockStatus}
                             checkedIcon={false}
                             boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                             activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"/></span>
                          </div>
                      </div>       
                    </div>
                </div>
                </div>
           
                <div className="card-body mg-t-20 pad-5">
                <div className="col-lg-12 col-12 form-group">
                <div className="status product"><span className="lables">Product Enable / Disable</span>
                <Switch
                                    onChange={(e) =>
                                      setProductStatus(
                                        productValues.productId,
                                        productValues.productStatus
                                      )
                                    }
                                    checked={productValues.productStatus}
                                    uncheckedIcon={false}
                                    checkedIcon={true}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  />
                  </div>
                </div>
                </div>

            <div className="card-body mg-t-20 pad-l-5">
              <div className="col-lg-12 col-12 form-group">
                <label>Price: $ </label>
                <input
                  type="number"
                  placeholder="Price"
                  className="form-control"
                  style={{ display: "inline", width: "auto" }}
                  value={productValues.price}
                  onChange={(e) =>
                    setProductValues({
                      ...productValues,
                      price: e.target.value,
                    })
                  }
                  required
                />
                <button
                  type="button"
                  onClick={(e) => editProductPrice()}
                  className="btn-fill-lg bg-blue-dark btn-hover-yellow"
                  
                  style={{
                    marginLeft: "10px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12-xxxl col-12">
          <div className="card">
            <div
              className="card-body2"
              style={{ marginBottom: "15px", overflowX: "auto" }}
            >
              {!productAddonsloaded ? (
                <div style={{ textAlign: "center" }}>
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={150}
                    width={150}
                  />
                </div>
              ) : (
                <>
                  <div style={{ padding: "10px", color: "#000000" }}>
                    Categories:
                    <div className="float-right">
                      <Link
                        className="btn-fill-lg text-light bg-blue-dark btn-hover-yellow"
                        to={{
                          pathname: "/editproductcategory",
                          state: addCategoryGroups(),
                        }}
                      >
                        Manage Categories Groups 
                      </Link>
                    </div>
                  </div>
                
                  <div className="pad-10">
                  {productCategoriesResult && productCategoriesResult.categoryObj && productCategoriesResult.categoryObj.length > 0 && productCategoriesResult.categoryObj.map((item, index) => {


return (
  <>
    <div className="card-body2 mg-b-10">
      <div className="mg-b-10">
        {index === indexState ? (
          <span>
           
            {item.categoryName}
          </span>
        ) : (
          <span>
            
            {item.categoryName}
          </span>
        )}{" "}
        <span
          style={{ float: "right" }}
          onClick={(e) =>
            removeCategoryGroup(item.categoryId)
          }
        >
          <i className="fas fa-trash"></i>
        </span>
       </div>
     
    </div>
  </>
);
})}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12-xxxl col-12">
          <div className="card">
            <div
              className="card-body2"
              style={{ marginBottom: "15px", overflowX: "auto" }}
            >
              {!productAddonsloaded ? (
                <div style={{ textAlign: "center" }}>
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={150}
                    width={150}
                  />
                </div>
              ) : (
                <>
                  <div style={{ padding: "10px", color: "#000000" }}>
                    Addons:
                    <div className="float-right">
                      <Link
                        className="btn-fill-lg text-light bg-blue-dark btn-hover-yellow"
                        to={{
                          pathname: "/edit_addons",
                          state: addAddonGroups(),
                        }}
                      >

                        
                        Manage Addons Groups
                      </Link>
                    </div>
                  </div>
                  <div className="pad-10">
                    {productAddonsloaded &&
                      productAddonsResult &&
                      productAddonsResult.length > 0 &&
                      productAddonsResult.map((addonGroup, index1) => {
                        return (
                          <>
                            <div className="card-body2 mg-b-10">
                              <div className="mg-b-10">
                                {index1 === indexState ? (
                                  <span onClick={(e) => hideAddons()}>
                                    <i className="fas fa-caret-down"></i>{" "}
                                    {addonGroup.name}
                                  </span>
                                ) : (
                                  <span onClick={(e) => showAddons(index1)}>
                                    <i className="fas fa-caret-right"></i>{" "}
                                    {addonGroup.name}
                                  </span>
                                )}{" "}
                                <span
                                  style={{ float: "right" }}
                                  onClick={(e) =>
                                    removeGroup(addonGroup.addOnGroupId)
                                  }
                                >
                                  <i className="fas fa-trash"></i>
                                </span>
                              </div>
                              <table key={index1} className={(index1 === indexState) ? "table data-table text-nowrap" : "d-none table data-table text-nowrap"}>
                                <tbody>
                                  {addonGroup.addons &&
                                    addonGroup.addons.length > 0 &&
                                    addonGroup.addons.map((addon) => {
                                      return (
                                        <tr
                                          style={{
                                            borderBottom: "1px solid #dee2e6",
                                          }}
                                        >
                                          <td className="no-border">
                                            <i className="fas fa-grip-vertical"></i>
                                          </td>
                                          <td className="no-border">
                                            {addon.name}
                                          </td>
                                          <td className="no-border">
                                            $ {addon.unitPrice}
                                          </td>
                                          <td className="no-border">
                                            <i className="fas fa-ellipsis-v"></i>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DirectEditProduct;
