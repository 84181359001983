import {
  BANNER_UPLOAD_REQUEST,
  BANNER_UPLOAD_SUCCESS,
  BANNER_UPLOAD_FALIURE,
  LOGO_UPLOAD_REQUEST,
  LOGO_UPLOAD_SUCCESS,
  LOGO_UPLOAD_FALIURE,
  GET_UPLOAD_REQUEST,
  GET_UPLOAD_SUCCESS,
  GET_UPLOAD_FALIURE
} from './BannerLogoConstants'
import {config} from '../config'

export const bannerUploadRequest = (banner_upload) =>{
  return{
    type : BANNER_UPLOAD_REQUEST,
    payload : banner_upload
  }
}

export const bannerUploadSuccess = (banner_upload_success) =>{
  return{
    type : BANNER_UPLOAD_SUCCESS,
    payload : banner_upload_success
  }
}

export const bannerUploadFaliure = (error) =>{
  return{
    type : BANNER_UPLOAD_FALIURE,
    payload : error
  }
}

export const logoUploadRequest = (logo_upload) =>{
  return{
    type : LOGO_UPLOAD_REQUEST,
    payload : logo_upload
  }
}

export const logoUploadSuccess = (logo_upload_success) =>{
  return{
    type : LOGO_UPLOAD_SUCCESS,
    payload : logo_upload_success
  }
}

export const logoUploadFaliure = (error) =>{
  return{
    type : LOGO_UPLOAD_FALIURE,
    payload : error
  }
}

export const getRequest = (get_upload) =>{
  return{
    type : GET_UPLOAD_REQUEST,
    payload : get_upload
  }
}

export const getSuccess = (get_upload_success) =>{
  return{
    type : GET_UPLOAD_SUCCESS,
    payload : get_upload_success
  }
}

export const getFaliure = (error) =>{
  return{
    type : GET_UPLOAD_FALIURE,
    payload : error
  }
}

export const uploadBanner = (file) => {
    return(dispatch) => {
      dispatch(bannerUploadRequest(true))

      const url =`${config.api_root}/api/v1/merchants/banner`;
      var formdata = new FormData();
      formdata.append("file", file, file.value);
      formdata.append("user_id", config.user_id);
      
      var request_option = {
        method: 'POST',
        headers: {
           'Authorization': 'Bearer '+ config.token
        },
        body: formdata
      };
      
      fetch(url, request_option)
        .then(response => response.json())
        .then(upload_res =>{
          dispatch(bannerUploadSuccess(upload_res))
          dispatch(getLogoBanner(localStorage.getItem('merchant_url')))
          
          
        })
        .catch(error => {
          const errorMsg = error
          dispatch(bannerUploadFaliure(errorMsg))
        })
    }
}

export const uploadLogo = (file) => {
  return(dispatch) => {
    dispatch(logoUploadRequest(true))

    const url =`${config.api_root}/api/v1/merchants/logo`;
    var formdata = new FormData();
    formdata.append("file", file, file.value);
    formdata.append("user_id", config.user_id);
    
    var request_option = {
      method: 'POST',
      headers: {
         'Authorization': 'Bearer '+ config.token
      },
      body: formdata
    };
    
    fetch(url, request_option)
      .then(response => response.json())
      .then(upload_res =>{
        dispatch(logoUploadSuccess(upload_res))
        dispatch(getLogoBanner(localStorage.getItem('merchant_url')))
      })
      .catch(error => {
        const errorMsg = error
        dispatch(logoUploadFaliure(errorMsg))
      })
  }
}


export const getLogoBanner = (url) =>{
  return(dispatch) => {
    dispatch(getRequest(false))
    const request_option = {
       method: "GET",
       headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
        }
    }
    fetch(url, request_option)
     .then(response => response.json() )
     .then(specific_res =>{
        let res = {
          success_status: true,
          logo: specific_res.object.LOGO,
          banner: specific_res.object.BANNER
        }
        dispatch(getSuccess(res))
     })
    .catch(error => {
      const errorMsg = error
      dispatch(getFaliure(errorMsg))
    })
  }
}
