import {
  ETICKET_REDEEM_REQUEST,
  ETICKET_REDEEM_SUCCESS,
  ETICKET_REDEEM_FALIURE
} from './ETicketRedeemConstants'

const intialstate = {
  eticket_redeem_loaded : true,
  eticket_redeem : '',
  eticket_redeem_error : '',
  eticket_redeem_success : false
  // egift_card_balance_loaded : true,
  // egift_card_balance : '',
  // egift_card_balance_error : '',
  // egift_card_balance_success : false
}

const EticketRedeemReducers = (state = intialstate,action) =>{
  switch(action.type){
    case ETICKET_REDEEM_REQUEST : return{
      ...state,
      eticket_redeem_loaded:false
    }
    case ETICKET_REDEEM_SUCCESS : return{
      ...state,
      eticket_redeem_loaded:true,
      eticket_redeem_success:true,
      eticket_redeem:action.payload
    }
    case ETICKET_REDEEM_FALIURE : return{
      ...state,
      eticket_redeem_loaded:true,
      eticket_redeem_error:action.payload
    }
  //   case EGIFTCARD_BALANCE_REQUEST : return{
  //     ...state,
  //     egift_card_balance_loaded:false
  //   }
  //   case EGIFTCARD_BALANCE_SUCCESS : return{
  //     ...state,
  //     egift_card_balance_loaded:true,
  //     egift_card_balance_success:true,
  //     egift_card_balance:action.payload
  //   }
  //   case EGIFTCARD_BALANCE_FALIURE : return{
  //     ...state,
  //     egift_card_balance_loaded:true,
  //     egift_card_balance_error:action.payload
  //   }
    default: return state
  }

}

export default EticketRedeemReducers
