import {
  ADD_HOLIDAY_REQUEST,
  ADD_HOLIDAY_SUCCESS,
  ADD_HOLIDAY_FALIURE,
  DELETE_HOLIDAY_REQUEST,
  DELETE_HOLIDAY_SUCCESS,
  DELETE_HOLIDAY_FALIURE
} from './AddHolidayConstants'
import { fetchAllDriver } from '../AllDriver/AllDriverActions';

import {config} from '../config'
import toast from 'react-hot-toast';
import { useState } from 'react';

const currentPage = 0;
const totalPages = 0;

export const addHolidayRequest = (holiday_info) =>{
  return{
    type : ADD_HOLIDAY_REQUEST,
    payload : holiday_info
  }
}

export const addHolidaySuccess = (holiday_success) =>{
  return{
    type : ADD_HOLIDAY_SUCCESS,
    payload : holiday_success
  }
}

export const addHolidayFaliure = (error) =>{
  return{
    type : ADD_HOLIDAY_FALIURE,
    payload : error
  }
}

export const deleteHolidayRequest = (delete_info) =>{
  return{
    type : DELETE_HOLIDAY_REQUEST,
    payload : delete_info
  }
}

export const deleteHolidaySuccess = (delete_success) =>{
  return{
    type : DELETE_HOLIDAY_SUCCESS,
    payload : delete_success
  }
}

export const deleteHolidayFaliure = (error) =>{
  return{
    type : DELETE_HOLIDAY_FALIURE,
    payload : error
  }
}

export const addHoliday = (timing_info) =>{
    return(dispatch) => {
      dispatch(addHolidayRequest(timing_info))
      const url =`${config.api_root}/api/v1/merchants/holiday`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
      body: JSON.stringify({
        form_id: "",
        user_id:config.user_id,
        fields: {
        name: timing_info.holidayName,
        holiday_id:"",
        holiday_date:timing_info.holiday_date,
        }

      })
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(add_timing_res =>{
      const add_timing_success = add_timing_res
      dispatch(addHolidaySuccess(add_timing_success))
      dispatch(fetchAllDriver())
    })
    .catch(error => {
      const errorMsg = error
      dispatch(addHolidayFaliure(errorMsg))
    })
}
}

export const deleteHoliday = (holiday_id) =>{
    return(dispatch) => {
     dispatch(deleteHolidayRequest(holiday_id))
      const url =`${config.api_root}/api/v1/merchants/holiday?user_id=${config.user_id}&holiday_id=${holiday_id}`;
      const request_option = {
      method: "DELETE",
      headers: {
        'Authorization': 'Bearer '+ config.token,
        'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(exam_res =>{
      const all_rest_timings_success = exam_res
      dispatch(deleteHolidaySuccess(all_rest_timings_success))
      dispatch(fetchAllDriver())
    })
    .catch(error => {
      const errorMsg = error
      dispatch(deleteHolidayFaliure(errorMsg))
    })
    }
}

