import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uploadBanner, uploadLogo } from '../Redux/BannerLogo/BannerLogoActions'
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Link } from 'react-router-dom';

function BannerLogoUpload() {

  const uploadBannerData = useSelector(state => state.BannerLogo)
  // store data access End
  const dispatch = useDispatch()  // for accessing the redux function

  const [isBannerLoaded, setBannerLoaded] = useState(null)
  const [isLogoLoaded, setLogoLoaded] = useState(null)
  const [responseMessage, setResponseMessage] = useState('')
  const [banner, setBanner] = useState('')
  const [logo, setLogo] = useState('')
  const [isbanner, setIsBanner] = useState(false)
  const [islogo, setIsLogo] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  // add data of allFees api into constant,hook start

  useMemo(() => {
    if (uploadBannerData && !uploadBannerData.upload_loading) {
      if (uploadBannerData.upload_banner && uploadBannerData.upload_banner.object) {
         setResponseMessage(uploadBannerData.upload_banner.message)
      } else if (uploadBannerData.error) {
         setErrorMessage(uploadBannerData.error)
      }
    } else {
      if(isbanner) setBannerLoaded(false)
      if(islogo) setLogoLoaded(false)
    }
  }, [uploadBannerData])

  useMemo(() => {
    if (uploadBannerData && !uploadBannerData.get_loading) {
      if (uploadBannerData.get_bannerlogo && uploadBannerData.success_status) {
        
         setBanner(uploadBannerData.get_bannerlogo.banner)
         setLogo(uploadBannerData.get_bannerlogo.logo)
         if(uploadBannerData && !uploadBannerData.upload_loading){
           setLogoLoaded(true)
           setBannerLoaded(true)
         }
      } else if (uploadBannerData.error) {
         setErrorMessage(uploadBannerData.error)
      }
    }
  }, [uploadBannerData])


  // const uploadBannerFunction = () => {
  //    var file = document.querySelector("input[name='banner']").files[0];
  //    setLoaded(false)
  //    dispatch(uploadBanner(file));
  // }

  const uploadBannerFunction = () => {
    setBannerLoaded(null)
    let input = document.createElement('input');
    input.type = 'file';
    input.onchange = _ => {
      if (input.files[0]) {
        // Validate file type
        if (input.files[0].type !== 'image/jpeg') {
          setErrorMessage('Only JPG images are allowed.');
        } else {
          setErrorMessage('');
          setIsBanner(true)
          setIsLogo(false)
          setBannerLoaded(false)
          dispatch(uploadBanner(input.files[0]));
        }
      }
    };
    input.click(); 
  }

  const uploadLogoFunction = () => {
    setLogoLoaded(null)
    let input = document.createElement('input');
    input.type = 'file';
    input.onchange = _ => {
      if (input.files[0]) {
        // Validate file type
        if (input.files[0].type !== 'image/jpeg') {
          setErrorMessage('Only JPG images are allowed.');
        } else {
           setIsLogo(true)
           setIsBanner(false)
           setLogoLoaded(false)
           dispatch(uploadLogo(input.files[0]));
        }
      }    
    };
    input.click(); 
  }

  return (
    <>
      <div className="breadcrumbs-area">
        <h3>Upload Banner/Logo</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Upload Banner/Logo</li>
        </ul>
      </div>
      {/* Breadcubs Area Start Here */}


      <div className="card-body mx-width-600">

        <div className="heading-layout1">
          <div className="item-title">

          </div>

        </div>

        {responseMessage ?
          <p className="alert alert-success w-auto" role="alert">
            {responseMessage}
          </p> : <p></p>
        }

        {errorMessage ?
          <p className="alert alert-danger w-auto" role="alert">
            {errorMessage}
          </p> : <p></p>
        }


<div className="pad-5 bg-darkblue height-50">
          <ul className="nav nav-tabs justify-content-center" role="tablist">
            <li>
              <a href="#" className='nav-link mg-r-10 active show' aria-controls="billing" role="link" aria-expanded="true" aria-disabled="true">Upload Banner/Logo</a>
            </li>
          </ul>
        </div> 


        <div className="tab-content single-info-details mx-width-600">
          <div role="tabpanel" className='tab-pane active show' id="billing">
         
                
            <div className="row">
              <div className="col-12-xxxl col-lg-6 col-12 form-group mg-0">
              <div className="rcorners" id="#banner_img" style={{background: banner ? `url(${banner}) no-repeat center` : "#afb0ad"}} type="image">
                   <button type="button" onClick={(e) => uploadBannerFunction()} style={{marginTop: '100px'}} className='btn-fill-lg bg-blue-dark btn-hover-yellow'>Upload Banner</button>
                   {isBannerLoaded == false ?
               <div style={{textAlign: 'center'}}>
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={90}
                    width={150}
                  />
                </div> : <span></span> }
                </div>
              </div>
            </div>
            <br/>
            <div className="row">
              <div className="col-12-xxxl col-lg-6 col-12 form-group mg-0">
                <div className="rcorners" id="#logo_img" style={{background: logo ? `url(${logo}) no-repeat center` : '#afb0ad'}} type="image">
                   <button type="button" onClick={(e) => uploadLogoFunction()} style={{marginTop: '100px'}} className='btn-fill-lg bg-blue-dark btn-hover-yellow'>Upload Logo</button>
                   {isLogoLoaded == false ?
               <div style={{textAlign: 'center'}}>
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={90}
                    width={150}
                  />
                </div> : <span></span> }
                </div>
              </div>
            </div>
            <br/>
          </div>       


        </div>



      </div>

    </>
  );
};

export default BannerLogoUpload;
