import React, {useState,useEffect,useMemo} from 'react';
import Loader from 'react-loader-spinner';

import 'rc-time-picker/assets/index.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
  FormControl,
  FormGroup,
  FormLabel
} from "react-bootstrap";
import { useSelector,useDispatch } from 'react-redux'
import { addAddon } from '../Redux/AddTiming/AddTimingActions'

import moment from 'moment';
import { fetchAllAddons } from '../Redux/AllBook/AllBookActions';

import { Link } from 'react-router-dom';
import AddAddonGroup from './AddAddonGroup';
import AllAddonGroups from './AllAddonGroups';
import { Button } from 'react-bootstrap';
import AddSingleAddonGroup from './AddSingleAddonGroup';
function AddonGroups() {

  const [show,setShow] = useState(true);

  const classes = useSelector(state =>state.AllClasses)
const sections = useSelector(state =>state.AllSections)
const subjects = useSelector(state =>state.AllSubjects)
const teachers = useSelector(state =>state.AllTeachers)
const addExam_data = useSelector(state =>state.AddTiming)
// store data access End
  const dispatch = useDispatch()  // for accessing the redux function

  // component all states define start
  const [classesResult,setClassesResult] = useState([])
  const [saveAddonGroup,setSaveAddonGroup] = useState('')
  const [allClassesInfo,setClassesInfo] = useState([])
  const [allSectionsInfo,setSectionsInfo] = useState([])
  const [allSubjectsInfo,setSubjectsInfo] = useState([])
  const [teacherResult,setTeacherResult] = useState([])
  const [allTeachersInfo,setTeachersInfo] = useState([])
  const [inputValues,setInputValues] = useState({
                                          name:"",
                                          description:"",
                                          type:"",
                                          nameKey:"type",
                                          isRequired:false,
                                          requires:0,
                                          isGlobal:true,
                                          isSystem:false,

  })
  const [inputValuesdefault,setInputValuesdefault] = useState({
                                          name:"",
                                          description:"",
                                          type:"",
                                          nameKey:"type",
                                          isRequired:false,
                                          requires:0,
                                          isGlobal:true,
                                          isSystem:false
  })
  
  const [mediumId,setMediumId] = useState('')
  const [error,setError] = useState(null)
  const [successStatus,setSuccessStatus] = useState(null)


const format = 'HH:mm';

const now = moment().hour(0).minute(0);

  // component all states define End

   //hooks start
   // fetch classes and teachers api hook start
   useEffect(() =>{
    // dispatch(fetchAllClasses())
  //   dispatch(fetchAllTeachers())
   },[dispatch])
// fetch classes and teachers api hook End

const callSaveAddonGroup = () =>{
  setSaveAddonGroup(null)
}

// add data of classes api into constant,hook start
   useMemo(() =>{
     if(classes && classes.all_classes && classes.all_classes.result && classes.all_classes.success === true){
       setClassesResult(classes.all_classes.result)
     }
   },[classes])
// add data of classes api into constant,hook End

// when classesResult change add data into classInfo,hook start
   useMemo(()=>{
     if(classesResult && classesResult.data){
           setClassesInfo(classesResult.data)
     }
   },[classesResult])
// when classesResult change add data into classInfo,hook End

// fetch allSections and allsubject api,hook start
   useMemo(() =>{
     if(mediumId !='' && inputValues.classId !=''){
      //   dispatch(fetchAllSections(inputValues.classId,mediumId))
      //   dispatch(fetchAllSubjects(inputValues.classId,mediumId))
     }
   },[mediumId,inputValues.classId])
// fetch allSections and allsubject api,hook End

// add data of allSections api into constant,hook start
   useMemo(()=>{
     if(sections && sections.all_sections && sections.all_sections.result && sections.all_sections.success === true){
       setSectionsInfo(sections.all_sections.result)
     }
   },[sections])
// add data of allSections api into constant,hook End

// add data of allsubject api into constant,hook start
   useMemo(()=>{
     if(subjects && subjects.all_subjects && subjects.all_subjects.result && subjects.all_subjects.success === true ){
       setSubjectsInfo(subjects.all_subjects.result)
     }
   },[subjects])
// add data of allsubject api into constant,hook End

// add data of teachers api into constant,hook start
   useMemo(()=>{
     if(teachers && teachers.all_teachers && teachers.all_teachers.result && teachers.all_teachers.success === true){
       setTeacherResult(teachers.all_teachers.result)
     }
   },[teachers])
// add data of teachers api into constant,hook End

// when teacherResult change add data into teachersInfo,hook start
   useMemo(()=>{
     if(teacherResult && teacherResult.data){
           setTeachersInfo(teacherResult.data)
     }
   },[teacherResult])
// when teacherResult change add data into teachersInfo,hook End

// add data of addExam api into constant,hook start

   useMemo(()=>{
     if(addExam_data && addExam_data.add_addon && addExam_data.add_addon.object){
       if(addExam_data.add_addon.request_status && saveAddonGroup == 'save'){
        setInterval(() => {
          setShow(false); 
      }, 3000);
         setSaveAddonGroup(null)       
         setInputValues(inputValuesdefault);
         setSuccessStatus(addExam_data.add_addon.request_status)
       } else if(addExam_data && addExam_data.add_addon && addExam_data.add_addon.object.error && saveAddonGroup == 'save'){
         setSaveAddonGroup(null)
         setInputValues(inputValuesdefault);
         setError(addExam_data.add_addon.object.error)
       }
    }
   },[addExam_data])
// add data of addExam api into constant,hook End

   //hooks end

// component function start
// classHandler function start
   const classHandler = (event) =>{

     const classInfo = event.target.value
     console.log("classInfo",event.target.value)
     if(classInfo !=""){
       setInputValues({...inputValues,classId:classInfo})
       allClassesInfo.filter(classid =>classid.id ==
          classInfo).map((item,index) =>{
         setMediumId(item.ClassMediumId)
       })

     }
     else{
       setInputValues({...inputValues,classId:classInfo})
     }

   }
// classHandler function End
const [currentPage, setCurrentPage] = useState(0);
const [totalPages, setTotalPages] = useState(0);
// examHandler function start
   const examGroupHandler = (event) =>{
     event.preventDefault()
     setSaveAddonGroup(null)
     const exam_info = inputValues;
     setSaveAddonGroup("save")
     dispatch(addAddon(exam_info))
     dispatch(fetchAllAddons(totalPages,currentPage))
   }
// examHandler function End

// resetHandler function start
   const resetHandler = (event) =>{
     event.preventDefault()
     setInputValues(inputValuesdefault);
   }
// resetHandler function start




        return (           
          <>
            {/* Breadcubs Area Start Here */}
            <div className="breadcrumbs-area">
              <h3>Addon Groups</h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Addon Groups</li>
              </ul>
              <div className="d-lg-none d-md-block">     
              <button className="btn-fill-lg bg-blue-dark btn-hover-yellow float-right" data-toggle="modal" data-target="#exampleModal"><i className="fas fa-plus"></i> Addon Group</button>
             
           



          </div>
            </div>

         

            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" style={{marginLeft:"20px", marginRight:"20px"}}>
      <div className="heading-layout1 bg-darkblue">
                      <div className="item-title">
                        <h3 className='text-light'>Add Addon Group</h3>
                        
                      </div>

                    </div>
     
      </div>
      <div class="modal-body" style={{marginLeft:"20px", marginRight:"20px"}}>
      {addExam_data.add_addon_loading ?
                          <div className='mg-l-40'>
                          <Loader
                            className = "cl-center"
                            type="MutatingDots"
                            color="#fea801"
                            height={150}
                            width={150}    
                          /></div>
                         : addExam_data.add_addon && addExam_data.add_addon.request_status && addExam_data.add_addon.request_status === true && show ? (<div className="success-greeting1">
                             <h2 className="addtimesuccess cl-center">Addon Group Added Successfully!</h2>
                            
                          </div>) : addExam_data.add_addon && addExam_data.add_addon.object && addExam_data.add_addon.object && addExam_data.add_addon.object.error ? (<div className="success-greeting1">
                             <h2 className="addtimeerror cl-center">{addExam_data.add_addon.object.error}</h2>
                          
                           </div>): null}

                    <form className="new-added-form" onSubmit={(e) =>examGroupHandler(e)}>
                      <div className="row">
                        <div className="col-12-xxxl col-12 form-group">
                          <label>Group Name *</label>
                          <input type="text" placeholder className="form-control" value={inputValues.name} onChange={(e) =>setInputValues({...inputValues,name:e.target.value})} required/>
                          {error != null && error.name ? (<h6 className="addStudent-error">*{JSON.stringify(error.name).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>
                        <div className="col-12-xxxl col-12 form-group">
                          <label>Group Description *</label>
                          <input type="text" placeholder className="form-control" value={inputValues.description} onChange={(e) =>setInputValues({...inputValues,description:e.target.value})} required/>
                          {error != null && error.description ? (<h6 className="addStudent-error">*{JSON.stringify(error.description).replace(/[\[\]"]+/g,"")}</h6>):null}
                        </div>
                        <div className="col-12-xxxl col-12 form-group">
                        <FormGroup>
                            <FormLabel>Type *</FormLabel>
                            <FormControl
                              required
                              type="text"
                              value={inputValues.type}
                              onChange={(e) =>setInputValues({...inputValues,type:e.target.value})}
                              as="select"
                            >
                            <option value="">Choose a type</option>  
                            <option value="SELECT">Can Select in DropDown</option>
                            <option value="CHECKBOX">Can Select any of them</option>
                            <option value="RADIO">Can Select only one of them</option>

                            </FormControl>
                            {error != null && error.openingDay ? (<h6 className="addStudent-error">*{JSON.stringify(error.openingDay).replace(/[\[\]"]+/g,"")}</h6>):null}
                          </FormGroup>
                        </div>
                        <div className="col-12-xxxl col-12 form-group">
                        <FormGroup>
                            <FormLabel>Is Required</FormLabel>
                            <FormControl
                              required
                              type="text"
                              value={inputValues.isRequired}
                              onChange={(e) =>setInputValues({...inputValues,isRequired:e.target.value})}
                              as="select"
                            >
                            <option value="false">FALSE</option>
                            <option value="true">TRUE</option>

                            </FormControl>
                            {error != null && error.closingDay ? (<h6 className="addStudent-error">*{JSON.stringify(error.closingDay).replace(/[\[\]"]+/g,"")}</h6>):null}
                          </FormGroup>
                        </div>

                        <div className="col-12 form-group mg-t-20">
                          <button type="submit" className="btn-fill-lg bg-blue-dark btn-hover-yellow right-float mg-l-10">Save</button>
                          <button type="reset" className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark right-float" onClick={(e) =>resetHandler(e)}>Reset</button>
                        </div>
                      </div>
                    </form>
      </div>
     
    </div>
  </div>
</div>
  
                           
            {/* Breadcubs Area End Here */}
            {/* Exam Schedule Area Start Here */}
            <div className="row">
              <AllAddonGroups />
              <AddAddonGroup />
            </div>
          </>  
        );
}

export default AddonGroups;
