import React, {useState,useEffect,useMemo} from 'react';

import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { useSelector,useDispatch } from 'react-redux'
import {fetchAllRestTimings, deleteRestTiming} from '../Redux/AllRestTimings/AllRestTimingsActions'

function AllRestTimings() {
  // store data access start
const allresttimings = useSelector(state =>state.AllRestTimings)
// store data access End
  const dispatch = useDispatch()  // for accessing the redux function

  // component all states define start
  const [allresttimingsResult,setAllrestTimingsResult] = useState([])


  // component all states define End

   //hooks start
   // fetch allexams api hook start
   useEffect(() =>{
     dispatch(fetchAllRestTimings())
   },[dispatch])
// fetch allexams api hook End

// add data of allexams api into constant,hook start
   useMemo(() =>{
     if(allresttimings && allresttimings.all_rest_timings && allresttimings.all_rest_timings.data && allresttimings.all_rest_timings.request_status === true){
       setAllrestTimingsResult(allresttimings.all_rest_timings.data)
     }
   },[allresttimings])
// add data of allexams api into constant,hook End

// when examsResult change add data into classInfo,hook start
   useMemo(()=>{
     if(allresttimingsResult && allresttimingsResult.data){
           setAllrestTimingsResult(allresttimingsResult.data)
     }
   },[allresttimingsResult])
// when examsResult change add data into classInfo,hook End

   //hooks end

   const handledeleteTiming =(e) => {
   
     dispatch(deleteRestTiming(e))
      
   }

        return (
            <div className="col-8-xxxl col-12">
            <div className="card">
                <div className="card-body">

                  <div className="table-box-wrap">

                      <div className="row gutters-8">



                        <div className="col-lg-4 form-group">

                        </div>
                      </div>


                      {allresttimings && allresttimings.all_rest_timings_loading === true ?
                        (<div className="card-body">
                        <div className="Student-Loader">
                        <Loader
                        className = "student-detail-loader"
                      type="ThreeDots"
                      color="#fea801"
                      height={150}
                      width={150}

         />
                        </div>
                      </div>) : (<div className="table-responsive expenses-table-box">

                    <table className="table data-table text-nowrap">
                        <thead>
                          <tr>
                            <th>
                              Day
                            </th>
                            <th>Timing</th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Action</th>


                          </tr>
                        </thead>
                        <tbody>
                        {allresttimingsResult && allresttimingsResult.length > 0 && allresttimingsResult.map((item,index)=>{
                          return (
                            <tr>
                            <td>{item.from_day} - {item.to_day}</td>
                            <td>{item.from_time} - {item.to_time} </td>
                              <td>{item.name}</td>
                              <td>{item.time_type}</td>
                              <td><button type="button" onClick={e=>handledeleteTiming(item.id)} className="btn btn-danger restdelete"><i className="fa fa-trash" aria-hidden="true"></i></button></td>
                              <td className="hidden">
                                <div className="dropdown">
                                  <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                    <span className="flaticon-more-button-of-three-dots" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right" style={{display:'none'}}>
                                    <a className="dropdown-item" href="#"><i className="fas fa-times text-orange-red" />Close</a>
                                    <a className="dropdown-item" href="#"><i className="fas fa-cogs text-dark-pastel-green" />Edit</a>
                                    <a className="dropdown-item" href="#"><i className="fas fa-redo-alt text-orange-peel" />Refresh</a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )
                        })}


                        </tbody>
                      </table>
                    </div>)}
                  </div>
                </div>
              </div>
              </div>
        );
}

export default AllRestTimings;
