import React from 'react';

function Footer() {
        return (
            <footer className="footer-wrap-layout1">
            <div className="copyright">© Copyrights <a href="#">DiningTek</a> 2023. All rights reserved. </div>
          </footer>
        );
}

export default Footer;
