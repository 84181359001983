import React from 'react';
import { useSelector } from 'react-redux'


function Breadcubs() {
const specificUserDetailData = useSelector(state =>state.SpecificUserDetail)
        return (
            <div className="breadcrumbs-area">
            {specificUserDetailData && specificUserDetailData.specific_user_detail && specificUserDetailData.specific_user_detail.LOGO ? (<img src={specificUserDetailData.specific_user_detail.LOGO} />) : null}
        <h3>{specificUserDetailData && specificUserDetailData.specific_user_detail && specificUserDetailData.specific_user_detail.name ? specificUserDetailData.specific_user_detail.name : null}</h3>
        <ul>
         
        </ul>
      </div>
        );
}

export default Breadcubs;
