import React from 'react';
import { Link } from 'react-router-dom';
import Marketing from './marketing';


function AllOrders() {

 
        return (           
          <>
          <div className="breadcrumbs-area">
            <h3>Marketing & Branding Plan</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Marketing & Branding Plan</li>
            </ul>
          </div>
            {/* Breadcubs Area Start Here */}
            <Marketing />
          </>
        );
}

export default AllOrders;
