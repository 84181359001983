import React,{useState}from 'react';
import { Link } from 'react-router-dom';
import Orders from './orders';


function AllOrders() {
        return (
          <div>
          <div className="breadcrumbs-area">
            <h3>All Orders</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>All Orders</li>
            </ul>
          </div>
            {/* Breadcubs Area Start Here */}
            <Orders />
            {/* Add New Teacher Area End Here */}
          
          </div>
        );
}

export default AllOrders;
