import {
  FETCH_ALLNOTICE_REQUEST,
  FETCH_ALLNOTICE_SUCCESS,
  FETCH_ALLNOTICE_ADDED_SUCCESS,
  FETCH_ALLNOTICE_FALIURE
} from './AllNoticeConstants'
import {config} from '../config'
import toast from 'react-hot-toast'

export const fetchAllNoticeRequest = () =>{
  return{
    type : FETCH_ALLNOTICE_REQUEST
  }
}

export const fetchAllNoticeAddedSuccess = (all_notices_added_success) =>{
  return{
    type : FETCH_ALLNOTICE_ADDED_SUCCESS,
    payload : all_notices_added_success
  }
}

export const fetchAllNoticeSuccess = (all_notices_success) =>{
  return{
    type : FETCH_ALLNOTICE_SUCCESS,
    payload : all_notices_success
  }
}

export const fetchAllNoticeFaliure = (error) =>{
  return{
    type : FETCH_ALLNOTICE_FALIURE,
    payload : error
  }
}

export const fetchAllNotices = (isAdded,currentPage) =>{
    return(dispatch) => {
      dispatch(fetchAllNoticeRequest())
      const url =`${config.api_root}/api/v1/merchants/events?size=200&offset=${currentPage}&user_id=${config.user_id}`;
      const request_option = {
          method: "GET",
          headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token
          }
      }
      fetch(url, request_option)
      .then(response => response.json())
      .then(all_notices_res =>{
          const all_notices_success = all_notices_res
          if(isAdded) {
            dispatch(fetchAllNoticeAddedSuccess(all_notices_success))
          } else {
            dispatch(fetchAllNoticeSuccess(all_notices_success))
          }
          console.log(dispatch(fetchAllNoticeAddedSuccess(all_notices_success)));
       })
       .catch(error => {
          const errorMsg = error
          dispatch(fetchAllNoticeFaliure(errorMsg))
       })
    }
}

export const addEvent = (timing_info) =>{
    return(dispatch) => {
      dispatch(fetchAllNoticeRequest(timing_info))
      const url =`${config.api_root}/api/v1/merchants/event?user_id=${config.user_id}`;

      const request_option = {
      method: "POST",
      headers: {
        // 'Accept': 'application/json',
        'Authorization': 'Bearer '+ config.token,
        // 'Content-Type': 'application/json'
    },
      body: timing_info
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(add_timing_res =>{
      const add_timing_success = add_timing_res
      if(add_timing_res.request_status === false){
        dispatch(fetchAllNoticeFaliure(add_timing_res.object.error))
        console.log(fetchAllNoticeFaliure(add_timing_res.object.error));
        toast.error(add_timing_res.object.error)
      }else{    
        toast.success("Specials Added Successfully")
        dispatch(fetchAllNotices(0,0));
      }
  //    dispatch(addTimingSuccess(add_timing_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchAllNoticeFaliure(errorMsg))
    })
}
}


// punch ticket api in special events
export const punchTicket = (timing_info) =>{

  return(dispatch) => {
    const event_id = localStorage.getItem("event_id")
    dispatch(fetchAllNoticeRequest(timing_info))
    const url =`${config.api_root}/api/v1/merchants/event/${event_id}/punch`;
    const request_option = {
    method: "POST",
    headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
        },
    body: JSON.stringify({
      user_id : config.user_id,
      fields:{
        "giftCard":[
          {
            card_number: timing_info.card_number,
            pin: timing_info.pin,
        }
        ]
      }
       
    })
  }
  fetch(url, request_option)
  .then(response => response.json())
  .then(add_timing_res =>{
    console.log("--jkf--->"+JSON.stringify(add_timing_res.object.error));
    const all_categories_success = add_timing_res.object.error
    if(add_timing_res.request_status === false){
      dispatch(fetchAllNoticeFaliure(add_timing_res.object.error))
      // console.log(fetchProductsFaliure(add_timing_res.object.error));
      toast.error(add_timing_res.object.error)
    }else{
      toast.success("Punch Ticket Added Successfully")
      window.location.replace("/events")
    }
    
    // dispatch(fetchProductsSuccess(all_categories_success))

  })
  .catch(error => {
    const errorMsg = error
    dispatch(fetchAllNoticeFaliure(errorMsg))
  })
}
}