import {
  GET_GALLERY_REQUEST,
  GET_GALLERY_SUCCESS,
  GET_GALLERY_FALIURE
} from './GalleryConstants'
import {config} from '../config'

export const getGalleryRequest = (gallery_req) =>{
  return{
    type : GET_GALLERY_REQUEST,
    payload : gallery_req
  }
}

export const getGallerySuccess = (gallery_req_success) =>{
  return{
    type : GET_GALLERY_SUCCESS,
    payload : gallery_req_success
  }
}

export const getGalleryFaliure = (error) =>{
  return{
    type : GET_GALLERY_FALIURE,
    payload : error
  }
}

export const fetchGalleries = () =>{
  return(dispatch) => {
    dispatch(getGalleryRequest(false))
    const url =`${config.api_root}/api/v1/merchants/galleries?w=360&h=240&type=BANNER&mid=${config.mid}`
    const request_option = {
       method: "GET",
       headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
        }
    }
    fetch(url, request_option)
     .then(response => response.json() )
     .then(res =>{
        dispatch(getGallerySuccess(res))
     })
    .catch(error => {
      const errorMsg = error
      dispatch(getGalleryFaliure(errorMsg))
    })
  }
}
